import {GridCellParams} from "@mui/x-data-grid";
import React from "react";
import {ISurvey} from "../../../types/interfaces";
import {Button, ButtonGroup, ClickAwayListener, Grow, Paper, Popper} from "@mui/material";
import {ArrowDropDownIcon} from "@mui/x-date-pickers";

export interface MultiActionButtonAction {
    label: string,
    toolTipText?: string,
    action: (() => void),
    disabled?: boolean
}

export function RenderCellMultiButton(props: { params: GridCellParams, getActions: (params: GridCellParams) => MultiActionButtonAction[] }) {




    // const options = ['Clone', 'Archive', 'Export'];

    const answerMemo = React.useMemo(() => {
        let answers = (props.params.row as ISurvey).answers;
        if (answers === undefined || answers === null || answers.length === 0) {
            return null;
        }
        return answers[0];
    }, [props.params.row]);

    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLDivElement>(null);
    const [selectedIndex, setSelectedIndex] = React.useState(1);

    const handleClick = () => {
        // console.info(`You clicked ${options[selectedIndex]}`);
    };

    const handleMenuItemClick = (
        event: React.MouseEvent<HTMLLIElement, MouseEvent>,
        index: number,
    ) => {
        setSelectedIndex(index);
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: Event) => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
        ) {
            return;
        }

        setOpen(false);
    };

    const actions = props.getActions(props.params);


    return (<>

        {/*<ButtonGroup variant="outlined" ref={anchorRef} aria-label="split button" fullWidth={true}>*/}
        {/*<Button onClick={handleClick}>{options[selectedIndex]}</Button>*/}
        <div ref={anchorRef} style={{ width: '100%'}}>
            <Button
                variant="outlined" fullWidth={true}
                size="small"
                // aria-controls={open ? 'split-button-menu' : undefined}
                // aria-expanded={open ? 'true' : undefined}
                // aria-label="select merge strategy"
                // aria-haspopup="menu"
                onClick={handleToggle}
            >
                Actions <ArrowDropDownIcon />
            </Button>
        </div>

        {/*</ButtonGroup>*/}
        <Popper
            sx={{
                zIndex: 1,
            }}
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
        >
            {({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                    style={{
                        transformOrigin:
                            placement === 'bottom' ? 'center top' : 'center bottom',
                    }}
                >
                    <Paper>
                        <ClickAwayListener onClickAway={handleClose}>

                            <ButtonGroup
                                orientation="vertical"
                                aria-label="vertical outlined button group"
                            >
                                {actions.map((option, index) => (
                                    <Button key={index} onClick={() => {
                                        console.log(option)
                                        option.action();
                                    }}>{option.label}</Button>
                                ))}
                            </ButtonGroup>

                            {/*<MenuList id="split-button-menu" autoFocusItem>*/}
                            {/*    {options.map((option, index) => (*/}
                            {/*        <MenuItem*/}
                            {/*            key={option}*/}
                            {/*            disabled={index === 2}*/}
                            {/*            selected={index === selectedIndex}*/}
                            {/*            onClick={(event) => handleMenuItemClick(event, index)}*/}
                            {/*        >*/}
                            {/*            {option}*/}
                            {/*        </MenuItem>*/}
                            {/*    ))}*/}
                            {/*</MenuList>*/}
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>


        {/*<Tooltip title="Open Survey Answers" arrow>*/}
        {/*    <Button variant="outlined" color="primary" fullWidth={true} disabled={answerMemo === null} component={RouterLink} to={`/dashboard/surveyAnswer/${answerMemo !== null ? answerMemo._id : ""}`}>*/}
        {/*        Open Answer*/}
        {/*    </Button>*/}
        {/*</Tooltip>*/}
    </>);
}


