import {PhotoAnswer} from "../../../../types/interfaces";
import React from "react";
import {AuthContext} from "../../../../Library/AuthContext";
import {Box, Button, Paper, Stack, Toolbar, Typography} from "@mui/material";
import {AnswerViewProps, S3GetController} from "../SurveyAnswerEdit";

export function PhotoAnswerView(props: AnswerViewProps) {
    const { answer } = props;

    const {
        userToken
    } = React.useContext(AuthContext)!;

    const [photoURLs, setPhotoURLs] = React.useState<string[]>([]);

    React.useEffect(() => {

        let photoAnswer: PhotoAnswer = answer as PhotoAnswer;
        if (photoAnswer === undefined) {
            photoAnswer = {
                photo: {
                    data: [ ] }
            } as PhotoAnswer;
        }


        if (userToken === undefined || userToken === null) { return }
        let controller = new S3GetController(userToken);
        const fetchPhotoURLs = async () => {
            const urls = await Promise.all(
                photoAnswer.photo.data.map(async (photo) => {
                    return await controller.getURL(photo.bucket, photo.key);
                })
            );
            setPhotoURLs(urls);
        };

        fetchPhotoURLs().then();
    }, [answer, userToken]);

    function uploadImportDocument() {

        if (userToken === undefined || userToken === null) {
            return alert("You must be logged in to upload a photo.");
        }
        let controller = new S3GetController(userToken);

        let input = document.createElement('input');
        input.type = 'file';
        input.accept = '.jpeg';
        input.onchange = async (event: any) => {
            const reader = new FileReader();
            reader.onload = async (e) => {
                if (e.target && e.target.result) {
                    const fileContent = e.target.result as ArrayBuffer;
                    try {

                        let photoAnswer: PhotoAnswer = answer as PhotoAnswer;
                        if (photoAnswer === undefined) {
                            photoAnswer = {
                                photo: {
                                    data: [ ] }
                            } as PhotoAnswer;
                        }
                        console.log(fileContent);
                        let uploadInfo = await controller.createURL(props.surveyAnswer.survey_id, "jpeg");


                        await controller.uploadFile(uploadInfo.url, new File([fileContent], "photo.jpeg", { type: "image/jpeg" }))

                        // This doesn't handle existing images
                        let photosData = photoAnswer.photo.data as { bucket: string, key: string }[];
                        if (photosData === undefined) {
                            photosData = [];
                        }

                        photosData.push({ bucket: uploadInfo.bucket, key: uploadInfo.key });
                        let newPhotosAnswer = { photo: { data: photosData } } as PhotoAnswer;
                        let surveyAnswerObject = props.surveyAnswer.answers[props.surveyObject.key];
                        if (surveyAnswerObject === undefined) {
                            surveyAnswerObject = { values: {} };
                        }
                        let newValues = surveyAnswerObject.values;
                        newValues[props.question.key] = newPhotosAnswer;
                        let newSurveyAnswer = {...props.surveyAnswer, answers: {...props.surveyAnswer.answers, [props.surveyObject.key]: {...surveyAnswerObject, values: newValues}}};
                        props.setSurveyAnswer(newSurveyAnswer);
                        console.log("newValues", newValues);
                    } catch (error: any) {
                        console.error('Error parsing JSON:', error);
                        alert("Error parsing JSON: " + error.message)
                    }
                }
            };

            // reader.readAsText(event.target.files[0]);
            reader.readAsArrayBuffer(event.target.files[0]);
        };
        input.click();
    }

    async function deleteImage(index: number) {
        if (userToken === undefined || userToken === null) {
            return alert("You must be logged in to upload a photo.");
        }
        let controller = new S3GetController(userToken);
        let photoAnswer: PhotoAnswer = answer as PhotoAnswer;
        if (photoAnswer === undefined) {
            photoAnswer = {
                photo: {
                    data: [ ] }
            } as PhotoAnswer;
        }
        try {
            await controller.deleteImage(photoAnswer.photo.data[index].bucket, photoAnswer.photo.data[index].key);
        }
        catch (error: any) {
            console.error('Error:', error);
        }

        let newPhotosAnswer = { photo: { data: [ ...photoAnswer.photo.data ] } } as PhotoAnswer;
        newPhotosAnswer.photo.data.splice(index, 1);
        let surveyAnswerObject = props.surveyAnswer.answers[props.surveyObject.key];
        if (surveyAnswerObject === undefined) {
            surveyAnswerObject = {values: {}};
        }
        let newValues = surveyAnswerObject.values;
        newValues[props.question.key] = newPhotosAnswer;
        let newSurveyAnswer = {...props.surveyAnswer, answers: {...props.surveyAnswer.answers, [props.surveyObject.key]: {...surveyAnswerObject, values: newValues}}};
        props.setSurveyAnswer(newSurveyAnswer);
        console.log("newValues", newValues);
    }

    return (<>

        <Paper style={{ padding: '1rem' }}>
            <Toolbar variant={"dense"} color={"primary"}>
                <Typography variant={"h6"}>{props.question.questionText}</Typography>
                <Box sx={{ flexGrow: 1 }}></Box>
                <Button
                    disabled={props.surveyAnswer.isCompleted || props.disabled}
                    onClick={() => {
                        // alert("TODO: implement photo upload")
                        uploadImportDocument();
                    }}>Upload</Button>
            </Toolbar>

            <div style={{ overflow: 'auto', height: '320px' }}>
                <div style={{ height: '320px', width: `${ 300 * photoURLs.length }px` }}>
                    <Stack direction="row" spacing={2}>
                        {photoURLs.map((url, index) => (

                            <div key={index} style={{ width: '300px', aspectRatio: '1 / 1' }} onDoubleClick={props.surveyAnswer.isCompleted || props.disabled ? undefined : () => {
                                if (window.confirm("Are you sure you want to delete this photo?")) {
                                    deleteImage(index).then();
                                }
                            }}>
                                <img
                                    src={url}
                                    alt={`${index}`}
                                    style={{ objectFit: 'contain', width: '100%', height: '100%' }}
                                />
                            </div>
                        ))}

                    </Stack>
                </div>
            </div>


        </Paper>
    </>);
}
