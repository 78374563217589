import {ISurveyWithId, SurveyDataController} from "../SurveyDataController";
import React from "react";
import {AuthContext} from "../../../Library/AuthContext";
import {ICondition, ISurvey, IUser} from "../../../types/interfaces";
import {UserDataController} from "../../Users/UserDataController";
import {UserRole} from "../../../types/enums";
import {generateCode} from "../SurveyEditorView";
import {Button, Modal, Tooltip} from "@mui/material";
import {CloneSurveyModal} from "./CloneSurveyModal";

export interface CloneSurveyButtonProps {
    userToken: string | null | undefined,
    setList: ((list: ISurveyWithId[]) => void),
    updateList: (() => void),
    usersList: IUser[],
    survey: ISurveyWithId
}

export function CloneSurveyButton(props: CloneSurveyButtonProps) {

    const {
        // user,
        userToken
    } = React.useContext(AuthContext)!;

    const {usersList} = props;

    const [show, setShow] = React.useState<boolean>(false);

    // const [usersList, setUserList] = React.useState<(IUser & {_id: string})[]>([])
    // const [coordinatorsList, setCoordinatorList] = React.useState<(IUser & {_id: string})[]>([])

    // React.useEffect(() => {
    //     async function loadData() {
    //         // console.log(`userToken: ${userToken}`)
    //         if (userToken === null || userToken === undefined) {
    //             return;
    //         }
    //         try {
    //             let list = await new UserDataController(userToken).getAll();
    //             if (list === null || list === undefined) {
    //                 alert("Error loading");
    //                 return;
    //             }
    //             setUserList(list.filter((u) => u.role !== UserRole.CLIENT).sort((a, b) => a.name.localeCompare(b.name)));
    //             // setCoordinatorList(list.filter((u) => u.role === UserRole.MANAGER || u.role === UserRole.ADMIN));
    //         } catch (e) {
    //             console.warn(`exception: ${(e as any).message}`)
    //         }
    //
    //     }
    //     loadData().then();
    // }, [userToken]);


    const updateKeys = React.useCallback((survey: ISurvey) => {
        let updatedSurvey = {...survey};
        let newObjectKeys = new Map<string, string>();

        survey.content.forEach((object) => {
            let newKey = generateCode();
            newObjectKeys.set(object.key, newKey);
        })

        console.log(newObjectKeys)

        updatedSurvey.content = survey.content.map((surveyObject) => {


            let newSurveyObject = {...surveyObject, key: newObjectKeys.get(surveyObject.key) ?? surveyObject.key};

            let objectQuestionKeys = new Map<string, string>();

            surveyObject.questions.forEach((question) => {
                let newKey = generateCode();
                objectQuestionKeys.set(question.key, newKey);
            })

            console.log(objectQuestionKeys)

            // update the questions into new survey Object
            newSurveyObject.questions = newSurveyObject.questions.map((question) => {
                let newQuestion = {...question, key: objectQuestionKeys.get(question.key) ?? question.key};
                newQuestion.conditions = newQuestion.conditions?.map((condition) => {
                    let newCondition = {...condition};
                    newCondition.key = objectQuestionKeys.get(newCondition.key) ?? newCondition.key;
                    return newCondition;
                })

                // TODO: handle the new showInStandardReport and showInExceptionReport and required
                if (newQuestion.required !== undefined && newQuestion.required !== null) {
                    if (Array.isArray(newQuestion.required)) {
                        let conditions = newQuestion.required as ICondition[];
                        conditions.forEach((condition) => {
                            condition.key = objectQuestionKeys.get(condition.key) ?? condition.key;
                        })
                    }
                    else if (typeof newQuestion.required === "object") {
                        let condition = newQuestion.required as ICondition;
                        condition.key = objectQuestionKeys.get(condition.key) ?? condition.key;
                    }
                    else {
                        // boolean
                    }
                }

                if (newQuestion.showInStandardReport !== undefined && newQuestion.showInStandardReport !== null) {
                    if (Array.isArray(newQuestion.showInStandardReport)) {
                        let conditions = newQuestion.showInStandardReport as ICondition[];
                        conditions.forEach((condition) => {
                            condition.key = objectQuestionKeys.get(condition.key) ?? condition.key;
                        })
                    }
                    else if (typeof newQuestion.showInStandardReport === "object") {
                        let condition = newQuestion.showInStandardReport as ICondition;
                        condition.key = objectQuestionKeys.get(condition.key) ?? condition.key;
                    }
                    else {
                        // boolean
                    }
                }

                if (newQuestion.showInExceptionReport !== undefined && newQuestion.showInExceptionReport !== null) {
                    if (Array.isArray(newQuestion.showInExceptionReport)) {
                        let conditions = newQuestion.showInExceptionReport as ICondition[];
                        conditions.forEach((condition) => {
                            condition.key = objectQuestionKeys.get(condition.key) ?? condition.key;
                        })
                    }
                    else if (typeof newQuestion.showInExceptionReport === "object") {
                        let condition = newQuestion.showInExceptionReport as ICondition;
                        condition.key = objectQuestionKeys.get(condition.key) ?? condition.key;
                    }
                    else {
                        // boolean
                    }
                }

                if (newQuestion.showInExceptionCount !== undefined && newQuestion.showInExceptionCount !== null) {
                    if (Array.isArray(newQuestion.showInExceptionCount)) {
                        let conditions = newQuestion.showInExceptionCount as ICondition[];
                        conditions.forEach((condition) => {
                            condition.key = objectQuestionKeys.get(condition.key) ?? condition.key;
                        })
                    }
                    else if (typeof newQuestion.showInExceptionCount === "object") {
                        let condition = newQuestion.showInExceptionCount as ICondition;
                        condition.key = objectQuestionKeys.get(condition.key) ?? condition.key;
                    }
                    else {
                        // boolean
                    }
                }

                return newQuestion;
            })


            return newSurveyObject;
        })

        return updatedSurvey;
    }, []);

    return (<>
        <Modal
            open={show}
            onClose={() => { setShow(false) }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            {<>
                <CloneSurveyModal
                    usersList={usersList}
                    survey={props.survey}
                    onSave={async (survey: ISurvey) => {
                        if (props.userToken === null || props.userToken === undefined) {
                            return;
                        }

                        console.log(survey)
                        let updatedSurvey = updateKeys(survey) as ISurvey;


                        console.log(updatedSurvey)



                        // updating keys

                        await new SurveyDataController(props.userToken).create(updatedSurvey);

                        // let all = await new SurveyDataController(props.userToken).getAll();
                        // setList(all);
                        props.updateList();
                        setShow(false);
                    }}
                    onCancel={() => { setShow(false) }}
                ></CloneSurveyModal>
            </>}
        </Modal>


        <Tooltip title={"Clone Survey"} arrow={true}>
            <Button variant="outlined" color="error" fullWidth={true} onClick={() => {
                setShow(true);
            }}>
                Clone
                {/*<FontAwesomeIcon icon={faCopy} />*/}
            </Button>
        </Tooltip>


    </>);
}
