import {GeoCoordinateAnswer, TextAnswer} from "../../../../types/interfaces";
import React from "react";
import {IconButton, InputAdornment, TextField} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import {AnswerViewProps} from "../SurveyAnswerEdit";

export function GeoCoordinateAnswerView(props: AnswerViewProps) {
    const { answer } = props;

    let geoAnswer: GeoCoordinateAnswer = answer as GeoCoordinateAnswer;
    if (geoAnswer === undefined) {
        geoAnswer = { geoCoordinate: { data: { latitude: 0, longitude: 0 } } } as GeoCoordinateAnswer;
    }

    // const [location, setLocation] = React.useState<{ latitude: number, longitude: number }>({ latitude: 0, longitude: 0 });
    const locationStringMemo = React.useMemo(() => {

        if (geoAnswer === undefined) { return ""; }
        if (geoAnswer.geoCoordinate === undefined) { return ""; }
        if (geoAnswer.geoCoordinate.data === undefined) { return ""; }
        if (geoAnswer.geoCoordinate.data.latitude === undefined) { return ""; }
        if (geoAnswer.geoCoordinate.data.longitude === undefined) { return ""; }

        return `${geoAnswer.geoCoordinate.data.latitude}, ${geoAnswer.geoCoordinate.data.longitude}`
    }, [geoAnswer]);


    let endAdornment = (<InputAdornment position="end">
        <IconButton
            aria-label="Get Location"
            onClick={() => {
                if ('geolocation' in navigator) {
                    // Geolocation is available
                    navigator.geolocation.getCurrentPosition(
                        (position) => {
                            const latitude = position.coords.latitude;
                            const longitude = position.coords.longitude;
                            console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);

                            let newGeoCoordinateAnswer = { geoCoordinate: { data: { latitude: latitude, longitude: longitude } } } as GeoCoordinateAnswer;
                            let surveyAnswerObject = props.surveyAnswer.answers[props.surveyObject.key];
                            if (surveyAnswerObject === undefined || surveyAnswerObject === null || surveyAnswerObject.values === undefined || surveyAnswerObject.values === null) {
                                surveyAnswerObject = { values: {} };
                            }
                            let newValues = surveyAnswerObject.values;
                            newValues[props.question.key] = newGeoCoordinateAnswer;
                            let newSurveyAnswer = {...props.surveyAnswer, answers: {...props.surveyAnswer.answers, [props.surveyObject.key]: {...surveyAnswerObject, values: newValues}}};
                            props.setSurveyAnswer(newSurveyAnswer);
                            console.log("newValues", newValues);


                            // You can use latitude and longitude for your application here
                        },
                        (error) => {
                            console.error('Error getting location:', error);
                        }
                    );
                } else {
                    // Geolocation is not available in this browser
                    console.error('Geolocation is not available.');
                }
            }}
            edge="end"
            disabled={props.surveyAnswer.isCompleted || props.disabled}
        >
            <LocationOnIcon />

        </IconButton>
    </InputAdornment>);

    return (<>
        <TextField
            fullWidth={true}
            type={"text"}
            label={props.question.questionText}
            value={locationStringMemo}
            onChange={(e) => {
                let newTextAnswer = { text: { data: e.target.value as unknown as string } } as TextAnswer;
                let surveyAnswerObject = props.surveyAnswer.answers[props.surveyObject.key];
                if (surveyAnswerObject === undefined) {
                    surveyAnswerObject = { values: {} };
                }
                let newValues = surveyAnswerObject.values;
                newValues[props.question.key] = newTextAnswer;
                let newSurveyAnswer = {...props.surveyAnswer, answers: {...props.surveyAnswer.answers, [props.surveyObject.key]: {...surveyAnswerObject, values: newValues}}};
                props.setSurveyAnswer(newSurveyAnswer);
                console.log("newValues", newValues);
            }}
            disabled={true}
            InputProps={{
                endAdornment: endAdornment,
            }}
        ></TextField>
    </>);
}

