import {ISurvey, SurveyObject, SurveyObjectQuestion, SurveyWarningType} from "../../../types/interfaces";
import {GridCellParams} from "@mui/x-data-grid";
import React from "react";
import {CheckSurveyObjectQuestionForWarnings} from "../../../utils/warningSystem";
import {List, ListItem, ListItemIcon, ListItemText, Tooltip} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleCheck, faShield, faTriangleExclamation} from "@fortawesome/free-solid-svg-icons";

 export function RenderCellValidInvalidIcon(props: { item: SurveyObject, setItem: (item: SurveyObject) => void, survey: ISurvey, setSurvey: (survey: ISurvey) => void, userToken: string, params: GridCellParams}) {
    const row = props.params.row as SurveyObjectQuestion;

    const warningsMemo = React.useMemo(() => {
        return CheckSurveyObjectQuestionForWarnings(props.survey, props.item, row);
    }, [props.item, props.survey, row])


     const warningsTitleMemo = React.useMemo(() => {
         if (warningsMemo.length === 0) {
             // return "No Warnings";
             return (<>
                 <div style={{ maxHeight: '250px', overflowY: "auto"}}>
                     <List>
                         <ListItem>
                             <ListItemIcon>
                                 <FontAwesomeIcon
                                     icon={faCircleCheck}
                                     style={{
                                         color: 'green',
                                         fontSize: '2rem'
                                     }}
                                 />
                             </ListItemIcon>

                             <ListItemText primary={"No Issues"} />
                         </ListItem>
                     </List>
                 </div>
             </>)
         }

         return (<>
             <div style={{ maxHeight: '250px', overflowY: "scroll"}}>
                 <List>
                     {warningsMemo.map((warning) => {
                         return (<>
                             <ListItem>
                                 <ListItemIcon>
                                     <FontAwesomeIcon
                                         icon={warning.type === SurveyWarningType.warning ? faShield : faTriangleExclamation}
                                         style={{
                                             color: warning.type === SurveyWarningType.warning ? 'orange' : 'red',
                                             fontSize: '2rem'
                                         }}
                                     />
                                 </ListItemIcon>

                                 <ListItemText primary={warning.message} />
                             </ListItem>
                         </>)
                     })}
                 </List>
             </div>
         </>)
     }, [warningsMemo])

     const iconMemo = React.useMemo(() => {
         if (warningsMemo.length === 0) {
             return {icon: faCircleCheck, color: 'green'};
         }
         if (warningsMemo.find((item) => item.type === SurveyWarningType.error) !== undefined) {
             return {icon:faTriangleExclamation, color: 'red'};
         }
         return {icon:faShield, color: 'orange'};
     }, [warningsMemo])

     return (<>
         <Tooltip title={warningsTitleMemo} >
             <FontAwesomeIcon
                 icon={iconMemo.icon}
                 style={{
                     color: iconMemo.color,
                     fontSize: '2em'
                 }}
             />
         </Tooltip>
     </>)
}
