import {SurveyWarning, SurveyWarningType} from "../../../types/interfaces";
import {Box, List, ListItem, ListItemIcon, ListItemText, Modal, Typography} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faShield, faTriangleExclamation} from "@fortawesome/free-solid-svg-icons";
import React from "react";

export const WarningsModal = (props: { warnings: SurveyWarning[], onClose: () => void, open: boolean }) => {



    return (<>
        <Modal open={props.open} onClose={props.onClose}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    // width: '60%',
                    width: '850px',
                    height: '600px',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                }}
            >
                <Typography id="modal-modal-title" variant="h5" component="h2" sx={{ mb: 2, textAlign: 'center' }}>

                    <FontAwesomeIcon
                        icon={faTriangleExclamation}
                        style={{
                            color: 'red',
                        }}
                    /> Issues <FontAwesomeIcon
                    icon={faShield}
                    style={{
                        color: 'orange',
                    }}
                />
                </Typography>
                <div style={{ height: 'calc( 100% - 3rem )', overflowY: "scroll"}}>
                    <List>
                        {props.warnings.map((warning) => {
                            return (<>
                                <ListItem>
                                    <ListItemIcon>
                                        <FontAwesomeIcon
                                            icon={warning.type === SurveyWarningType.warning ? faShield : faTriangleExclamation}
                                            style={{
                                                color: warning.type === SurveyWarningType.warning ? 'orange' : 'red',
                                                fontSize: '1.5em'
                                            }}
                                        />
                                    </ListItemIcon>

                                    <ListItemText primary={warning.message} />
                                </ListItem>
                            </>)
                        })}
                    </List>
                </div>

            </Box>
        </Modal>
    </>);
}


