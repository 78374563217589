import {CustomFormField, ISurvey, IUser, SurveyObject} from "../../../types/interfaces";
import React from "react";
import {CustomFormFieldSizes, CustomFormFieldTypes} from "../../../types/enums";
import {Box, Button, Typography} from "@mui/material";
import {EditObject} from "../../../Shared/Components/EditObject";

export function ImportSurvey(props: any) {

    interface importedDataType {
        content: SurveyObject[],
        conditionFlags: any
    }

    const [importedData, setImportedData] = React.useState<importedDataType>({content: [], conditionFlags: {}});

    function uploadImportDocument() {
        let input = document.createElement('input');
        input.type = 'file';
        input.accept = '.json';
        input.onchange = async (event: any) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                if (e.target && e.target.result) {
                    const fileContent = e.target.result as string;
                    try {
                        console.log(fileContent);
                        const jsonData = JSON.parse(fileContent);
                        setImportedData (jsonData);
                        // we now have the data from the file.

                        console.log(jsonData);
                        // alert("Imported " + jsonData.length + " content.");
                    } catch (error: any) {
                        console.error('Error parsing JSON:', error);
                        alert("Error parsing JSON: " + error.message)
                    }
                }
            };

            reader.readAsText(event.target.files[0]);
            // reader.readAsArrayBuffer(event.target.files[0]);
        };
        input.click();
    }

    const form: CustomFormField[] = [
        {
            kind: CustomFormFieldTypes.TEXT,
            size: CustomFormFieldSizes.FULL,
            key: "caseNumber",
            label: "Case Number"
        },
        {
            kind: CustomFormFieldTypes.SELECT,
            size: CustomFormFieldSizes.FULL,
            key: "assigned_user_id",
            label: "Assigned User Id",
            options: [
                { label: "Un-assigned", value: null },
                ...props.usersList.map((opt: (IUser & {_id: string})) => { return { label: opt.name, value: opt._id }; })
            ]
        },
    ];
    const [survey, setSurvey] = React.useState<ISurvey>({
        caseNumber: "",
        assigned_user_id: null,
        content: [],
        locations: [],
    });

    return (
        <Box sx={{
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4
        }}>
            <Typography id="modal-modal-title" variant="h5" component="h2" sx={{ mb: 2, textAlign: 'center' }}>
                Import Survey
            </Typography>

            <Button variant="outlined" color="error" fullWidth={true} onClick={() => {
                uploadImportDocument();
            }}>
                Import JSON File
            </Button>

            <EditObject item={survey} setItem={setSurvey} form={form} columns={5}></EditObject>

            <br />

            <Button type={"submit"} variant={"contained"} size={"large"} fullWidth={true} onClick={() => {
                let newSurvey: ISurvey = {
                    caseNumber: survey.caseNumber,
                    assigned_user_id: survey.assigned_user_id,
                    content: importedData.content,
                    conditionFlags: importedData.conditionFlags,
                    locations: [],
                };
                props.onSave(newSurvey);
            }} disabled={ importedData.content.length === 0 || survey === undefined || survey['caseNumber'] === "" }>CREATE</Button>

        </Box>
    );

}

