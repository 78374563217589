import {AppBar, Box, IconButton, Toolbar, Typography} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import React from "react";
import {AuthContext} from "./AuthContext";

function AuthenticatedNavBar(props: {showSidebar: boolean, setShowSidebar: any}) {
    const { showSidebar, setShowSidebar } = props;

    const {
        user
    } = React.useContext(AuthContext)!;

    const baseURL = process.env.REACT_APP_SERVER_URL || "127.0.0.1:3000";
    let titleText = "POST"
    switch (baseURL) {
        case "127.0.0.1:3000":
            titleText += " (Local)"
            break;
        case "survey-stage.post.pivital.com":
            titleText += " (Stage)"
            break;
        case "survey.post.pivital.com":
            titleText += " (Prod)"
            break;
        case "survey-demo.post.pivital.com":
            titleText += " (Demo)"
            break;
        default:
            break;
    }

    return (
        <div>
            <AppBar position="static">
                <Toolbar variant="dense">
                    <IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} onClick={() => {
                        setShowSidebar(!showSidebar)
                    }}>
                    <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" color="inherit" component="div">
                        {titleText}
                    </Typography>
                    <Box sx={{ flexGrow: 1 }}></Box>

                    <Typography variant="body1" color="inherit" component="div">
                        {user?.name || ""} [{user?.email_address || ""}]
                    </Typography>

                </Toolbar>
            </AppBar>
        </div>
    );
}

export default AuthenticatedNavBar;
