import {CustomFormField, ISurvey, SurveyObject} from "../../../types/interfaces";
import React, {Dispatch} from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary, Button,
    List,
    ListItem, ListItemText,
    ListSubheader,
    Paper, Switch,
    Typography
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const ConditionFlagsPassThru = (props: { survey: ISurvey, surveyObject: SurveyObject, item: any, setItem: Dispatch<any>, field: CustomFormField, expanded: string | false, handleAccordionChange: any }) => {

    const ConditionFlagsToBeRemovedMemo = React.useMemo(() => {
        let conditionFlagsToBeRemoved: string[] = [];
        if (props.survey.conditionFlags === undefined) {
            conditionFlagsToBeRemoved = props.item.requiredConditionFlags;
        }
        if (props.survey.conditionFlags !== undefined && props.item.requiredConditionFlags !== undefined) {

            props.item.requiredConditionFlags.forEach((conditionFlagKey: string) => {
                if (props.survey.conditionFlags !== undefined && props.survey.conditionFlags[conditionFlagKey] === undefined) {
                    conditionFlagsToBeRemoved.push(conditionFlagKey);
                }
            })
        }
        return conditionFlagsToBeRemoved;
    }, [ props.survey.conditionFlags, props.item.requiredConditionFlags ]);

    return (<>
        {props.survey.conditionFlags !== undefined && Object.keys(props.survey.conditionFlags).length && (
            <Accordion expanded={props.expanded === 'conditionFlags'} onChange={props.handleAccordionChange('conditionFlags')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Typography sx={{ width: '33%', flexShrink: 0 }}>
                        Condition Flags
                    </Typography>
                    <Typography sx={{ color: 'text.secondary' }}>Show/Hide questions based on Survey Condition flags</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Paper variant={"outlined"} sx={{padding: 2}}>
                        {props.survey.conditionFlags !== undefined && (
                            <>
                                {/*<pre>requiredConditionFlags: {JSON.stringify(props.item.requiredConditionFlags)}</pre>*/}
                                <Paper elevation={1}>
                                    <List
                                        sx={{ width: '100%', bgcolor: 'background.paper' }}
                                        subheader={<ListSubheader>Condition Flags</ListSubheader>}
                                    >
                                        {Object.keys(props.survey.conditionFlags).map((conditionFlagKey) => {
                                            return (<>
                                                <ListItem>
                                                    <ListItemText id="switch-list-label-wifi" primary={conditionFlagKey} />



                                                    <Switch
                                                        edge="end"
                                                        onChange={() =>{
                                                            let newItem = { ...props.item };
                                                            if (newItem.requiredConditionFlags === undefined) {
                                                                newItem.requiredConditionFlags = [];
                                                            }
                                                            if (newItem.requiredConditionFlags.includes(conditionFlagKey)) {
                                                                newItem.requiredConditionFlags = newItem.requiredConditionFlags.filter((flag: string) => flag !== conditionFlagKey);
                                                            }
                                                            else {
                                                                newItem.requiredConditionFlags.push(conditionFlagKey);
                                                            }
                                                            props.setItem(newItem);
                                                        }}
                                                        checked={props.item.requiredConditionFlags === undefined ? false : props.item.requiredConditionFlags.includes(conditionFlagKey)}
                                                        inputProps={{
                                                            'aria-labelledby': 'switch-list-label-wifi',
                                                        }}
                                                    />

                                                </ListItem>
                                            </>)
                                        })}



                                    </List>

                                    {ConditionFlagsToBeRemovedMemo.length > 0 && (
                                        <List
                                            sx={{ width: '100%', bgcolor: 'background.paper' }}
                                            subheader={<ListSubheader>Missing Condition Flags</ListSubheader>}
                                        >
                                            {ConditionFlagsToBeRemovedMemo.map((conditionFlagKey) => {
                                                return (<>
                                                    <ListItem>
                                                        <ListItemText id="switch-list-label-wifi" primary={conditionFlagKey} />

                                                        <Button onClick={() => {
                                                            let newItem = { ...props.item };
                                                            if (newItem.requiredConditionFlags === undefined) {
                                                                newItem.requiredConditionFlags = [];
                                                            }
                                                            newItem.requiredConditionFlags = newItem.requiredConditionFlags.filter((flag: string) => flag !== conditionFlagKey);
                                                            props.setItem(newItem);
                                                        }}>Remove</Button>
                                                    </ListItem>
                                                </>)
                                            })}
                                        </List>
                                    )}


                                    {/*ConditionFlagsToBeRemovedMemo*/}
                                    {/*<DebugView value={ConditionFlagsToBeRemovedMemo} />*/}
                                    {/*conditionFlags*/}
                                    {/*<DebugView value={props.survey.conditionFlags} />*/}
                                    {/*requiredConditionFlags*/}
                                    {/*<DebugView value={props.item.requiredConditionFlags} />*/}
                                </Paper>
                            </>
                        )}
                    </Paper>
                </AccordionDetails>
            </Accordion>
        )}
    </>);
};


