import {DataGrid, GridCellParams, GridColDef, GridValidRowModel} from "@mui/x-data-grid";
import React from "react";
import {AuthContext} from "../../../Library/AuthContext";
import {ISurvey} from "../../../types/interfaces";
import {Box, Button, IconButton, Modal, Stack, Tooltip, Typography} from "@mui/material";
import {ReportDataController} from "../../Reports/ReportDataController";
import {Download as DownloadIcon} from "@mui/icons-material";
import {ReportType, UserRole} from "../../../types/enums";
import {RenderCellDeleteReportButton} from "./RenderCellDeleteReportButton";
import {GenerateButton} from "./GenerateButton";

export function RenderCellGenerateSurveyReport(props: { params: GridCellParams, updateListRequested: (() => void) }) {

    const {
        user,
        userToken
    } = React.useContext(AuthContext)!;

    const [show, setShow] = React.useState<boolean>(false);

    const answerMemo = React.useMemo(() => {
        let answers = (props.params.row as ISurvey).answers;
        if (answers === undefined || answers === null || answers.length === 0) {
            return null;
        }
        return answers[0];
    }, [props.params.row]);

    const columns = React.useMemo<GridColDef[]>(() => {

        if (answerMemo === null || answerMemo === undefined) {
            // alert("No Answer Memo");
            return [];
        }

        let columns: GridColDef[] = [];

        columns.push({field: 'date', headerName: 'Date', width: 200,
            valueFormatter: (params) => {
                let date = new Date(params.value as string);
                return date.toLocaleString();
            }
        });

        columns.push({field: 'type', headerName: 'Type', width: 200});

        columns.push({ field: 'download', headerName: '', width: 75, renderCell: (params: GridCellParams) => {
                return (<IconButton color={"success"} onClick={() => {
                    let item = params.row as {key: string, bucket: string, type: string };
                    async function download(){
                        if (userToken === null || userToken === undefined) {
                            return;
                        }
                        let bucket = encodeURIComponent(item.bucket);
                        let key = encodeURIComponent(item.key);
                        let url = await new ReportDataController(userToken).downloadReport(bucket, key);
                        window.open(url, '_blank');
                    }

                    download().then();
                }}><DownloadIcon /></IconButton>)
            }})

        if (user?.role !== UserRole.CLIENT) {
            columns.push({
                field: 'delete_btn',
                headerName: '',
                sortable: false,
                width: 100,
                renderCell: (params: GridCellParams) => (
                    <RenderCellDeleteReportButton userToken={userToken} rowData={answerMemo} onRemove={() => {
                        props.updateListRequested();
                    }} params={params}/>
                ),
            })
        }

        if (user?.role !== UserRole.CLIENT) {
            columns.push({field: 'bucket', headerName: 'Bucket', width: 500})

            columns.push({
                field: 'key', headerName: 'Key', width: 500,
                valueGetter: (params: GridCellParams) => {
                    let item = params.row as { key: string, bucket: string, type: string };
                    return item.key.split("/").pop();
                }
            })
        }

        return columns;
    }, [user]);

    return (<>
        <Tooltip title="Generate Survey Report" arrow>
            <Button variant="outlined" color="error" fullWidth={true} disabled={answerMemo === null} onClick={() => {
                setShow(true);
            }}>
                Report
            </Button>
        </Tooltip>

        {show && answerMemo !== null && (
            <Modal
                open={show}
                onClose={() => { setShow(false) }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute' as 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 1000,
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 4
                }}>
                    <Typography id="modal-modal-title" variant="h5" component="h2" sx={{ mb: 2, textAlign: 'center' }}>
                        Survey Report
                    </Typography>

                    {/*<DebugView value={answerMemo} />*/}
                    <Stack direction={"column"} spacing={2}>
                        <Stack direction={"row"} spacing={2}>
                            <GenerateButton survey_id={(props.params.row as ISurvey & {_id: string})._id} reportType={ReportType.Standard} reportTypeLabel={"Standard"} updateListRequested={() => {
                                // props.updateListRequested();
                            }} />
                            <GenerateButton survey_id={(props.params.row as ISurvey & {_id: string})._id} reportType={ReportType.Exception} reportTypeLabel={"Exception"} updateListRequested={() => {
                                // props.updateListRequested();
                            }} />
                            <GenerateButton survey_id={(props.params.row as ISurvey & {_id: string})._id} reportType={ReportType.FullResolutionImages} reportTypeLabel={"Photo Zip"} updateListRequested={() => {
                                // props.updateListRequested();
                            }} />
                            <GenerateButton survey_id={(props.params.row as ISurvey & {_id: string})._id} reportType={ReportType.SpreadSheet} reportTypeLabel={"Excel"} updateListRequested={() => {
                                // props.updateListRequested();
                            }} />


                            {user?.role === UserRole.ADMIN && (<GenerateButton survey_id={(props.params.row as ISurvey & {_id: string})._id} reportType={ReportType.CreatorReport} reportTypeLabel={"Creator Report"} updateListRequested={() => {
                                // props.updateListRequested();
                            }} />)}

                            {/*{user.role === UserRole.ADMIN && (<GenerateButton survey_id={(props.params.row as ISurvey & {_id: string})._id} reportType={ReportType.CreatorReport} reportTypeLabel={"Creator Report"} updateListRequested={() => {*/}
                            {/*<GenerateButton survey_id={(props.params.row as ISurvey & {_id: string})._id} reportType={ReportType.CreatorReport} reportTypeLabel={"Creator Report"} updateListRequested={() => {*/}
                            {/*    props.updateListRequested();*/}
                            {/*}} />*/}
                            {/*}}*/}
                        </Stack>

                        {answerMemo.reports.length === 0 ? (<Typography sx={{ textAlign: 'center', marginTop: 2 }}>No Reports Generated</Typography>) : (<DataGrid
                            // make sure to set height to 100% so that the grid fills the whole container
                            style={{height: '100%'}}
                            rows={user?.role === UserRole.CLIENT ? answerMemo.reports.filter((obj) => obj.type !== ReportType.CreatorReport) : answerMemo.reports}
                            columns={columns}
                            getRowId={(row: GridValidRowModel) => row.key}
                            pageSizeOptions={[100]}
                            disableRowSelectionOnClick
                            initialState={{
                                sorting: {
                                    sortModel: [{ field: 'date', sort: 'desc' }],
                                },
                            }}
                        />)}

                    </Stack>
                </Box>
            </Modal>
        )}

    </>);
}
