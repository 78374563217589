import {
    DataController, ICase, ICaseAnswer, ICaseComment, ICaseDeliverable, ITimeEntry
} from "../../types/interfaces";

export type ICaseWithId = ICase & { _id?: string };

export class CaseDataController implements DataController<ICaseWithId> {

    private readonly baseURL : string

    constructor(private userToken: string) {
        // Nothing to do here yet.
        // const WS_URL = `ws${ process.env.REACT_APP_SERVER_URL ? "s" :"" }://${baseURL}`;
        const domainURL: string = process.env.REACT_APP_SERVER_URL || "127.0.0.1:3000";
        const protocol: string = `http${ process.env.REACT_APP_SERVER_URL ? "s" :"" }`;
        this.baseURL = `${protocol}://${domainURL}`;
    }

    public async getAll(): Promise<ICaseWithId[]> {
        let results = await fetch(`${this.baseURL}/api/cases`, {
            headers: {
                "authorization": `Bearer ${this.userToken}`
            }
        });
        let data = await results.json();
        return await data; // this doesn't seem to type check.
    }

    public async getAllArchived(): Promise<ICaseWithId[]> {
        let results = await fetch(`${this.baseURL}/api/cases/ArchiveList`, {
            headers: {
                "authorization": `Bearer ${this.userToken}`
            }
        });
        let data = await results.json();
        return await data; // this doesn't seem to type check.
    }

    public async getOne(id: String): Promise<ICaseWithId> {
        let results = await fetch(`${this.baseURL}/api/cases/${id}`, {
            headers: {
                "authorization": `Bearer ${this.userToken}`
            }
        });
        let data = await results.json();
        // console.log(data);
        return await data; // this doesn't seem to type check.
    }

    public async create(item: ICase): Promise<ICaseWithId> {
        let results = await fetch(`${this.baseURL}/api/cases/`, {
            method: "POST",
            headers: {
                "authorization": `Bearer ${this.userToken}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(item)
        });
        let data = await results.json();
        console.log(data);
        return await data.item; // this doesn't seem to type check.
    }

    public async import(item: ICaseWithId, sourceSurveyId: string): Promise<ICaseWithId> {
        let results = await fetch(`${this.baseURL}/api/cases/create`, {
            method: "POST",
            headers: {
                "authorization": `Bearer ${this.userToken}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({doc: item, sourceSurveyId: sourceSurveyId})
        });
        let data = await results.json();
        console.log(data);
        return await data.item; // this doesn't seem to type check.
    }

    public async update(id: string, item: ICaseWithId): Promise<ICaseWithId> {
        let results = await fetch(`${this.baseURL}/api/cases/${id}`, {
            method: "PUT",
            headers: {
                "authorization": `Bearer ${this.userToken}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(item)
        });

        // handle when results is 304
        if (results.status === 304) {
            console.log("304");
            return item;
        }

        console.log(results)
        let data = await results.json();
        console.log(data);
        return await data; // this doesn't seem to type check. change to .item when api updated
    }

    public async delete(id: string): Promise<ICaseWithId> {
        let results = await fetch(`${this.baseURL}/api/cases/${id}`, {
            method: "DELETE",
            headers: {
                "authorization": `Bearer ${this.userToken}`,
                "Content-Type": "application/json"
            }
            // body: JSON.stringify(item)
        });

        console.log(results)
        let data = await results.json();
        console.log(data);
        return await data; // this doesn't seem to type check. change to .item when api updated
    }

    public async downloadExport(id: string): Promise<string> {
        let results = await fetch(`${this.baseURL}/api/cases/export/${id}`, {
            headers: {
                "authorization": `Bearer ${this.userToken}`
            }
        });
        let data = await results.text();
        console.log(data);

        return data;
    }

}



export class CaseAnswerDataController {

    private readonly baseURL : string

    constructor(private userToken: string) {
        // Nothing to do here yet.
        // const WS_URL = `ws${ process.env.REACT_APP_SERVER_URL ? "s" :"" }://${baseURL}`;
        const domainURL: string = process.env.REACT_APP_SERVER_URL || "127.0.0.1:3000";
        const protocol: string = `http${ process.env.REACT_APP_SERVER_URL ? "s" :"" }`;
        this.baseURL = `${protocol}://${domainURL}`;
    }


    public async getOne(id: string): Promise<ICaseAnswer> {
        // this id is actually caseNumber
        let results = await fetch(`${this.baseURL}/api/caseAnswers/${encodeURIComponent(id)}`, {
            headers: {
                "authorization": `Bearer ${this.userToken}`
            }
        });
        let data = await results.json();
        // console.log(data);
        return await data; // this doesn't seem to type check.
    }



}








export class CaseCommentDataController {

    private readonly baseURL : string

    constructor(private userToken: string) {
        // Nothing to do here yet.
        // const WS_URL = `ws${ process.env.REACT_APP_SERVER_URL ? "s" :"" }://${baseURL}`;
        const domainURL: string = process.env.REACT_APP_SERVER_URL || "127.0.0.1:3000";
        const protocol: string = `http${ process.env.REACT_APP_SERVER_URL ? "s" :"" }`;
        this.baseURL = `${protocol}://${domainURL}`;
    }

    // public async getAll(): Promise<ICaseWithId[]> {
    //     let results = await fetch(`${this.baseURL}/api/casesComments`, {
    //         headers: {
    //             "authorization": `Bearer ${this.userToken}`
    //         }
    //     });
    //     let data = await results.json();
    //     return await data; // this doesn't seem to type check.
    // }

    // public async getAllArchived(): Promise<ICaseWithId[]> {
    //     let results = await fetch(`${this.baseURL}/api/cases/ArchiveList`, {
    //         headers: {
    //             "authorization": `Bearer ${this.userToken}`
    //         }
    //     });
    //     let data = await results.json();
    //     return await data; // this doesn't seem to type check.
    // }

    public async getOne(id: String): Promise<ICaseComment[]> {
        let results = await fetch(`${this.baseURL}/api/caseComments/${id}`, {
            headers: {
                "authorization": `Bearer ${this.userToken}`
            }
        });
        let data = await results.json();
        // console.log(data);
        return await data; // this doesn't seem to type check.
    }

    public async create(id: string, item: ICaseComment): Promise<ICaseComment> {
        let results = await fetch(`${this.baseURL}/api/caseComments/${id}`, {
            method: "POST",
            headers: {
                "authorization": `Bearer ${this.userToken}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(item)
        });
        let data = await results.json();
        console.log(data);
        return await data; // this doesn't seem to type check.
    }
    //
    // public async import(item: ICaseWithId, sourceSurveyId: string): Promise<ICaseWithId> {
    //     let results = await fetch(`${this.baseURL}/api/cases/create`, {
    //         method: "POST",
    //         headers: {
    //             "authorization": `Bearer ${this.userToken}`,
    //             "Content-Type": "application/json"
    //         },
    //         body: JSON.stringify({doc: item, sourceSurveyId: sourceSurveyId})
    //     });
    //     let data = await results.json();
    //     console.log(data);
    //     return await data.item; // this doesn't seem to type check.
    // }
    //
    // public async update(id: string, item: ICaseWithId): Promise<ICaseWithId> {
    //     let results = await fetch(`${this.baseURL}/api/cases/${id}`, {
    //         method: "PUT",
    //         headers: {
    //             "authorization": `Bearer ${this.userToken}`,
    //             "Content-Type": "application/json"
    //         },
    //         body: JSON.stringify(item)
    //     });
    //
    //     // handle when results is 304
    //     if (results.status === 304) {
    //         console.log("304");
    //         return item;
    //     }
    //
    //     console.log(results)
    //     let data = await results.json();
    //     console.log(data);
    //     return await data; // this doesn't seem to type check. change to .item when api updated
    // }
    //
    // public async delete(id: string): Promise<ICaseWithId> {
    //     let results = await fetch(`${this.baseURL}/api/cases/${id}`, {
    //         method: "DELETE",
    //         headers: {
    //             "authorization": `Bearer ${this.userToken}`,
    //             "Content-Type": "application/json"
    //         }
    //         // body: JSON.stringify(item)
    //     });
    //
    //     console.log(results)
    //     let data = await results.json();
    //     console.log(data);
    //     return await data; // this doesn't seem to type check. change to .item when api updated
    // }
    //
    // public async downloadExport(id: string): Promise<string> {
    //     let results = await fetch(`${this.baseURL}/api/cases/export/${id}`, {
    //         headers: {
    //             "authorization": `Bearer ${this.userToken}`
    //         }
    //     });
    //     let data = await results.text();
    //     console.log(data);
    //
    //     return data;
    // }

}




export class CaseDeliverablesDataController {

    private readonly baseURL : string

    constructor(private userToken: string) {
        // Nothing to do here yet.
        // const WS_URL = `ws${ process.env.REACT_APP_SERVER_URL ? "s" :"" }://${baseURL}`;
        const domainURL: string = process.env.REACT_APP_SERVER_URL || "127.0.0.1:3000";
        const protocol: string = `http${ process.env.REACT_APP_SERVER_URL ? "s" :"" }`;
        this.baseURL = `${protocol}://${domainURL}`;
    }

    // public async getAll(): Promise<ICaseWithId[]> {
    //     let results = await fetch(`${this.baseURL}/api/casesComments`, {
    //         headers: {
    //             "authorization": `Bearer ${this.userToken}`
    //         }
    //     });
    //     let data = await results.json();
    //     return await data; // this doesn't seem to type check.
    // }

    // public async getAllArchived(): Promise<ICaseWithId[]> {
    //     let results = await fetch(`${this.baseURL}/api/cases/ArchiveList`, {
    //         headers: {
    //             "authorization": `Bearer ${this.userToken}`
    //         }
    //     });
    //     let data = await results.json();
    //     return await data; // this doesn't seem to type check.
    // }

    public async getOne(id: String): Promise<ICaseDeliverable[]> {
        let results = await fetch(`${this.baseURL}/api/caseDeliverables/${id}`, {
            headers: {
                "authorization": `Bearer ${this.userToken}`
            }
        });
        let data = await results.json();
        // console.log(data);
        return await data; // this doesn't seem to type check.
    }

    // public async create(id: string, item: ICaseComment): Promise<ICaseComment> {
    //     let results = await fetch(`${this.baseURL}/api/caseComments/${id}`, {
    //         method: "POST",
    //         headers: {
    //             "authorization": `Bearer ${this.userToken}`,
    //             "Content-Type": "application/json"
    //         },
    //         body: JSON.stringify(item)
    //     });
    //     let data = await results.json();
    //     console.log(data);
    //     return await data; // this doesn't seem to type check.
    // }
    //
    // public async import(item: ICaseWithId, sourceSurveyId: string): Promise<ICaseWithId> {
    //     let results = await fetch(`${this.baseURL}/api/cases/create`, {
    //         method: "POST",
    //         headers: {
    //             "authorization": `Bearer ${this.userToken}`,
    //             "Content-Type": "application/json"
    //         },
    //         body: JSON.stringify({doc: item, sourceSurveyId: sourceSurveyId})
    //     });
    //     let data = await results.json();
    //     console.log(data);
    //     return await data.item; // this doesn't seem to type check.
    // }
    //
    // public async update(id: string, item: ICaseWithId): Promise<ICaseWithId> {
    //     let results = await fetch(`${this.baseURL}/api/cases/${id}`, {
    //         method: "PUT",
    //         headers: {
    //             "authorization": `Bearer ${this.userToken}`,
    //             "Content-Type": "application/json"
    //         },
    //         body: JSON.stringify(item)
    //     });
    //
    //     // handle when results is 304
    //     if (results.status === 304) {
    //         console.log("304");
    //         return item;
    //     }
    //
    //     console.log(results)
    //     let data = await results.json();
    //     console.log(data);
    //     return await data; // this doesn't seem to type check. change to .item when api updated
    // }
    //
    // public async delete(id: string): Promise<ICaseWithId> {
    //     let results = await fetch(`${this.baseURL}/api/cases/${id}`, {
    //         method: "DELETE",
    //         headers: {
    //             "authorization": `Bearer ${this.userToken}`,
    //             "Content-Type": "application/json"
    //         }
    //         // body: JSON.stringify(item)
    //     });
    //
    //     console.log(results)
    //     let data = await results.json();
    //     console.log(data);
    //     return await data; // this doesn't seem to type check. change to .item when api updated
    // }
    //
    // public async downloadExport(id: string): Promise<string> {
    //     let results = await fetch(`${this.baseURL}/api/cases/export/${id}`, {
    //         headers: {
    //             "authorization": `Bearer ${this.userToken}`
    //         }
    //     });
    //     let data = await results.text();
    //     console.log(data);
    //
    //     return data;
    // }

}






export class CaseTimeEntriesDataController {

    private readonly baseURL : string

    constructor(private userToken: string) {
        // Nothing to do here yet.
        // const WS_URL = `ws${ process.env.REACT_APP_SERVER_URL ? "s" :"" }://${baseURL}`;
        const domainURL: string = process.env.REACT_APP_SERVER_URL || "127.0.0.1:3000";
        const protocol: string = `http${ process.env.REACT_APP_SERVER_URL ? "s" :"" }`;
        this.baseURL = `${protocol}://${domainURL}`;
    }

    // public async getAll(): Promise<ICaseWithId[]> {
    //     let results = await fetch(`${this.baseURL}/api/casesComments`, {
    //         headers: {
    //             "authorization": `Bearer ${this.userToken}`
    //         }
    //     });
    //     let data = await results.json();
    //     return await data; // this doesn't seem to type check.
    // }

    // public async getAllArchived(): Promise<ICaseWithId[]> {
    //     let results = await fetch(`${this.baseURL}/api/cases/ArchiveList`, {
    //         headers: {
    //             "authorization": `Bearer ${this.userToken}`
    //         }
    //     });
    //     let data = await results.json();
    //     return await data; // this doesn't seem to type check.
    // }

    public async getOne(id: String): Promise<ITimeEntry[]> {
        let results = await fetch(`${this.baseURL}/api/timeEntries/case/${id}/all`, {
            headers: {
                "authorization": `Bearer ${this.userToken}`
            }
        });
        let data = await results.json();
        // console.log(data);
        return await data; // this doesn't seem to type check.
    }

    // public async create(id: string, item: ICaseComment): Promise<ICaseComment> {
    //     let results = await fetch(`${this.baseURL}/api/caseComments/${id}`, {
    //         method: "POST",
    //         headers: {
    //             "authorization": `Bearer ${this.userToken}`,
    //             "Content-Type": "application/json"
    //         },
    //         body: JSON.stringify(item)
    //     });
    //     let data = await results.json();
    //     console.log(data);
    //     return await data; // this doesn't seem to type check.
    // }
    //
    // public async import(item: ICaseWithId, sourceSurveyId: string): Promise<ICaseWithId> {
    //     let results = await fetch(`${this.baseURL}/api/cases/create`, {
    //         method: "POST",
    //         headers: {
    //             "authorization": `Bearer ${this.userToken}`,
    //             "Content-Type": "application/json"
    //         },
    //         body: JSON.stringify({doc: item, sourceSurveyId: sourceSurveyId})
    //     });
    //     let data = await results.json();
    //     console.log(data);
    //     return await data.item; // this doesn't seem to type check.
    // }
    //
    // public async update(id: string, item: ICaseWithId): Promise<ICaseWithId> {
    //     let results = await fetch(`${this.baseURL}/api/cases/${id}`, {
    //         method: "PUT",
    //         headers: {
    //             "authorization": `Bearer ${this.userToken}`,
    //             "Content-Type": "application/json"
    //         },
    //         body: JSON.stringify(item)
    //     });
    //
    //     // handle when results is 304
    //     if (results.status === 304) {
    //         console.log("304");
    //         return item;
    //     }
    //
    //     console.log(results)
    //     let data = await results.json();
    //     console.log(data);
    //     return await data; // this doesn't seem to type check. change to .item when api updated
    // }
    //
    // public async delete(id: string): Promise<ICaseWithId> {
    //     let results = await fetch(`${this.baseURL}/api/cases/${id}`, {
    //         method: "DELETE",
    //         headers: {
    //             "authorization": `Bearer ${this.userToken}`,
    //             "Content-Type": "application/json"
    //         }
    //         // body: JSON.stringify(item)
    //     });
    //
    //     console.log(results)
    //     let data = await results.json();
    //     console.log(data);
    //     return await data; // this doesn't seem to type check. change to .item when api updated
    // }
    //
    // public async downloadExport(id: string): Promise<string> {
    //     let results = await fetch(`${this.baseURL}/api/cases/export/${id}`, {
    //         headers: {
    //             "authorization": `Bearer ${this.userToken}`
    //         }
    //     });
    //     let data = await results.text();
    //     console.log(data);
    //
    //     return data;
    // }

}




