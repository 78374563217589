import {
    ICondition,
    ISurvey,
    ISurveyAnswer,
    ObjectAnswer,
    SelectAnswer,
    SurveyObject,
    ToggleAnswer
} from "../../../types/interfaces";
import {GridCellParams} from "@mui/x-data-grid";
import React from "react";
import {Typography} from "@mui/material";

export function createContentWithClone(surveyObjects: SurveyObject[], answer: ISurveyAnswer, survey: ISurvey, isException: boolean): SurveyObject[] {
    let content: SurveyObject[] = [];

    surveyObjects.forEach((obj) => {
        // Apply the filter to each SurveyObject based on the condition flags
        const filteredObj = filterObjectQuestionBasedOnConditions(survey, obj);

        // Clone the filtered SurveyObject and add it to the content array
        content.push(JSON.parse(JSON.stringify(filteredObj)));

        // Process any clones specified in the answer
        if (answer.clones && answer.clones[obj.key] !== undefined) {
            let cloneIds = answer.clones[obj.key];
            cloneIds.forEach((cloneId: { key: string, parent?: string }) => {
                let clonedItem = {
                    ...filteredObj,
                    key: cloneId.key,
                    parent: cloneId.parent ?? filteredObj.parent,
                };
                content.push(JSON.parse(JSON.stringify(clonedItem)));
            });
        }
    });

    if (isException) {
        // Filter out any SurveyObjects that have no questions
        content = content.filter(obj => obj.questions.length > 0);
    }



    return content;
}

function filterObjectQuestionBasedOnConditions(survey: ISurvey, surveyObject: SurveyObject): SurveyObject {
    // If there are no condition flags, return the survey object as is
    if (!survey.conditionFlags) {
        return surveyObject;
    }

    // Filter questions based on condition flags
    const newQuestions = surveyObject.questions.filter(question => {
        // If no specific condition flags are required, include the question
        if (!question.requiredConditionFlags) {
            return true;
        }

        // Check if all required conditions are met
        const passedConditions = question.requiredConditionFlags.filter(condition =>
            survey.conditionFlags ? survey.conditionFlags[condition] : false
        );

        return passedConditions.length === question.requiredConditionFlags.length;
    });

    // Return a new survey object with the filtered questions
    return { ...surveyObject, questions: newQuestions };
}

export function showInReport(value: boolean | ICondition | ICondition[], objectAnswers: ObjectAnswer) : boolean {
    if (value === undefined || value === null){
        return true;
    }

    if (value === true || value === false){
        return value;
    }

    // handle if is a condition
    if (Array.isArray(value)){
        // multiple conditions

        let multipleConditions = value as ICondition[];
        // console.log("multipleConditions", multipleConditions);
        let multipleConditionsResult = multipleConditions.filter((singleCondition) => {
            // console.log("condition", singleCondition);
            let conditionAnswer = objectAnswers.values[singleCondition.key];
            // console.log("conditionAnswer", conditionAnswer);
            if (conditionAnswer === undefined || conditionAnswer === null){
                return true;
            }
            switch (true) {
                case "toggle" in conditionAnswer:
                    let localToggleAnswer = conditionAnswer as ToggleAnswer;
                    switch (singleCondition.op) {
                        case "=":
                            console.log("localToggleAnswer.toggle.data === singleCondition.value", localToggleAnswer.toggle.data === singleCondition.value)
                            return localToggleAnswer.toggle.data === singleCondition.value;
                        case "≠":
                            console.log("localToggleAnswer.toggle.data !== singleCondition.value", localToggleAnswer.toggle.data !== singleCondition.value)
                            return localToggleAnswer.toggle.data !== singleCondition.value;
                    }
                    // return localToggleAnswer.toggle.data === singleCondition.value;
                    break;
                case "select" in conditionAnswer:
                    let localSelectAnswer = conditionAnswer as SelectAnswer;
                    // return localSelectAnswer.select.data === singleCondition.value;
                    switch (singleCondition.op) {
                        case "=":
                            console.log("localSelectAnswer.select.data === singleCondition.value", localSelectAnswer.select.data === singleCondition.value)
                            return localSelectAnswer.select.data === singleCondition.value;
                        case "≠":
                            console.log("localSelectAnswer.select.data !== singleCondition.value", localSelectAnswer.select.data !== singleCondition.value)
                            return localSelectAnswer.select.data !== singleCondition.value;
                    }
                    break;
                default:
                    return true;
                    // throw new Error("Invalid answer type");
                    break;
            }
        })
        return multipleConditionsResult.length > 0;

        // let multipleConditions = value as ICondition[];
        // return multipleConditions.every((condition) => {
        //     return showInReport(condition, objectAnswers);
        // })
        // return false;
    }
    else {
        // single condition
        let singleCondition = value as ICondition;
        let conditionAnswer = objectAnswers.values[singleCondition.key];

        if (conditionAnswer === undefined || conditionAnswer === null){
            return true;
        }

        switch (true) {
            case "toggle" in conditionAnswer:
                let localToggleAnswer = conditionAnswer as ToggleAnswer;
                switch (singleCondition.op) {
                    case "=":
                        // console.log("localToggleAnswer.toggle.data === singleCondition.value", localToggleAnswer.toggle.data === singleCondition.value)
                        return localToggleAnswer.toggle.data === singleCondition.value;
                    case "≠":
                        // console.log("localToggleAnswer.toggle.data !== singleCondition.value", localToggleAnswer.toggle.data !== singleCondition.value)
                        return localToggleAnswer.toggle.data !== singleCondition.value;
                }
                // return localToggleAnswer.toggle.data === singleCondition.value;
                break;
            case "select" in conditionAnswer:
                let localSelectAnswer = conditionAnswer as SelectAnswer;
                // return localSelectAnswer.select.data === singleCondition.value;
                switch (singleCondition.op) {
                    case "=":
                        // console.log("localSelectAnswer.select.data === singleCondition.value", localSelectAnswer.select.data === singleCondition.value)
                        return localSelectAnswer.select.data === singleCondition.value;
                    case "≠":
                        // console.log("localSelectAnswer.select.data !== singleCondition.value", localSelectAnswer.select.data !== singleCondition.value)
                        return localSelectAnswer.select.data !== singleCondition.value;
                }
                break;
            default:
                return true;
                // throw new Error("Invalid answer type");
                break;
        }
    }

}


export function RenderCellExceptionCount(props: { params: GridCellParams }) {

    const answerCountMemo = React.useMemo(() => {
        try {
            let survey = props.params.row as ISurvey;
            let answers = survey.answers;
            if (answers === undefined || answers === null || answers.length === 0) {
                return null;
            }
            let answer = answers[0];
            if (answer === undefined || answer === null || answer.answers === undefined || answer.answers === null) {

                return null;
            }

            let count = 0;

            let contentWithClones = createContentWithClone(survey.content, answer, answer.survey_id, true);

            // TODO: get list of objects that either oare non-conditional or have passed the conditional check
            let contentWithConditionalObjects = contentWithClones.filter((object) => {

                if (object.isConditional === undefined || !object.isConditional) {
                    return true;
                }
                let objectAnswers = answer.answers[object.key];
                if (objectAnswers === undefined) {
                    return false; // does objects that are conditional but have no answer show up
                }

                return objectAnswers.conditionalExists === true;
            });

            // let answeredObjects = await asyncMap<SurveyObject, { tableRows: TableRow[], object: SurveyObject }>(contentWithConditionalObjects, GetSurveyObjectToTableRowsFunction(reportType, item))
            // console.log("answeredObjects", answeredObjects);

            // console.log("contentWithConditionalObjects", contentWithConditionalObjects);

            contentWithConditionalObjects.forEach((obj) => {

                if (answer === undefined || answer === null || answer.answers === undefined || answer.answers === null) {
                    return;
                }
                // console.log(obj)
                let objectAnswers = answer.answers[obj.key];
                if (objectAnswers === undefined || objectAnswers === null || objectAnswers.values === undefined || objectAnswers.values === null) {
                    return;
                }
                // console.log(objectAnswers)
                let questions = obj.questions;
                if (questions === undefined || questions === null || questions.length === 0) {
                    return;
                }

                questions.forEach((question) => {
                    let questionAnswers = objectAnswers.values[question.key];
                    if (questionAnswers === undefined) {
                        return;
                    }
                    // console.log("question", question)
                    if (question.showInExceptionCount === undefined || question.showInExceptionCount === null){
                        return;
                    }

                    // console.log(questionAnswers)
                    if (showInReport(question.showInExceptionCount, objectAnswers)) {
                        // console.log("showInReport", question.showInExceptionCount)
                        count++;
                    }
                    // if (questionAnswers.exception !== undefined && questionAnswers.exception !== null && questionAnswers.exception !== "") {
                    //     count++;
                    // }
                })
            });

            return count;
        }
        catch (e) {
            console.log(e);
            return null;
        }

    }, [props.params.row ]);

    return (<>
        <Typography>
            {answerCountMemo !== null ? answerCountMemo : ""}
            {/*{answerCountMemo > 0 ? answerCountMemo : ""}*/}
            {/*({answerCountMemo})*/}
        </Typography>
    </>);
}

export async function asyncMap<T, R>(arr: T[], asyncFn: (item: T) => Promise<R>): Promise<R[]> {
    const result: R[] = [];
    for (const item of arr) {
        const transformedValue = await asyncFn(item);
        result.push(transformedValue);
    }
    return result;
}
