import {ISurvey, SurveyObject, SurveyObjectQuestion} from "../../../types/interfaces";
import {GridCellParams} from "@mui/x-data-grid";
import React from "react";
import {
    Box,
    Button,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Modal,
    Select,
    Stack,
    Tooltip,
    Typography
} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faObjectGroup} from "@fortawesome/free-regular-svg-icons";

export function RenderCellMoveQuestionToAnotherObject(props: { item: SurveyObject, setItem: (item: SurveyObject) => void, survey: ISurvey, setSurvey: (survey: ISurvey) => void, userToken: string, params: GridCellParams}) {
    // return function () {

    const row = props.params.row as SurveyObjectQuestion;
    const [open, setOpen] = React.useState(false);

    const filteredObjectMemo = React.useMemo(
        () => {
            return props.survey.content.filter((content: SurveyObject) => content.key !== props.item.key);
        },
        [props.survey.content, props.item]
    );

    const [selectedObject, setSelectedObject] = React.useState('');

    const handleClose = () => {
        setSelectedObject('');
        setOpen(false);
    };

    const handleMoveButtonClick = () => {
        // Add your logic here for moving to another object
        console.log(`Moving to ${selectedObject}`);


        // get list of questions from source object, and remove the question from the list.
        let newParentItemQuestions = [...props.item.questions];
        let questionIndex = newParentItemQuestions.findIndex((question) => question.key === row.key);
        let question = newParentItemQuestions[questionIndex];
        newParentItemQuestions.splice(questionIndex, 1);

        // get the destination object
        let destinationObject = props.survey.content.filter((content: SurveyObject) => content.key === selectedObject)[0];
        let newDestinationObjectQuestions = [...destinationObject.questions];
        newDestinationObjectQuestions.push(question);

        // update the source object
        let newParentItem: SurveyObject = {...props.item, questions: newParentItemQuestions};
        // replace new parent item in the survey

        // update the destination object
        let newDestinationObject: SurveyObject = {...destinationObject, questions: newDestinationObjectQuestions};


        let newSurvey = {...props.survey};
        newSurvey.content = newSurvey.content.map((content) => {
            if (content.key === newDestinationObject.key) {
                return newDestinationObject;
            }

            if (content.key === newParentItem.key) {
                return newParentItem;
            }
            return content;
        })
        props.setSurvey(newSurvey);

        // close the modal





        handleClose();
    };

    return (<>
        {open && (
            <Modal open={open} onClose={handleClose}>
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', boxShadow: 24, p: 4, minWidth: 300 }}>
                    {/*<IconButton sx={{ position: 'absolute', top: 0, right: 0 }} onClick={handleClose}>*/}
                    {/*                        <CloseIcon />
                    </IconButton>*/}

                    <Typography id="modal-modal-title" variant="h5" component="h2" sx={{ mb: 2, textAlign: 'center' }}>
                        Move Question to Another Object
                    </Typography>

                    <Stack direction={"column"} spacing={2}>
                        <FormControl fullWidth>
                            <InputLabel>Select an Object</InputLabel>
                            <Select
                                value={selectedObject}
                                onChange={(event: any) => {
                                    setSelectedObject(event.target.value as string);
                                }}
                                label="Select an Object"
                            >
                                {filteredObjectMemo.map((object: SurveyObject) => {
                                    return (<MenuItem value={object.key}>{object.key} - {object.location}</MenuItem>)
                                })}
                                {/*<MenuItem value="Object 1">Object 1</MenuItem>*/}
                            </Select>
                        </FormControl>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={handleMoveButtonClick}
                            disabled={!selectedObject}
                        >
                            Move
                        </Button>
                    </Stack>



                </Box>
            </Modal>
        )}
        <Tooltip title="Move Question To Another Object" arrow><IconButton color={"warning"} onClick={() => {
            // alert("move question to another object");
            setOpen(true);
        }}>
            <FontAwesomeIcon icon={faObjectGroup} />
        </IconButton></Tooltip>

    </>)
    // }
}
