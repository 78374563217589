import {ICondition, SurveyObject} from "../../../types/interfaces";
import React, {Dispatch} from "react";
import {Stack, Switch, TextField} from "@mui/material";

export function MultipleConditionStack(props: { quickConditions: ICondition[], item: any, setItem: Dispatch<any>, conditionKey: string, surveyObject: SurveyObject }) {

    const [filter, setFilter] = React.useState<string>("");
    const filteredQuickConditions = React.useMemo(() => {
        return props.quickConditions.filter((condition) => {
            let selectedQuestions = props.surveyObject.questions.filter((q) => q.key === condition.key)[0];
            return selectedQuestions !== undefined && selectedQuestions.questionText.toLowerCase().includes(filter.toLowerCase());
        });
    }, [props.quickConditions, filter, props.surveyObject.questions]);

    return (<>

        <Stack direction={"column"} spacing={1}>

            <TextField
                fullWidth
                label="Filter"
                value={filter}
                onChange={(e) => {
                    setFilter(e.target.value);
                }}
            />

            {filteredQuickConditions.map((condition) => {

                return (<div style={{ padding: '0.5rem'}}>
                    <Switch
                        // is included in the item.showInExceptionReport as ICondition[]
                        checked={
                            Array.isArray(props.item[props.conditionKey])
                                ?
                                ((props.item[props.conditionKey] as ICondition[])
                                    .filter((c: ICondition) => {
                                        return c.key === condition.key && c.op === condition.op && c.value === condition.value;
                                    }).length > 0 )

                                : false

                        }
                        onChange={(e) => {
                            // if it is checked, add it to the list
                            let newItem = { ...props.item };
                            if (!Array.isArray(props.item[props.conditionKey])) {
                                newItem[props.conditionKey]= [] as ICondition[];
                            }
                            if (e.target.checked) {
                                newItem[props.conditionKey].push(condition);
                            }
                            else {
                                newItem[props.conditionKey] = newItem[props.conditionKey].filter((c: ICondition) => c.key !== condition.key || c.op !== condition.op || c.value !== condition.value);
                            }
                            props.setItem(newItem);
                            // // if it is unchecked, remove it from the list
                        }}
                    />
                    <label>{
                        props.surveyObject.questions.filter((q) => q.key === condition.key)[0].questionText
                    } ({condition.key}) {condition.op} {condition.value}</label>

                </div>)
            })}
        </Stack>

    </>)
}
