import {ISurvey, SurveyObject, SurveyObjectQuestion} from "../../../types/interfaces";
import React from "react";
import {DataGrid, GridCellParams, GridRowSelectionModel, GridValidRowModel} from "@mui/x-data-grid";
import {Box, Button, Modal, Paper, Stack, Typography} from "@mui/material";
import DebugView from "../../../Library/DebugView";

export const QuickBulkAddConditionFlagsModal = (props : { item: SurveyObject, setItem: (item: SurveyObject) => void, showModal: boolean, setShowModal: React.Dispatch<React.SetStateAction<boolean>>, survey: ISurvey }) => {
    const [rowSelectionModel, setRowSelectionModel] = React.useState<GridRowSelectionModel>([]);

    const columns = [
        { field: 'questionText', headerName: 'Question', width: 500 },
        { field: 'type', headerName: 'Type', width: 150, valueGetter: (params: GridCellParams) => { return (params.row as SurveyObjectQuestion).type.type } },
        { field: 'key', headerName: 'Key', width: 150 },
    ];

    return (<>
        <Modal open={props.showModal} onClose={() => {
            props.setShowModal(false);
        }}>
            {props.showModal ? (<>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '60%',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        overflowY: 'auto',
                        maxHeight: '90vh',
                    }}
                >
                    <Typography id="modal-modal-title" variant="h5" component="h2" sx={{ mb: 2, textAlign: 'center' }}>
                        Quick Bulk Add Condition Flags
                    </Typography>

                    <DataGrid
                        rows={props.item.questions}
                        columns={columns}
                        getRowId={(row: GridValidRowModel) => row.key} // was _id
                        pageSizeOptions={[100]}
                        disableRowSelectionOnClick
                        checkboxSelection
                        onRowSelectionModelChange={(newRowSelectionModel) => {
                            setRowSelectionModel(newRowSelectionModel);
                        }}
                        rowSelectionModel={rowSelectionModel}
                    />

                    <br />
                    <hr />
                    <br />

                    <Paper variant={"outlined"} sx={{padding: 2}}>
                        <DebugView value={props.survey.conditionFlags}    />
                        <Stack direction={"row"} spacing={1}>





                            {props.survey.conditionFlags !== undefined && Object.keys(props.survey.conditionFlags).map((condition) => {

                                return (<div style={{ padding: '0.5rem'}}>

                                    <Button variant={"contained"} onClick={() => {
                                        // for each of the selected questions, add the condition to the list.
                                        let newParentItem = {...props.item};
                                        newParentItem.questions = newParentItem.questions.map((question) => {
                                            let updatedQuestion = {...question};
                                            if (rowSelectionModel.includes(updatedQuestion.key)) {
                                                updatedQuestion.requiredConditionFlags = updatedQuestion.requiredConditionFlags ?? [];
                                                updatedQuestion.requiredConditionFlags.push(condition);
                                            }
                                            return updatedQuestion;
                                        })
                                        props.setItem(newParentItem);
                                        props.setShowModal(false);
                                        // setRowSelectionModel([]);


                                    }}
                                    // disabled={props.survey.conditionFlags !== undefined && Object.keys(props.survey.conditionFlags).includes(condition)}

                                    >
                                        ADD {condition}
                                    </Button>
                                </div>)
                            })}

                            {props.survey.conditionFlags !== undefined && Object.keys(props.survey.conditionFlags).map((condition) => {

                                return (<div style={{ padding: '0.5rem'}}>

                                    <Button variant={"contained"} onClick={() => {
                                        // for each of the selected questions, add the condition to the list.
                                        let newParentItem = {...props.item};
                                        newParentItem.questions = newParentItem.questions.map((question) => {
                                            let updatedQuestion = {...question};
                                            if (rowSelectionModel.includes(updatedQuestion.key)) {
                                                updatedQuestion.requiredConditionFlags = updatedQuestion.requiredConditionFlags ?? [];
                                                // remove the condition from the list.
                                                updatedQuestion.requiredConditionFlags = updatedQuestion.requiredConditionFlags.filter((flag) => flag !== condition);
                                            }
                                            return updatedQuestion;
                                        })
                                        props.setItem(newParentItem);
                                        props.setShowModal(false);
                                        // setRowSelectionModel([]);


                                    }}
                                            // disabled={!(props.survey.conditionFlags !== undefined && Object.keys(props.survey.conditionFlags).includes(condition))}
                                    >
                                        DEL {condition}
                                    </Button>
                                </div>)
                            })}
                        </Stack>

                    </Paper>

                </Box>
            </>) : (<></>)}
        </Modal>
    </>);
};
