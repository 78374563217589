import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import ReactDOM from "react-dom/client";
import React from "react";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {orange} from "@mui/material/colors";

const theme = createTheme({
    palette: {
        mode: 'light',
        //mode: prefersDarkMode ? 'dark' : 'light',
        primary: {
            main: orange[900],
        },
        secondary: {
            main: "#FFFFFF" //blue[900],
        },
        // white: {
        //     main: "#FFFFFF"
        // }
    },
})

function CustomAlert(props: { title: string, message: string, handleClose: (() => void), timeout?: number }) {

    const [remainingTime, setRemainingTime] = React.useState(props.timeout || 0);
    // React.useEffect(() => {
    //     let timer: NodeJS.Timeout | null = null;
    //     if (props.timeout) {
    //         timer = setTimeout(() => {
    //             props.handleClose();
    //         }, props.timeout * 1000);
    //     }
    //
    //     return () => {
    //         if (timer) {
    //             clearTimeout(timer);
    //         }
    //     };
    // }, [props.timeout, props.handleClose]);

    React.useEffect(() => {
        let timer: NodeJS.Timeout | null = null;
        let countdownInterval: NodeJS.Timeout | null = null;

        if (props.timeout) {
            // Set the remaining time initially
            setRemainingTime(props.timeout);

            // Start a countdown interval
            countdownInterval = setInterval(() => {
                setRemainingTime(time => time - 1);
            }, 1000);

            // Set a timer to call handleClose after timeout
            timer = setTimeout(() => {
                props.handleClose();
                if (countdownInterval) {
                    clearInterval(countdownInterval);
                }
            }, props.timeout * 1000);
        }

        return () => {
            if (timer) {
                clearTimeout(timer);
            }
            if (countdownInterval) {
                clearInterval(countdownInterval);
            }
        };
    }, [props]);

    return (
        <><ThemeProvider theme={theme}>
            <Dialog
                open={true}
                onClose={() => {}}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={{ textAlign: 'center' }}
            >
                <DialogTitle id="alert-dialog-title">
                    {props.title}
                    <hr style={{ color: orange[900] }} />
                </DialogTitle>

                <DialogContent style={{ minWidth: '400px' }}>

                    <DialogContentText id="alert-dialog-description">
                        {props.message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {/*<Button onClick={handleClose}>Disagree</Button>*/}
                    <Button onClick={props.handleClose} autoFocus>
                        Proceed{remainingTime > 0 ? ` (${remainingTime})` : ''}
                    </Button>
                </DialogActions>


            </Dialog>
            </ThemeProvider>
        </>
    );
}

function CustomPrompt(props: { title: string, message: string, handleYes: (() => void), handleNo: (() => void) }) {
    return (
        <>
            <ThemeProvider theme={theme}>
                <Dialog
                    open={true}
                    onClose={() => {}}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    sx={{ textAlign: 'center'}}
                >
                    <DialogTitle id="alert-dialog-title">
                        {props.title}
                        <hr style={{ color: orange[900] }} />
                    </DialogTitle>
                    <DialogContent style={{ minWidth: '400px' }}>
                        <DialogContentText id="alert-dialog-description">
                            {props.message}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        {/*<Button onClick={handleClose}>Disagree</Button>*/}
                        <Button onClick={props.handleNo} autoFocus>
                            Cancel
                        </Button>

                        <Button onClick={props.handleYes} autoFocus>
                            Proceed
                        </Button>
                    </DialogActions>
                </Dialog>
            </ThemeProvider>
        </>
    );
}

// function showAlert({ title, message }: { title: string, message: string }) {
//     const div_root = document.createElement('div');
//     div_root.id    = 'alert_root_' + Math.random().toString(36).substr(2, 9);
//     document.body.appendChild(div_root);
//     const root = ReactDOM.createRoot(div_root);
//     root.render(<CustomAlert title={title} message={message} handleClose={() => {
//         // const div_root = document.getElementById('div_root');
//         root.unmount(); //React 18
//         div_root.remove();
//     }} />);
// }

export function showAlertAsync({ title, message, timeout }: { title: string, message: string, timeout?: number }) {
    return new Promise<void>((resolve, reject) => {
        const div_root = document.createElement('div');
        div_root.id    = 'alert_root_' + Math.random().toString(36).substr(2, 9);
        document.body.appendChild(div_root);
        const root = ReactDOM.createRoot(div_root);
        root.render(<CustomAlert title={title} message={message} timeout={timeout} handleClose={() => {
            root.unmount(); //React 18
            div_root.remove();
            resolve();
        }} />);
    });
}

export function showPromptAsync({ title, message }: { title: string, message: string }) {
    return new Promise<boolean>((resolve, reject) => {
        const div_root = document.createElement('div');
        div_root.id    = 'alert_root_' + Math.random().toString(36).substr(2, 9);
        document.body.appendChild(div_root);
        const root = ReactDOM.createRoot(div_root);
        root.render(<CustomPrompt title={title} message={message} handleYes={() => {
            root.unmount(); //React 18
            div_root.remove();
            resolve(true);
        }} handleNo={() => {
            root.unmount(); //React 18
            div_root.remove();
            resolve(false);
        }} />);
    });
}
