import React from "react";
import {AuthContext} from "../../Library/AuthContext";
import {DataGrid, GridValidRowModel} from "@mui/x-data-grid";
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import AuthenticatedLayout, {AuthenticatedLayoutCustomButton} from "../../Library/AuthenticatedLayout";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileDownload} from "@fortawesome/pro-solid-svg-icons";

interface DashboardData {
    androidUserCount?: number;
    iosUserCount?: number;
    totalUserCount?: number;

    surveyCount?: number;
    completedSurveyCount?: number;
    startedSurveyCount?: number;
    newSurveyCount?: number;

    surveyTodayCount?: number;
    surveyWeekCount?: number;
    surveyMonthCount?: number;

    surveyYesterdayCount?: number;

    surveyLastMonthCount?: number;
    surveyLastWeekCount?: number;

    adminUserCount?: number;
    technicianUserCount?: number;
    managerUserCount?: number;
    clientUserCount?: number;
    disabledUserCount?: number;

    projects?: ProjectStatusCount[];

}

interface ProjectStatusCount {
    projectName: String;
    projectID: String;
    completedCount: number;
    startedCount: number;
    newCount: number;
}

class UserDashboardDataController {

    private readonly baseURL : string

    constructor(private userToken: string) {
        // Nothing to do here yet.
        // const WS_URL = `ws${ process.env.REACT_APP_SERVER_URL ? "s" :"" }://${baseURL}`;
        const domainURL: string = process.env.REACT_APP_SERVER_URL || "127.0.0.1:3000";
        const protocol: string = `http${ process.env.REACT_APP_SERVER_URL ? "s" :"" }`;
        this.baseURL = `${protocol}://${domainURL}`;
    }



    public async loadData(): Promise<DashboardData> {
        let results = await fetch(`${this.baseURL}/api/dashboard`, {
            method: "GET",
            headers: {
                "authorization": `Bearer ${this.userToken}`,
                "Content-Type": "application/json"
            },
            // body: JSON.stringify(item)
        });
        let data = await results.json();
        console.log(data);
        return await data; // this doesn't seem to type check.
    }

    public getURL(): string {
        return `${this.baseURL}/api/dashboard/excel`
    }
}

function UsersDashboardView() {

    const {
        userToken,
    } = React.useContext(AuthContext)!;

    const [dashboardData, setDashboardData] = React.useState<DashboardData | null>(null);
    const UpdateList = React.useCallback(async () => {
        if (userToken === null || userToken === undefined) {
            return;
        }
        let response = await new UserDashboardDataController(userToken).loadData();
        setDashboardData(response);

    }, [userToken]);

    React.useEffect(() => {
        UpdateList().then();
    }, [UpdateList]);

    let buttons: AuthenticatedLayoutCustomButton[] = [
        {
            label: "Download",
            action: () => {
                // setShowDateFilter(!showDateFilter);
                if (userToken === null || userToken === undefined) {
                    return
                }
                let link = document.createElement("a")
                link.download = "Overview.xlsx"
                link.href =  new UserDashboardDataController(userToken).getURL()
                link.click()
            },
            disabled: dashboardData === null,
            icon: <FontAwesomeIcon icon={faFileDownload} />
        },
    ];

    return (
        <>
            {/* move the sidebar stuff into a provider system. */}
            <AuthenticatedLayout pageTitle={"User Dashboard"} customButtons={buttons}>
                {dashboardData !== null && (<>
                <TableContainer component={Paper}>
                    <Table sx={{minWidth: 650}} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Users</TableCell>
                                <TableCell align="right">User Count</TableCell>
                                <TableCell align="right">Android</TableCell>
                                <TableCell align="right">iOS</TableCell>
                                <TableCell align="right">Disabled</TableCell>
                                <TableCell align="right">Admin</TableCell>
                                <TableCell align="right">Manager</TableCell>
                                <TableCell align="right">Technician</TableCell>
                                <TableCell align="right">Client</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                <TableCell component="th" scope="row">
                                    Count
                                </TableCell>
                                <TableCell align="right">{dashboardData.totalUserCount}</TableCell>
                                <TableCell align="right">{dashboardData.androidUserCount}</TableCell>
                                <TableCell align="right">{dashboardData.iosUserCount}</TableCell>
                                <TableCell align="right">{dashboardData.disabledUserCount}</TableCell>
                                <TableCell align="right">{dashboardData.adminUserCount}</TableCell>
                                <TableCell align="right">{dashboardData.managerUserCount}</TableCell>
                                <TableCell align="right">{dashboardData.technicianUserCount}</TableCell>
                                <TableCell align="right">{dashboardData.clientUserCount}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                <br/>
                <hr/>
                <br/>

                <TableContainer component={Paper}>
                    <Table sx={{minWidth: 650}} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Survey Info</TableCell>
                                <TableCell align="right">Total Count</TableCell>
                                <TableCell align="right">Not Started</TableCell>
                                <TableCell align="right">Started</TableCell>
                                <TableCell align="right">Completed</TableCell>
                                <TableCell align="right">Today</TableCell>
                                <TableCell align="right">Yesterday</TableCell>
                                <TableCell align="right">This Month</TableCell>
                                <TableCell align="right">Last Month</TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow
                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                            >
                                <TableCell component="th" scope="row">
                                    Count
                                </TableCell>
                                <TableCell align="right">{dashboardData.surveyCount}</TableCell>
                                <TableCell align="right">{dashboardData.newSurveyCount}</TableCell>
                                <TableCell align="right">{dashboardData.startedSurveyCount}</TableCell>
                                <TableCell align="right">{dashboardData.completedSurveyCount}</TableCell>
                                <TableCell align="right">{dashboardData.surveyTodayCount}</TableCell>
                                <TableCell align="right">{dashboardData.surveyYesterdayCount}</TableCell>
                                <TableCell align="right">{dashboardData.surveyMonthCount}</TableCell>
                                <TableCell align="right">{dashboardData.surveyLastMonthCount}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                <br/>
                <hr/>
                <br/>

                <DataGrid
                    // make sure to set height to 100% so that the grid fills the whole container
                    style={{height: '600px'}}
                    rows={dashboardData.projects || []}
                    columns={[
                        {field: 'project.name', headerName: 'Project Name', width: 200, valueGetter: (params) => params.row.projectName},
                        {field: 'project._id', headerName: 'Project ID', width: 300, valueGetter: (params) => params.row.projectID},
                        {field: 'newCount', headerName: 'New', width: 100},
                        {field: 'startedCount', headerName: 'Started', width: 100},
                        {field: 'completedCount', headerName: 'Completed', width: 100},
                    ]}
                    getRowId={(row: GridValidRowModel) => row.projectID}
                    disableRowSelectionOnClick
                />
        </>
            )}

            </AuthenticatedLayout>
            </>
    );
}

export default UsersDashboardView;
