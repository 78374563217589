import React from "react";
import {AuthContext} from "../../Library/AuthContext";
import {ISurvey, IUser} from "../../types/interfaces";
import {ISurveyWithId, SurveyDataController} from "../Surveys/SurveyDataController";
import {DataGrid, GridColDef, GridRowModel, GridValidRowModel} from "@mui/x-data-grid";
import {DEFAULT_PAGE_SIZE_OPTIONS, UserRole} from "../../types/enums";
import {Button, Stack, Typography} from "@mui/material";
import {UserDataController} from "../Users/UserDataController";
import AuthenticatedLayout, {AuthenticatedLayoutCustomButton} from "../../Library/AuthenticatedLayout";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRotateRight} from "@fortawesome/free-solid-svg-icons";

import {ITimeEntryWithId, TimeEntryController} from "./TimeEntryController";
import {useLocalStorage} from "../../Hooks/useLocalStorage";
import {StoredPaginationModel} from "../Surveys/SurveyManagementView";

function TimeEntriesView() {

    const {
        user,
        userToken
    } = React.useContext(AuthContext)!;

    // const [usersList, setUserList] = React.useState<IUser[]>([])
    // const [coordinatorsList, setCoordinatorList] = React.useState<(IUser & {_id: string})[]>([])

    // const UpdateList = React.useCallback(async () => {
    //     if (userToken === null || userToken === undefined) {
    //         return;
    //     }
    //     let all = await new TimeEntryController(userToken).getAll();
    //     setList(all);
    // },[userToken]);


    const columns = React.useMemo(() => {
        let columns: GridColDef[] = []



        // columns.push({ field: 'assigned_user_id', headerName: 'Assigned User', width: 300, editable: false, valueGetter: params => {
        //         try {
        //             let survey = params.row as ISurvey;
        //             if (survey.assigned_user_id !== undefined){
        //                 let user = survey.assigned_user_id as IUser;
        //                 return user.name;
        //             }
        //             else {
        //                 return "Unassigned"
        //             }
        //         }
        //         catch (e) {
        //             return "Unassigned"
        //         }
        //
        //     } });



        columns.push({ field: 'checkInDate', headerName: 'Check In Date', width: 200, editable: false });
        columns.push({ field: 'checkInIsManual', headerName: 'Check In Manual', width: 200, editable: false });


        columns.push({ field: 'checkOutDate', headerName: 'Check Out Date', width: 200, editable: false });
        columns.push({ field: 'checkOutIsManual', headerName: 'Check Out Manual', width: 200, editable: false });



        if (user?.role === UserRole.ADMIN) {

            columns.push({ field: '_id', headerName: 'ID', width: 310, editable: false,
                renderCell: (params) => (
                    <>
                        <Stack direction={"row"} sx={{ width: '100%' }}>

                            <Button variant="outlined" color="primary" size={"small"} fullWidth={false} onClick={async () => {
                                let item = params.row as ISurvey & { _id: string };
                                try {
                                    await navigator.clipboard.writeText(item._id);
                                    console.log('Text copied to clipboard');
                                } catch (err) {
                                    console.error('Failed to copy: ', err);
                                }
                            }}>Copy</Button>

                            <Typography sx={{ padding: "3px" }}>&nbsp;{params.value}</Typography>
                        </Stack>
                    </>
                ),
            });
        }
        else if (user?.role !== UserRole.CLIENT) {
            columns.push({ field: '_id', headerName: 'ID', width: 400, editable: false });
        }


        columns.push({ field: 'id', headerName: 'ID', width: 400, editable: false });



        return columns;
    }, [user, userToken])

    const [selectionModel, setSelectionModel] = React.useState([]);
    const handleSelection = (newSelectionModel: any) => {
        setSelectionModel(newSelectionModel);
        // Handle the selection
    };


    const [paginationModel, setPaginationModel] = useLocalStorage("TimeEntriesView.paginationModel", {
        pageSize: 25,
        page: 0,
    }) as [StoredPaginationModel, React.Dispatch<React.SetStateAction<StoredPaginationModel>>];
    const [totalRows, setTotalRows] = React.useState<number>(0);

    const [archiveActive, setArchiveActive] = React.useState<boolean>(false);

    const loadData = React.useCallback(async () => {
        if (userToken === null || userToken === undefined) {
            return;
        }
        try {
            // let all = await new SurveyDataController(userToken).getAll();
            // setList(all.filter((survey) => {
            //     return survey.isTemplate === false;
            // }));
            let response = await new TimeEntryController(userToken).getAll(paginationModel);
            setList(response.items);
            setTotalRows(response.count)
            // let usersList = await new UserDataController(userToken).getAll();
            // if (usersList === null || usersList === undefined) {
            //     alert("Error loading");
            //     return;
            // }
            // setUserList(usersList.filter((u) => u.role !== UserRole.CLIENT).sort((a, b) => a.name.localeCompare(b.name)));
            // setCoordinatorList(list.filter((u) => u.role === UserRole.MANAGER || u.role === UserRole.ADMIN));
        } catch (e) {
            console.warn(`exception: ${(e as any).message}`)
        }
    }, [userToken,  paginationModel.page, paginationModel.pageSize ]);

    React.useEffect(() => {
        loadData().then();
    }, [userToken,  paginationModel.page, paginationModel.pageSize ]);


    const [list, setList] = React.useState<ITimeEntryWithId[]>([]);

    const processRowUpdate = React.useCallback(
        async (newRow: GridRowModel, oldRow: GridRowModel) => {
            if (userToken === null || userToken === undefined) {
                return;
            }
            return await new SurveyDataController(userToken).update(newRow._id, newRow as ISurveyWithId);
        },
        [userToken],
    );

    const handleProcessRowUpdateError = React.useCallback((error: Error) => {
        console.log('handleProcessRowUpdateError', error)
        alert(error.message);
        // setSnackbar({ children: error.message, severity: 'error' });
    }, []);

    // const [searchText, setSearchText] = useLocalStorage("SurveyManagementView.searchText", "") as [string, React.Dispatch<React.SetStateAction<string>>];




    const customButtonsMemo = React.useMemo(() => {
        let buttons: AuthenticatedLayoutCustomButton[] = [

        ];


        if (user?.role !== UserRole.CLIENT) {




            // Add button


            buttons.push({
                label: "Reload",
                action: () => {
                    loadData().then();
                },
                icon: <FontAwesomeIcon icon={faRotateRight} />
            })
        }

        return buttons;
    }, [archiveActive, selectionModel, userToken, list]);


    return (
        <div>
            {/* move the sidebar stuff into a provider system. */}
            <AuthenticatedLayout  pageTitle={"Time Entries"}
                                  // searchText={{text: searchText, setText: setSearchText, label: user?.role === UserRole.CLIENT ? "Search (Case Number)": "Search (Case Number, Assigned User, Project Name)"}}
                                  customButtons={customButtonsMemo}
            >

                <DataGrid
                    // make sure to set height to 100% so that the grid fills the whole container
                    style={{ height: '100%' }}
                    rows={list}
                    columns={columns}
                    getRowId={(row: GridValidRowModel) => row._id}
                    pageSizeOptions={DEFAULT_PAGE_SIZE_OPTIONS}
                    pagination={true}
                    rowCount={totalRows}
                    paginationMode={"server"}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}

                    disableRowSelectionOnClick
                    processRowUpdate={processRowUpdate}
                    onProcessRowUpdateError={handleProcessRowUpdateError}
                    rowSelectionModel={selectionModel}
                    onRowSelectionModelChange={handleSelection}
                    checkboxSelection={user?.role === UserRole.ADMIN}
                />




            </AuthenticatedLayout>
        </div>
    );
}

export default TimeEntriesView;
