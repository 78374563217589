import {CustomFormField, ICompany, IProject, IUser} from "../../types/interfaces";
import React from "react";
import {AuthContext} from "../../Library/AuthContext";
import {CompanyDataController} from "../Projects/CompanyDataController";
import {CustomFormFieldSizes, CustomFormFieldTypes} from "../../types/enums";
import {Box, Button, Modal, Paper, Stack, Tooltip, Typography} from "@mui/material";
import {EditObject} from "../../Shared/Components/EditObject";
import {UserDataController} from "./UserDataController";
import {DataGrid, GridCellParams, GridValidRowModel} from "@mui/x-data-grid";

export function UserProjectsModal(props: { user: IUser, onUpdateRequired: () => Promise<void> })  {

    const {
        // user,
        userToken
    } = React.useContext(AuthContext)!;

    const [show, setShow] = React.useState<boolean>(false);

    const [selectedOptions, setSelectedOptions] = React.useState<{
        company: string | null,
        project: string | null,
    }>({
        company: null,
        project: null,
    });


    const loadData = React.useCallback(async () => {
        if (userToken === null || userToken === undefined) {
            return;
        }
        let all = await new CompanyDataController(userToken).getAll();
        setCompanies(all);
    }, [userToken]); // Dependencies array


    React.useEffect(() => {

        loadData().then();
    }, [userToken, loadData]);

    const [companies, setCompanies] = React.useState<ICompany[]>([]);
    const projectsMemo = React.useMemo(() => {
        if (companies === undefined || companies === null || companies.length === 0) {
            return [];
        }
        if (selectedOptions.company === null) {
            return [];
        }
        return companies.find((company) => {
            return (company as ICompany & {_id: string})._id === selectedOptions.company;
        })?.projects as IProject[] ?? [];
    }, [companies, selectedOptions ]);

    const formMemo = React.useMemo(() => {



        const form: CustomFormField[] = [
            {
                kind: CustomFormFieldTypes.SELECT,
                size: CustomFormFieldSizes.ONE,
                key: "company",
                label: "Client",
                options: [
                    { label: "Un-assigned", value: null },
                    ...companies.map((opt: (ICompany)) => { return { label: opt.name, value: (opt as ICompany & {_id: string})._id }; })
                ]
            },
            {
                kind: CustomFormFieldTypes.SELECT,
                size: CustomFormFieldSizes.ONE,
                key: "project",
                label: "Project",
                options: [
                    { label: "Un-assigned", value: null },
                    ...projectsMemo.map((opt: (IProject)) => { return { label: opt.name, value: (opt as IProject & {_id: string})._id }; })
                ],
                readonly: selectedOptions.company === null
            },
        ];
        return form;

    }, [companies, projectsMemo, selectedOptions]);

    return (
        <>
            <Modal
                open={show}
                onClose={() => {
                    setShow(false);
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute' as 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 800,
                    height: 800,
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 4
                }}>
                    <Stack style={{height: '100%'}} spacing={2}>


                        <Typography id="modal-modal-title" variant="h5" component="h2" sx={{mb: 2, textAlign: 'center'}}>
                            {props.user.name}'s Projects
                        </Typography>


                        <Paper elevation={2} sx={{ padding: '1rem'}}>
                            <Stack direction={"column"} spacing={2}>
                                <div style={{ marginRight: '1rem', marginLeft: '0.5rem' }}>
                                    <EditObject item={selectedOptions} setItem={setSelectedOptions} form={formMemo} columns={1}></EditObject>
                                </div>

                                <Button type={"submit"} variant={"contained"} onClick={async () => {
                                    if (userToken === null || userToken === undefined) {
                                        return;
                                    }
                                    let controller = new UserDataController(userToken);
                                    let user = {...props.user, projects: [...props.user.projects, selectedOptions.project]} as IUser;
                                    let result = await controller.update(props.user._id, user);
                                    if (result === undefined) {
                                        return;
                                    }
                                    await props.onUpdateRequired()
                                }} disabled={ selectedOptions.project === null || !projectsMemo.map(s =>  (s as (IProject & {_id: string}))._id).includes(selectedOptions.project) }>ASSIGN</Button>
                            </Stack>
                        </Paper>

                        <hr />

                        <DataGrid

                            // make sure to set height to 100% so that the grid fills the whole container
                            style={{height: '100%'}}
                            rows={props.user.projects ?? []}
                            columns={[
                                { field: 'companyName', headerName: 'Client Name', width: 300, editable: false, valueGetter: (params: GridCellParams) => {
                                        let project = params.row as IProject;
                                        let company = project.company as ICompany;
                                        return company.name
                                    }},
                                { field: 'name', headerName: 'Project Name', width: 300, editable: false },
                                { field: 'unassign', headerName: ' ', width: 100, editable: false,
                                    renderCell: (params) => (
                                        <>
                                            <Button variant="outlined" color="primary" fullWidth={true} onClick={async () => {
                                                if (userToken === null || userToken === undefined) {
                                                    return;
                                                }
                                                let controller = new UserDataController(userToken);
                                                let user = {...props.user, projects: (props.user.projects as (IProject & {_id: string})[])?.filter((project) => {
                                                        let a = (project as (IProject & {_id: string}))._id;
                                                        let b = (params.row as (IProject & {_id: string}))._id;
                                                        return a !== b;
                                                    })} as IUser;
                                                let result = await controller.update(props.user._id, user);
                                                if (result === undefined) {
                                                    return;
                                                }
                                                await props.onUpdateRequired();
                                            }}>Remove</Button>
                                        </>
                                    ),
                                }
                            ]}
                            getRowId={(row: GridValidRowModel) => row._id}
                            pageSizeOptions={[100]}
                            disableRowSelectionOnClick/>
                    </Stack>

                </Box>
            </Modal><Tooltip title={"Projects"} arrow={true}>
            <Button variant="outlined" color="error" fullWidth={true} onClick={() => {
                setShow(true);
            }}>
                Projects
                {/*<FontAwesomeIcon icon={faCopy} />*/}
            </Button>
        </Tooltip>
        </>
    );
}
