
// If this function returns true it will be included in the failed conditions list, which will cause AllConditionPassOnQuestion to return false
// True: means that the question is excluded from being shown or required.
import {ObjectAnswer, SelectAnswer, SurveyObjectQuestion, TextAnswer, ToggleAnswer} from "../types/interfaces";

export function ConditionFailedOnQuestion(answers: ObjectAnswer, condition: {
    key: string;
    op: "=" | "≠",
    value: any
}): boolean {

    if (answers === undefined || answers.values === undefined || answers.values === null || answers.values[condition.key] === undefined || answers.values[condition.key] === null) {
        return true;
    }

    if(condition.key === undefined) {
        return false;
    }
    const answer = answers.values[condition.key];

    // console.log(condition, answer)

    if (answer) {

        switch (true) {
            case "toggle" in answer:
                let localToggleAnswer = answer as ToggleAnswer;
                switch (condition.op) {
                    case "=":
                        return localToggleAnswer.toggle.data !== condition.value;
                    case "≠":
                        return localToggleAnswer.toggle.data === condition.value;
                }
                break;
            case "text" in answer:
                let localTextAnswer = answer as TextAnswer;
                console.log(localTextAnswer.text.data, condition.value)
                switch (condition.op) {
                    case "=":
                        return localTextAnswer.text.data !== condition.value;
                    case "≠":
                        return localTextAnswer.text.data === condition.value;
                }
                break;

            case "select" in answer:
                let localSelectAnswer = answer as SelectAnswer;
                switch (condition.op) {
                    case "=":
                        return localSelectAnswer.select.data !== condition.value;
                    case "≠":
                        return localSelectAnswer.select.data === condition.value;
                }
                break;
            case "photo" in answer:
                return false;
            case "location" in answer:
                return false;
            default:
                throw new Error("Invalid answer type");
        }
    }

    return true;
}

export function _CountOfFailedConditionsOnQuestion(question: SurveyObjectQuestion, answers: ObjectAnswer ): number {
    const failedConditions = question.conditions.filter((condition) => {
        return ConditionFailedOnQuestion(answers, condition);
    });
    return failedConditions.length;
}

export function AllConditionPassOnQuestion(question: SurveyObjectQuestion, answers: ObjectAnswer): boolean {
    return _CountOfFailedConditionsOnQuestion(question, answers) === 0;
}
