import React from 'react';
import {DataGrid, GridCellParams, GridColDef, GridRowParams, GridValidRowModel} from '@mui/x-data-grid';
import AuthenticatedLayout from "../../Library/AuthenticatedLayout";
import {IProject, ISurveyAnswer, IUser} from "../../types/interfaces";
import {ReportDataController} from "./ReportDataController";
import {AuthContext} from "../../Library/AuthContext";
import {Button, IconButton} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRotateRight, faTrash} from "@fortawesome/free-solid-svg-icons";
import {ReportType} from "../../types/enums";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {Download as DownloadIcon} from "@mui/icons-material";
import {Link as RouterLink} from "react-router-dom";

// https://mui.com/material-ui/icons/#font-awesome


export function RenderCellDeleteButton({userToken, params, rowData, setList}: { userToken: string | undefined | null, rowData: ISurveyAnswer, setList: any, params: GridCellParams}) {
    const [isDeleting, setIsDeleting] = React.useState<boolean>(false);
    return (<>
        <Button variant="outlined" color="error" fullWidth={true} disabled={isDeleting} onClick={() => {
            // alert(params.row._id);
            async function removeRow() {
                if (userToken === null || userToken === undefined) {
                    return;
                }

                // rowData = row as ISurveyAnswer;
                //console.log(rowData)
                rowData.reports = rowData.reports.filter((obj) => obj.key !== params.row.key);
                //console.log(rowData)

                await new ReportDataController(userToken).deleteReport(params.row.bucket, params.row.key);

                await new ReportDataController(userToken).update(rowData._id, rowData);
                let all = await new ReportDataController(userToken).getAll();
                setList(all);
            }

            if (window.confirm("Are you sure you want to delete this survey?")){
                setIsDeleting(true)
                removeRow().then(() => setIsDeleting(false));
            }
        }}>
            <FontAwesomeIcon icon={faTrash} />
        </Button>
    </>)
}

// function RenderCell_DeleteButton(userToken: string | undefined | null, rowData: ISurveyAnswer, setList: any) {
//     return function (params: GridCellParams) {
//         const [isDeleting, setIsDeleting] = React.useState<boolean>(false);
//         return (<>
//             <Button variant="outlined" color="error" fullWidth={true} disabled={isDeleting} onClick={() => {
//                 // alert(params.row._id);
//                 async function removeRow() {
//                     if (userToken === null || userToken === undefined) {
//                         return;
//                     }
//
//                     // rowData = row as ISurveyAnswer;
//                     console.log(rowData)
//                     rowData.reports = rowData.reports.filter((obj) => obj.key !== params.row.key);
//                     console.log(rowData)
//
//                     await new ReportDataController(userToken).deleteReport(params.row.bucket, params.row.key);
//
//                     await new ReportDataController(userToken).update(rowData._id, rowData);
//                     let all = await new ReportDataController(userToken).getAll();
//                     setList(all);
//                 }
//
//                 if (window.confirm("Are you sure you want to delete this survey?")){
//                     setIsDeleting(true)
//                     removeRow().then(() => setIsDeleting(false));
//                 }
//             }}>
//                 <FontAwesomeIcon icon={faTrash} />
//             </Button>
//         </>)
//     }
// }




function GenerateButton(props: {
    survey_id: string,
    reportType: ReportType,
    reportTypeLabel: string,
    setList: any // ((ISurveyAnswer[]) => void),
}) {
    const {
        userToken,
    } = React.useContext(AuthContext)!;

    const survey_id = props.survey_id;
    const reportType = props.reportType;
    const reportTypeLabel = props.reportTypeLabel;

    const [isActive, setIsActive] = React.useState<boolean>(false);

    return (<>

        <Button variant="outlined" color="error" fullWidth={true} disabled={isActive} onClick={() => {
            async function generate() {

                if (userToken === null || userToken === undefined) {
                    return;
                }
                setIsActive(true);
                await new ReportDataController(userToken).generate(survey_id, reportType);
                let all = await new ReportDataController(userToken).getAll();
                props.setList(all);
                setIsActive(false);
            }

            generate().then();
        }}>
            Generate {reportTypeLabel}
        </Button>

    </>);

}

function ReportManagementView() {

    const {
        // user,
        userToken
    } = React.useContext(AuthContext)!;

    const columns: GridColDef[] = [
        {
            field: 'open_btn',
            headerName: '',
            sortable: false,
            width: 100,
            renderCell: (params: GridCellParams) => (
                <Button variant="outlined" color="primary" fullWidth={true} component={RouterLink} to={`/dashboard/surveyAnswer/${params.id}`}>
                    Open
                </Button>
            )
        },
        // { field: 'display_name', headerName: 'Display Name', width: 150, editable: false },
        // { field: '_id', headerName: 'ID', width: 250, editable: false },
        { field: 'caseNumber', headerName: 'Case Number', width: 300, editable: false, valueGetter: (params) => {
            let surveyAnswer: ISurveyAnswer = params.row as ISurveyAnswer;
            return surveyAnswer.survey_id.caseNumber;
        }},
        { field: 'status', headerName: 'Status', width: 300, editable: false,
            valueGetter: (params) => {
                let surveyAnswer: ISurveyAnswer = params.row as ISurveyAnswer;
                return surveyAnswer.isCompleted ? "Completed" : "Incomplete";
            }
        },

        { field: 'project', headerName: 'Project', width: 300, editable: false,
            valueGetter: (params) => {
                let surveyAnswer: ISurveyAnswer = params.row as ISurveyAnswer;
                if (surveyAnswer.survey_id.project !== undefined && surveyAnswer.survey_id.project !== null) {
                    return (surveyAnswer.survey_id.project as IProject).name;
                }
                return "";
            }
        },

        { field: 'assigned_user_id', headerName: 'Assigned User', width: 300, editable: false,
            valueGetter: (params) => {
                let surveyAnswer: ISurveyAnswer = params.row as ISurveyAnswer;
                if (surveyAnswer.survey_id.assigned_user_id !== undefined && surveyAnswer.survey_id.assigned_user_id !== null) {
                    return (surveyAnswer.survey_id.assigned_user_id as IUser).name;
                }
                return "Unassigned";
            }
        },


        // {
        //     field: 'debug',
        //     headerName: '',
        //     sortable: false,
        //     width: 500,
        //     renderCell: (params: GridCellParams) => (
        //         <pre>
        //             {JSON.stringify(params.row)}
        //         </pre>
        //     )
        // },



        {
            field: 'generate_btn_standard',
            headerName: '',
            sortable: false,
            width: 200,
            renderCell: (params: GridCellParams) => (
                <GenerateButton survey_id={params.row.survey_id._id} reportType={ReportType.Standard} reportTypeLabel={"Standard"} setList={setList} />
            ),
        },

        {
            field: 'generate_btn_exception',
            headerName: '',
            sortable: false,
            width: 200,
            renderCell: (params: GridCellParams) => (
                <GenerateButton survey_id={params.row.survey_id._id} reportType={ReportType.Exception} reportTypeLabel={"Exception"} setList={setList} />
            ),
        },


        {
            field: 'generate_btn_zip',
            headerName: '',
            sortable: false,
            width: 200,
            renderCell: (params: GridCellParams) => (
                <GenerateButton survey_id={params.row.survey_id._id} reportType={ReportType.FullResolutionImages} reportTypeLabel={"Photo Zip"} setList={setList} />
            ),
        },

        {
            field: 'generate_btn_spreadsheet',
            headerName: '',
            sortable: false,
            width: 200,
            renderCell: (params: GridCellParams) => (
                <GenerateButton survey_id={params.row.survey_id._id} reportType={ReportType.SpreadSheet} reportTypeLabel={"Spread Sheet"} setList={setList} />
            ),
        },

        {
            field: 'generate_btn_creator',
            headerName: '',
            sortable: false,
            width: 200,
            renderCell: (params: GridCellParams) => (
                <GenerateButton survey_id={params.row.survey_id._id} reportType={ReportType.CreatorReport} reportTypeLabel={"CreatorReport"} setList={setList} />
            ),
        },

    ]



    const loadData = React.useCallback(async () => {
        if (userToken === null || userToken === undefined) {
            return;
        }
        let all = await new ReportDataController(userToken).getAll();
        setList(all);
    }, [userToken]); // Dependencies array


    React.useEffect(() => {

        loadData().then();
    }, [userToken, loadData]);

    const [list, setList] = React.useState<ISurveyAnswer[]>([]);

    const [searchText, setSearchText] = React.useState<string>("");


    const filteredList = React.useMemo(() => {
        return list.filter((survey) => {

            let foundCaseNumber = survey.survey_id.caseNumber.toLowerCase().includes(searchText.toLowerCase())
            let foundProjectName = survey.survey_id.project !== undefined && survey.survey_id.project !== null && survey.survey_id.project.name.toLowerCase().includes(searchText.toLowerCase())


            // if ( survey.survey_id.caseNumber.toLowerCase().includes(searchText.toLowerCase())) {
            //     return true;
            // }

            if (foundCaseNumber || foundProjectName) {
                return true;
            }

            // if (survey.survey_id.assigned_user_id !== undefined && survey.survey_id.assigned_user_id !== null) {
            //     if ((survey.survey_id.assigned_user_id as IUser).name.toLowerCase().includes(searchText.toLowerCase())) {
            //         return true;
            //     }
            // }

            return false;
        });
    }, [list, searchText]);

    const getDetailPanelContent = React.useCallback(
        ({ row }: GridRowParams) => {
            let rowData = row as ISurveyAnswer & { _id: string };

            return (<>
                <div style={{padding: '1rem', height: '100%'}}>
                    <DataGrid
                        // make sure to set height to 100% so that the grid fills the whole container
                        style={{height: '100%'}}
                        rows={rowData.reports}
                        columns={[
                            {field: 'date', headerName: 'Date', width: 200,
                                valueFormatter: (params) => {
                                    let date = new Date(params.value as string);
                                    return date.toLocaleString();
                                }

                            },
                            {field: 'type', headerName: 'Type', width: 200},
                            {field: 'bucket', headerName: 'Bucket', width: 500},
                            {field: 'key', headerName: 'Key', width: 500,
                                valueGetter: (params) => {
                                    let item = params.row as {key: string, bucket: string, type: string };
                                    return item.key.split("/").pop();
                                }
                            },


                            { field: 'download', headerName: '', width: 75, renderCell: (params: GridCellParams) => {

                                // return (<DownloadLink params={params} userToken={userToken} rowData={rowData} />);

                                return (<IconButton color={"success"} onClick={() => {
                                    let item = params.row as {key: string, bucket: string, type: string };
// alert(JSON.stringify(item));
                                    async function download(){
                                        if (userToken === null || userToken === undefined) {
                                            return;
                                        }
                                        let bucket = encodeURIComponent(item.bucket);
                                        let key = encodeURIComponent(item.key);
                                        let url = await new ReportDataController(userToken).downloadReport(bucket, key);
                                        window.open(url, '_blank');
                                    }


                                    download().then();
                                }}><DownloadIcon /></IconButton>)
                            }},
                            {
                                field: 'delete_btn',
                                headerName: '',
                                sortable: false,
                                width: 100,
                                renderCell: (params: GridCellParams) => (
                                    <RenderCellDeleteButton userToken={userToken} rowData={rowData} setList={setList} params={params} />
                                ),
                            },
                        ]}
                        getRowId={(row: GridValidRowModel) => row.key}
                        pageSizeOptions={[100]}
                        disableRowSelectionOnClick
                        initialState={{
                            sorting: {
                                sortModel: [{ field: 'date', sort: 'desc' }],
                            },
                        }}
                    />
                </div>

            </>)
        },
        [userToken],
    );

    return (
        <div>
            {/* move the sidebar stuff into a provider system. */}
            <AuthenticatedLayout  pageTitle={"Surveys Answers / Reports"}
                                  searchText={{text: searchText, setText: setSearchText, label: "Search (Case Number, Project Name)"}}
                customButtons={[
                    {
                        label: "Reload",
                        action: () => {
                            loadData().then();
                        },
                        icon: <FontAwesomeIcon icon={faRotateRight} />
                    }
                ]}
            >
                <DataGridPro
                    // make sure to set height to 100% so that the grid fills the whole container
                    style={{ height: '100%' }}
                    rows={filteredList}
                    columns={columns}
                    getRowId={(row: GridValidRowModel) => row._id}
                    pageSizeOptions={[100]}
                    disableRowSelectionOnClick
                    getDetailPanelHeight={(row) => {
                        let reportCount = (row.row as ISurveyAnswer).reports.length;
                        if (reportCount === 0) {
                            return 275;
                        }
                        return 150 + ( reportCount * 51);
                    }}
                    getDetailPanelContent={getDetailPanelContent}
                />

            </AuthenticatedLayout>
        </div>
    );
}

export default ReportManagementView;
