import React from 'react';
import AuthenticatedLayout from "../../Library/AuthenticatedLayout";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {GridCellParams, GridValidRowModel} from "@mui/x-data-grid";
import {CompanyDataController} from "../Projects/CompanyDataController";
import {DataController, ICompany, ISentEmail, ISurvey} from "../../types/interfaces";
import {AuthContext} from "../../Library/AuthContext";
import {BankDataController_QuickQuestionGroup} from "../Bank/BankDataController_QuickQuestionGroup";
import {ISurveyWithId, SurveyDataController} from "../Surveys/SurveyDataController";
import {Button, Tooltip} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRotateRight} from "@fortawesome/free-solid-svg-icons";


// interface ResentEmailButtonProps {
//     userToken: string | null | undefined,
//     sentEmail: ISentEmail
// }

function ResendButton(props: { userToken: string | null | undefined, params: GridCellParams, setList: React.Dispatch<React.SetStateAction<ISentEmailsWithTime[]>> }) {

    const [isResending, setIsResending] = React.useState(false);

    return (
        <Tooltip title={"Resend"} arrow={true}>
            <Button variant="outlined" color="error" fullWidth={true} disabled={isResending} onClick={async () => {
                if (props.userToken === null || props.userToken === undefined) {
                    return;
                }
                setIsResending(true);
                let controller = new SentEmailController(props.userToken);
                await controller.update(props.params.id as string, props.params.row as ISurveyWithId);
                let all = await new SentEmailController(props.userToken).getAll();
                props.setList(all);
                setIsResending(false);

            }}>
                Resend
            </Button>
        </Tooltip>
    );
}

function SentEmailsView() {

    const {
        // user,
        userToken
    } = React.useContext(AuthContext)!;


    const columns = [
        // Resend button
        { field: 'to', headerName: 'To', width: 300, editable: false },
        { field: 'bcc', headerName: 'BCC', width: 300, editable: false },
        { field: 'cc', headerName: 'CC', width: 300, editable: false },

        { field: 'subject', headerName: 'Subject', width: 300, editable: false },
        { field: 'attachmentCount', headerName: 'Attachment Count', width: 160, editable: false },
        { field: 'createdAt', headerName: 'Date', width: 200, editable: false },
        { field: 'confirmation', headerName: 'Message ID', width: 300, editable: false },
        {
            field: 'resend',
            headerName: '',
            sortable: false,
            width: 100,
            renderCell: (params: GridCellParams) => (<>
                <ResendButton params={params} setList={setList} userToken={userToken} />
            </>),
        }
    ]

    const [list, setList] = React.useState<ISentEmailsWithTime[]>([]);

    const loadData = React.useCallback(async () => {
        if (userToken === null || userToken === undefined) {
            return;
        }
        let all = await new SentEmailController(userToken).getAll();
        setList(all);
    }, [userToken]); // Dependencies array

    React.useEffect(() => {
        loadData().then();
    }, [userToken]);

    return (
        <div>
            {/* move the sidebar stuff into a provider system. */}
            <AuthenticatedLayout  pageTitle={"Sent Emails"}
                customButtons={[
                    {
                        label: "Reload",
                        action: () => {
                            loadData().then();
                        },
                        icon: <FontAwesomeIcon icon={faRotateRight} />
                    }
                ]}
            >


                <DataGridPro
                    // make sure to set height to 100% so that the grid fills the whole container
                    style={{ height: 'calc( 100%  )' }}
                    // rows={userDataController.data}
                    rows={list}
                    columns={columns}
                    getRowId={(row: GridValidRowModel) => row._id}
                    pageSizeOptions={[100]}
                    disableRowSelectionOnClick
                    // processRowUpdate={processRowUpdate}
                    // onProcessRowUpdateError={handleProcessRowUpdateError}
                    // getRowId={(row) => row.key || row.location}

                    // getDetailPanelHeight={(row) => {
                    //     return 275;
                    // }}
                    // getDetailPanelContent={ getDetailPanelContent }

                />

            </AuthenticatedLayout>
        </div>
    );
}

export default SentEmailsView;


export type ISentEmailsWithTime = ISentEmail & { createdAt: Date };

export class SentEmailController {

    private readonly baseURL : string

    constructor(private userToken: string) {
        // Nothing to do here yet.
        // const WS_URL = `ws${ process.env.REACT_APP_SERVER_URL ? "s" :"" }://${baseURL}`;
        const domainURL: string = process.env.REACT_APP_SERVER_URL || "127.0.0.1:3000";
        const protocol: string = `http${ process.env.REACT_APP_SERVER_URL ? "s" :"" }`;
        this.baseURL = `${protocol}://${domainURL}`;
    }

    public async getAll(): Promise<ISentEmailsWithTime[]> {
        let results = await fetch(`${this.baseURL}/api/sentEmails`, {
            headers: {
                "authorization": `Bearer ${this.userToken}`
            }
        });
        let data = await results.json();
        return await data; // this doesn't seem to type check.
    }

    public async update(id: string, item: ISurveyWithId): Promise<ISurveyWithId> {
        let results = await fetch(`${this.baseURL}/api/sentEmails/${id}`, {
            method: "PUT",
            headers: {
                "authorization": `Bearer ${this.userToken}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(item)
        });

        // handle when results is 304
        if (results.status === 304) {
            console.log("304");
            return item;
        }

        console.log(results)
        let data = await results.json();
        console.log(data);
        return await data; // this doesn't seem to type check. change to .item when api updated
    }

}
