import {
    DataController,
    ISurvey
} from "../../types/interfaces";

export type ISurveyWithId = ISurvey & { _id?: string };

export type IPaginatedSurveys = {
    items: ISurveyWithId[],
    count: number
}


export interface ISurveyFilter {
    page: Number,
    pageSize: Number,
    searchText: String,
    isTemplate?: boolean,
    isArchived?: boolean,

    filterStart_year: number | null;
    filterStart_month: number | null;
    filterStart_day: number | null;
    filterEnd_year: number | null;
    filterEnd_month: number | null;
    filterEnd_day: number | null;

}

//  implements DataController<ISurveyWithId>

export class SurveyDataController implements DataController<ISurveyWithId> {

    private readonly baseURL : string

    constructor(private userToken: string) {
        // Nothing to do here yet.
        // const WS_URL = `ws${ process.env.REACT_APP_SERVER_URL ? "s" :"" }://${baseURL}`;
        const domainURL: string = process.env.REACT_APP_SERVER_URL || "127.0.0.1:3000";
        const protocol: string = `http${ process.env.REACT_APP_SERVER_URL ? "s" :"" }`;
        this.baseURL = `${protocol}://${domainURL}`;
    }

    public async getAll(): Promise<ISurveyWithId[]> {
        let results = await fetch(`${this.baseURL}/api/surveys`, {
            headers: {
                "authorization": `Bearer ${this.userToken}`
            }
        });
        let data = await results.json();
        return await data; // this doesn't seem to type check.
    }


    public async getPaginated(filter: ISurveyFilter): Promise<IPaginatedSurveys> {
        console.log("getPaginated", filter)
        // , startDateFilter: moment.Moment | null, endDateFilter: moment.Moment | null
        // &startDateFilter=${startDateFilter}&endDateFilter=${endDateFilter}
        let url = `${this.baseURL}/api/surveys/paginated/?page=${filter.page}&limit=${filter.pageSize}&filter=${filter.searchText}`;

        if (filter.isTemplate !== undefined) {
            url += `&isTemplate=${filter.isTemplate}`;
        }
        if (filter.isArchived !== undefined) {
            url += `&isArchived=${filter.isArchived}`;
        }

        if (filter.filterStart_year !== null) {
            url += `&filterStart_year=${filter.filterStart_year}`;
        }
        if (filter.filterStart_month !== null) {
            url += `&filterStart_month=${filter.filterStart_month}`;
        }
        if (filter.filterStart_day !== null) {
            url += `&filterStart_day=${filter.filterStart_day}`;
        }
        if (filter.filterEnd_year !== null) {
            url += `&filterEnd_year=${filter.filterEnd_year}`;
        }
        if (filter.filterEnd_month !== null) {
            url += `&filterEnd_month=${filter.filterEnd_month}`;
        }
        if (filter.filterEnd_day !== null) {
            url += `&filterEnd_day=${filter.filterEnd_day}`;
        }



        // if (filter.startDateFilter) {
        //     let jsonDate = filter.startDateFilter.toJSON();
        //     url += `&startDateFilter=${jsonDate}`;
        // }
        //
        // if (filter.endDateFilter) {
        //     let jsonDate = filter.endDateFilter.toJSON();
        //     url += `&endDateFilter=${jsonDate}`;
        // }

        console.log(`url: ${url}`);

        let results = await fetch(url, {
            headers: {
                "authorization": `Bearer ${this.userToken}`
            }
        });

        let data = await results.json();
        return await data; // this doesn't seem to type check.
    }

    public async getOne(id: String): Promise<ISurveyWithId> {
        let results = await fetch(`${this.baseURL}/api/surveys/withoutMutexLock/${id}`, {
            headers: {
                "authorization": `Bearer ${this.userToken}`
            }
        });
        let data = await results.json();
        // console.log(data);
        return await data; // this doesn't seem to type check.
    }

    public async create(item: ISurvey): Promise<ISurveyWithId> {
        let results = await fetch(`${this.baseURL}/api/surveys/`, {
            method: "POST",
            headers: {
                "authorization": `Bearer ${this.userToken}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(item)
        });
        let data = await results.json();
        console.log(data);
        return await data.item; // this doesn't seem to type check.
    }

    public async import(item: ISurveyWithId, sourceSurveyId: string): Promise<ISurveyWithId> {
        let results = await fetch(`${this.baseURL}/api/surveys/create`, {
            method: "POST",
            headers: {
                "authorization": `Bearer ${this.userToken}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({doc: item, sourceSurveyId: sourceSurveyId})
        });
        let data = await results.json();
        console.log(data);
        return await data.item; // this doesn't seem to type check.
    }

    public async update(id: string, item: ISurveyWithId): Promise<ISurveyWithId> {
        let results = await fetch(`${this.baseURL}/api/surveys/${id}`, {
            method: "PUT",
            headers: {
                "authorization": `Bearer ${this.userToken}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(item)
        });

        // handle when results is 304
        if (results.status === 304) {
            console.log("304");
            return item;
        }

        console.log(results)
        let data = await results.json();
        console.log(data);
        return await data; // this doesn't seem to type check. change to .item when api updated
    }

    public async delete(id: string): Promise<ISurveyWithId> {
        let results = await fetch(`${this.baseURL}/api/surveys/${id}`, {
            method: "DELETE",
            headers: {
                "authorization": `Bearer ${this.userToken}`,
                "Content-Type": "application/json"
            }
            // body: JSON.stringify(item)
        });

        console.log(results)
        let data = await results.json();
        console.log(data);
        return await data; // this doesn't seem to type check. change to .item when api updated
    }

    public async downloadExport(id: string): Promise<string> {
        let results = await fetch(`${this.baseURL}/api/surveys/export/${id}`, {
            headers: {
                "authorization": `Bearer ${this.userToken}`
            }
        });
        let data = await results.text();
        console.log(data);

        return data;
    }

}
