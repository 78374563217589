import {IQuickQuestionGroup, SurveyObject} from "../../../types/interfaces";
import React from "react";
import {AuthContext} from "../../../Library/AuthContext";
import {DataGrid, GridCellParams, GridValidRowModel} from "@mui/x-data-grid";
import {Box, IconButton, Modal, Typography} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {BankDataController_QuickQuestionGroup} from "../../Bank/BankDataController_QuickQuestionGroup";
import {UpdateQuestionsToCreateFromBank} from "./UpdateQuestionsToCreateFromBank";

export const BulkImportQuestionModal = (props : { item: SurveyObject, setItem: (item: SurveyObject) => void, showModal: boolean, setShowModal: React.Dispatch<React.SetStateAction<boolean>> }) => {

    const {
        // user,
        userToken
    } = React.useContext(AuthContext)!;

    const columns = [
        { field: '_id', headerName: 'ID', width: 250, editable: false },
        { field: 'name', headerName: 'Name', width: 250, editable: false },
        { field: 'questions.length', headerName: 'Question Count', width: 150, editable: false, valueGetter: (params: GridCellParams) => {
                let quickQuestion: IQuickQuestionGroup = params.row as IQuickQuestionGroup;
                return quickQuestion.content.length;
            }},

        {
            field: 'add_btn',
            headerName: '',
            sortable: false,
            width: 75,
            renderCell: (params: GridCellParams) => {
                return (<IconButton color={"warning"} onClick={() => {

                    let group = params.row as IQuickQuestionGroup;
                    let questionsToAdd = UpdateQuestionsToCreateFromBank(group, props.item);
                    let newParentItemQuestions = [...props.item.questions, ...questionsToAdd];
                    let newParentItem: SurveyObject = {...props.item, questions: newParentItemQuestions};

                    console.log(`newParentItem: ${JSON.stringify(newParentItem)}`);
                    props.setItem(newParentItem);
                    props.setShowModal(false);

                }}>
                    <FontAwesomeIcon icon={faPlus} />
                </IconButton>)
            }
        }

    ]


    React.useEffect(() => {
        async function loadData() {
            if (userToken === null || userToken === undefined) {
                return;
            }
            let all = await new BankDataController_QuickQuestionGroup(userToken).getAll();
            setQuickQuestionGroupsList(all);
        }
        loadData().then();
    }, [userToken]);

    const [quickQuestionGroupsList, setQuickQuestionGroupsList] = React.useState<IQuickQuestionGroup[]>([]);



    return (<>
        <Modal open={props.showModal} onClose={() => {
            props.setShowModal(false);
        }}>
            {props.showModal ? (<>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '60%',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        overflowY: 'auto',
                        maxHeight: '90vh',
                    }}
                >
                    <Typography id="modal-modal-title" variant="h5" component="h2" sx={{ mb: 2, textAlign: 'center' }}>
                        Import Group Questions from Bank
                    </Typography>

                    <DataGrid
                        rows={quickQuestionGroupsList}
                        columns={columns}
                        getRowId={(row: GridValidRowModel) => row._id} // was _id
                        pageSizeOptions={[100]}
                        disableRowSelectionOnClick
                    />


                </Box>
            </>) : (<></>)}
        </Modal>
    </>);
};

