import React from 'react';
import AuthenticatedLayout from "../../Library/AuthenticatedLayout";
import {CustomFormField} from "../../types/interfaces";
import {AuthContext} from "../../Library/AuthContext";
import {CustomFormFieldSizes, CustomFormFieldTypes} from "../../types/enums";
import {EditObject} from "../../Shared/Components/EditObject";
import {Button} from "@mui/material";

// https://mui.com/material-ui/icons/#font-awesome


function NotificationManagerView() {

    const {
        // user,
        userToken
    } = React.useContext(AuthContext)!;

   const [notification, setNotification] = React.useState<{ body: string, title: string }>({ body: "", title: "" });

   const formFields : CustomFormField[] = [
         {
            label: "Title",
            kind: CustomFormFieldTypes.TEXT,
             key: "title",
             size: CustomFormFieldSizes.FULL

         },
       {
           label: "Body",
           kind: CustomFormFieldTypes.TEXT,
           key: "body",
           size: CustomFormFieldSizes.FULL

       },

       {
           label: "Category",
              kind: CustomFormFieldTypes.SELECT,
                key: "category",
                size: CustomFormFieldSizes.FULL,
                options: [
                    { label: "Comments", value: "comments" },
                    // { label: "Case", value: "case" },
                    // { label: "Survey", value: "survey" },
                    // { label: "User", value: "user" }
                ]
       }


    ];

    const onSubmit = async () => {

        if (!userToken) {
            alert("You must be logged in to do that.");
            return;
        }

        let controller = new NotificationManagerController(userToken);
        let results = await controller.send(notification);
        console.log(results);
        alert("Completed")


        // alert("Not implemented")
    };


    return (
        <div>
            {/* move the sidebar stuff into a provider system. */}
            <AuthenticatedLayout  pageTitle={"Notifications"}>
                <EditObject item={notification} setItem={setNotification} form={formFields} columns={12} />
                <Button onClick={onSubmit}>Submit</Button>





                <Button onClick={() => {
                    throw new Error('Test exception triggered');
                }}>Throw Exception Test</Button>


            </AuthenticatedLayout>
        </div>
    );
}

export default NotificationManagerView;



export class NotificationManagerController  {

    private readonly baseURL : string

    constructor(private userToken: string) {
        // Nothing to do here yet.
        // const WS_URL = `ws${ process.env.REACT_APP_SERVER_URL ? "s" :"" }://${baseURL}`;
        const domainURL: string = process.env.REACT_APP_SERVER_URL || "127.0.0.1:3000";
        const protocol: string = `http${ process.env.REACT_APP_SERVER_URL ? "s" :"" }`;
        this.baseURL = `${protocol}://${domainURL}`;
    }


    public async send(item: { title: string, body: string }): Promise<any> {
        let results = await fetch(`${this.baseURL}/api/notification/`, {
            method: "POST",
            headers: {
                "authorization": `Bearer ${this.userToken}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(item)
        });
        let data = await results.json();
        console.log(data);
        return await data; // this doesn't seem to type check.
    }


}
