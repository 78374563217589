import {GridCellParams} from "@mui/x-data-grid";
import React from "react";
import {ISurvey} from "../../../types/interfaces";
import {Button, Tooltip} from "@mui/material";
import {Link as RouterLink} from "react-router-dom";

export function RenderCellViewAnswer(props: { params: GridCellParams }) {
    const answerMemo = React.useMemo(() => {
        let answers = (props.params.row as ISurvey).answers;
        if (answers === undefined || answers === null || answers.length === 0) {
            return null;
        }
        return answers[0];
    }, [props.params.row]);

    return (<>
        <Tooltip title="Open Survey Answers" arrow>
            <Button variant="outlined" color="primary" fullWidth={true} disabled={answerMemo === null} component={RouterLink} to={`/dashboard/surveyAnswer/${answerMemo !== null ? answerMemo._id : ""}`}>
                Open Answer
            </Button>
        </Tooltip>
    </>);
}
