import {TextAnswer} from "../../../../types/interfaces";
import {TextField} from "@mui/material";
import React from "react";
import {AnswerViewProps} from "../SurveyAnswerEdit";

export function TextAnswerView(props: AnswerViewProps) {
    const { answer } = props;

    let textAnswer: TextAnswer = answer as TextAnswer;
    if (textAnswer === undefined) {
        textAnswer = { text: { data: "" } } as TextAnswer;
    }

    return (<>
        <TextField
            fullWidth={true}
            type={"text"}
            label={props.question.questionText}
            value={textAnswer.text.data}
            onChange={(e) => {
                let newTextAnswer = { text: { data: e.target.value as unknown as string } } as TextAnswer;
                let surveyAnswerObject = props.surveyAnswer.answers[props.surveyObject.key];
                if (surveyAnswerObject === undefined || surveyAnswerObject === null || surveyAnswerObject.values === undefined || surveyAnswerObject.values === null) {
                    surveyAnswerObject = { values: {} };
                }
                let newValues = surveyAnswerObject.values;
                newValues[props.question.key] = newTextAnswer;
                let newSurveyAnswer = {...props.surveyAnswer, answers: {...props.surveyAnswer.answers, [props.surveyObject.key]: {...surveyAnswerObject, values: newValues}}};
                props.setSurveyAnswer(newSurveyAnswer);
                console.log("newValues", newValues);
            }}
            disabled={props.surveyAnswer.isCompleted || props.disabled}
        ></TextField>
    </>);
}
