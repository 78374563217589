import {
    ISurveyMutex,
    ITimeEntry
} from "../../types/interfaces";
import {IPaginated, PaginatedInput} from "../Bank/BankDataController_QuickObject";

export type ITimeEntryWithId = ITimeEntry & { _id?: string };

export class TimeEntryController {

    private readonly baseURL : string

    constructor(private userToken: string) {
        // Nothing to do here yet.
        // const WS_URL = `ws${ process.env.REACT_APP_SERVER_URL ? "s" :"" }://${baseURL}`;
        const domainURL: string = process.env.REACT_APP_SERVER_URL || "127.0.0.1:3000";
        const protocol: string = `http${ process.env.REACT_APP_SERVER_URL ? "s" :"" }`;
        this.baseURL = `${protocol}://${domainURL}`;
    }

    public async getAll(filter: PaginatedInput): Promise<IPaginated<ITimeEntryWithId>> {
        let results = await fetch(`${this.baseURL}/api/timeEntries/?page=${filter.page}&limit=${filter.pageSize}`, {
            headers: {
                "authorization": `Bearer ${this.userToken}`
            }
        });
        let data = await results.json();
        return await data; // this doesn't seem to type check.
    }


}
