// https://www.linode.com/docs/guides/authenticating-over-websockets-with-jwt/
// https://developer.okta.com/blog/2020/10/28/secure-web-apps-websockets-nodejs

import React from 'react';
import {DataGrid, GridCellParams, GridColDef, GridValidRowModel} from '@mui/x-data-grid';
import AuthenticatedLayout, {AuthenticatedLayoutCustomButton} from "../../Library/AuthenticatedLayout";
import {CustomFormField, ICompany, IProject, ISurvey, IUser} from "../../types/interfaces";
import {ISurveyFilter, ISurveyWithId, SurveyDataController} from "./SurveyDataController";
import {AuthContext} from "../../Library/AuthContext";
import {Box, Button, Modal, Typography} from "@mui/material";
import {CustomFormFieldSizes, CustomFormFieldTypes, DEFAULT_PAGE_SIZE_OPTIONS, UserRole} from "../../types/enums";
import {UserDataController} from "../Users/UserDataController";
import {EditObject} from "../../Shared/Components/EditObject";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {faTrashUndo} from "@fortawesome/pro-solid-svg-icons";
import {useLocalStorage} from "../../Hooks/useLocalStorage";
import {DateFilter, StoredPaginationModel} from "./SurveyManagementView";


function CloneArchivedSurveyModal(props: { usersList: IUser[], onSave: ((survey: ISurvey) => void), onCancel: (() => void), survey: ISurvey })  {

    // const [usersList, setUserList] = React.useState<(IUser & {_id: string})[]>([])

    const form: CustomFormField[] = [
        {
            kind: CustomFormFieldTypes.TEXT,
            size: CustomFormFieldSizes.FULL,
            key: "caseNumber",
            label: "Case Number"
        },
        {
            kind: CustomFormFieldTypes.SELECT,
            size: CustomFormFieldSizes.FULL,
            key: "assigned_user_id",
            label: "Assigned User Id",
            options: [
                { label: "Un-assigned", value: null },
                ...props.usersList.map((opt: (IUser & {_id: string})) => { return { label: opt.name, value: opt._id }; })
            ]
        },
    ];
    const [survey, setSurvey] = React.useState<ISurvey>({
        caseNumber: "",
        assigned_user_id: null,
        content: props.survey.content,
        locations: props.survey.locations,
        // selected technician
    });

    React.useEffect(() => {
        setSurvey({
            caseNumber: survey.caseNumber,
            assigned_user_id: survey.assigned_user_id,
            content: props.survey.content,
            locations: props.survey.locations,
            // selected technician
        });
    }, [props.usersList, props.survey, survey.assigned_user_id, survey.caseNumber]);



    return (
        <Box sx={{
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4
        }}>
            <Typography id="modal-modal-title" variant="h5" component="h2" sx={{ mb: 2, textAlign: 'center' }}>
                Clone Survey: {props.survey.caseNumber}
            </Typography>

            <EditObject item={survey} setItem={setSurvey} form={form} columns={5}></EditObject>

            {/*<p>Content: {JSON.stringify(survey)}</p>*/}

            <br />

            <Button type={"submit"} variant={"contained"} size={"large"} fullWidth={true} onClick={() => {
                props.onSave(survey);
            }} disabled={ survey === undefined || survey['caseNumber'] === "" }>CREATE</Button>

        </Box>
    );
}
export function CloneArchivedSurveyButton(props: {
    userToken: string | null | undefined,
    setList: ((list: ISurvey[]) => void),
    updateList: (() => void),
    usersList: IUser[],
    survey: ISurvey
})
{

    // const {
    //     // user,
    //     // userToken
    // } = React.useContext(AuthContext)!;

    const [show, setShow] = React.useState<boolean>(false);

    return (<>
        <Modal
            open={show}
            onClose={() => { setShow(false) }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            {<>
                <CloneArchivedSurveyModal
                    usersList={props.usersList}
                    survey={props.survey}
                    onSave={async (survey: ISurvey) => {
                        if (props.userToken === null || props.userToken === undefined) {
                            return;
                        }
                        let surveyToClone = survey;
                        surveyToClone.isArchived = false;
                        await new SurveyDataController(props.userToken).create(surveyToClone);

                        props.updateList();
                        setShow(false);
                    }}
                    onCancel={() => { setShow(false) }}
                ></CloneArchivedSurveyModal>
            </>}
        </Modal>

        <Button variant="outlined" color="error" fullWidth={true} onClick={() => {
            setShow(true);
        }}>
            Clone
        </Button>
    </>);
}


function ArchivedSurveysListView() {

    const {
        user,
        userToken
    } = React.useContext(AuthContext)!;

    const [usersList, setUserList] = React.useState<(IUser & {_id: string})[]>([])

    const [selectionModel, setSelectionModel] = React.useState([]);
    const handleSelection = (newSelectionModel: any) => {
        setSelectionModel(newSelectionModel);
        // Handle the selection
    };

    const [deleteActive, setDeleteActive] = React.useState<boolean>(false);
    const [restoreActive, setRestoreActive] = React.useState<boolean>(false);

    const [list, setList] = React.useState<ISurveyWithId[]>([]);
    const [totalRows, setTotalRows] = React.useState<number>(0);
    const [searchText, setSearchText] = useLocalStorage("ArchivedSurveysListView.searchText", "") as [string, React.Dispatch<React.SetStateAction<string>>];

    const [paginationModel, setPaginationModel] = useLocalStorage("ArchivedSurveysListView.paginationModel", {
        pageSize: 25,
        page: 0,
    }) as [StoredPaginationModel, React.Dispatch<React.SetStateAction<StoredPaginationModel>>];

    const [dateFilter, setDateFilter] = useLocalStorage("ArchivedSurveysListView.dateFilter", {
        filterStart_year: null,
        filterStart_month: null,
        filterStart_day: null,
        filterEnd_year: null,
        filterEnd_month: null,
        filterEnd_day: null,
    }) as [DateFilter, React.Dispatch<React.SetStateAction<DateFilter>>];


    const UpdateList = React.useCallback(async () => {
        if (userToken === null || userToken === undefined) {
            return;
        }
        let filter:  ISurveyFilter = {
            page: paginationModel.page,
            pageSize: paginationModel.pageSize,
            searchText: searchText,
            isArchived: true,
            ...dateFilter
        }
        let response = await new SurveyDataController(userToken).getPaginated(filter);
        setList(response.items);
        setTotalRows(response.count);

        let ListOfUsers = await new UserDataController(userToken).getAll();
        if (ListOfUsers === null || ListOfUsers === undefined) {
            alert("Error loading");
            return;
        }
        setUserList(ListOfUsers.filter((u) => u.role !== UserRole.CLIENT).sort((a, b) => a.name.localeCompare(b.name)));
    },[userToken, paginationModel.page, paginationModel.pageSize, searchText, dateFilter]);

    const customButtonsMemo = React.useMemo(() => {
        let buttons: AuthenticatedLayoutCustomButton[] = [];

        // Add button

        buttons.push({
            icon: <FontAwesomeIcon icon={faTrash} />,
            label: "Delete Selected",
            action: () => {

                async  function deleteSurveys() {
                    if (userToken === null || userToken === undefined) {
                        return;
                    }
                    for (let i = 0; i < selectionModel.length; i++) {
                        let id = selectionModel[i];
                        await new SurveyDataController(userToken).delete(id);
                    }
                    UpdateList().then();
                }

                setDeleteActive(true);
                if (window.confirm(`Are you sure you want to delete ${selectionModel.length} surveys?`)) {
                    deleteSurveys().then(() => {
                        setDeleteActive(false);
                    });
                }
                // setAddNewObjectModalShown(true)
            },
            disabled: selectionModel.length === 0 || deleteActive,
        });

        buttons.push({
            icon: <FontAwesomeIcon icon={faTrashUndo} />,
            label: "Restore",
            action: () => {

                async  function restoreSurveys() {
                    if (userToken === null || userToken === undefined) {
                        return;
                    }
                    for (let i = 0; i < selectionModel.length; i++) {
                        let id = selectionModel[i];
                        let survey = list.find((s) => s._id === id);
                        if (survey === undefined || survey === null || survey._id === undefined || survey._id === null) {
                            continue;
                        }
                        survey.isArchived = false;
                        await new SurveyDataController(userToken).update(survey._id, survey);
                    }
                    UpdateList().then();
                }

                setRestoreActive(true);
                if (window.confirm(`Are you sure you want to restore ${selectionModel.length} surveys?`)) {
                    restoreSurveys().then(() => {
                        setRestoreActive(false);
                    });
                }
                // setAddNewObjectModalShown(true)
            },
            disabled: selectionModel.length === 0 || restoreActive,
        });

        return buttons;
    }, [deleteActive, selectionModel, userToken, restoreActive, list, UpdateList]);

    const columns = React.useMemo(() => {
        let columns: GridColDef[] = [
            { field: 'caseNumber', headerName: 'Case Number', width: 300, editable: false },
            { field: 'status', headerName: 'Status', width: 300, editable: false },
            // { field: 'isArchived', headerName: 'isArchived', width: 125, editable: false },
            { field: 'surveyDate', headerName: 'Survey Date', width: 300, editable: false,
                valueGetter: params => {
                    try {
                        let survey = params.row as ISurvey;
                        if (survey.surveyDate !== undefined && survey.surveyDate !== null){
                            // return new Date(survey.surveyDate).toLocaleDateString();
                            return "DEBUG: " + survey.surveyDate;
                        }
                        else {
                            return ""
                        }
                    }
                    catch (e: any) {
                        // alert(e.message)
                        return ""
                    }
                }
            },
            { field: 'assigned_user_id', headerName: 'Assigned User', width: 300, editable: false, valueGetter: params => {
                    try {
                        let survey = params.row as ISurvey;
                        if (survey.assigned_user_id !== undefined){
                            let user = survey.assigned_user_id as IUser;
                            return user.name;
                        }
                        else {
                            return "Unassigned"
                        }
                    }
                    catch (e) {
                        return "Unassigned"
                    }

                } },
            { field: 'surveyOwner', headerName: 'Survey Owner', width: 300, editable: false },


            {
                field: 'restore_btn',
                headerName: '',
                sortable: false,
                width: 100,
                renderCell: (params: GridCellParams) => (
                    <Button variant="outlined" color="primary" fullWidth={true} onClick={() => {
                        async function restoreRow() {
                            let row = params.row as ISurvey & { _id: string };
                            row.isArchived = false;

                            if (userToken === null || userToken === undefined) {
                                return;
                            }
                            await new SurveyDataController(userToken).update(row._id, row);
                            // let all = await new SurveyDataController(userToken).getAllArchived();
                            // setList(all);

                            UpdateList().then();

                        }

                        if (window.confirm("Are you sure you want to restore this survey?")){
                            restoreRow().then();
                        }

                    }}>
                        Restore
                        {/*<FontAwesomeIcon icon={faTrash} />*/}
                    </Button>
                ),
            },


            {
                field: 'clone_btn',
                headerName: '',
                sortable: false,
                width: 100,
                renderCell: (params: GridCellParams) => (<>

                        <CloneArchivedSurveyButton
                            userToken={userToken}
                            setList={setList}
                            survey={params.row as ISurvey}
                            usersList={usersList}
                            updateList={() => {
                                UpdateList().then();
                            }}
                        />
                    </>
                ),
            }

        ]


        if (user?.role === UserRole.ADMIN) {

            columns.push({ field: 'company_project', headerName: 'Company / Project', width: 300, editable: false,
                valueGetter: (params) => {
                    let survey = params.row as ISurvey;
                    if (survey !== undefined && survey.project !== null && survey.project !== undefined) {
                        let project = survey.project as IProject;
                        if (project !== undefined && project.company !== null && project.company !== undefined) {
                            return (project.company as ICompany).name + " / " + project.name
                        }
                        // return (survey.project as IProject).company.name;
                    }
                    return "";
                }
            });

            columns.push({
                field: 'delete_btn',
                headerName: '',
                sortable: false,
                width: 180,
                renderCell: (params: GridCellParams) => (
                    <Button variant="outlined" color="primary" fullWidth={true} onClick={() => {
                        async function removeRow() {
                            if (userToken === null || userToken === undefined) {
                                return;
                            }
                            await new SurveyDataController(userToken).delete(params.row._id);
                            UpdateList().then();
                        }

                        if (window.confirm("Are you sure you want to archive this survey?")){
                            removeRow().then();
                        }
                    }}>
                        Fully Delete
                    </Button>
                ),
            })
        }

        return columns;

    }, [user, userToken]);



    React.useEffect(() => {
        UpdateList().then();
    }, [userToken, paginationModel.page, paginationModel.pageSize, searchText, dateFilter, UpdateList]);

    return (
        <div>
            {/* move the sidebar stuff into a provider system. */}
            <AuthenticatedLayout  pageTitle={"Archived Surveys"}
                                  customButtons={customButtonsMemo}
                                  searchText={{text: searchText, setText: setSearchText, label: "Search (Case Number, Assigned User)"}}
            >
                <DataGrid
                    // make sure to set height to 100% so that the grid fills the whole container
                    style={{ height: '100%' }}
                    rows={list}
                    columns={columns}
                    getRowId={(row: GridValidRowModel) => row._id}
                    pageSizeOptions={DEFAULT_PAGE_SIZE_OPTIONS}
                    disableRowSelectionOnClick
                    checkboxSelection={user?.role === UserRole.ADMIN}
                    onRowSelectionModelChange={handleSelection}
                    rowSelectionModel={selectionModel}
                    pagination={true}
                    rowCount={totalRows}
                    paginationMode={"server"}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                />
            </AuthenticatedLayout>
        </div>
    );
}

export default ArchivedSurveysListView;
