import {
    CustomFormField,
    IQuickQuestion, IQuickQuestionGroup,
    ISurvey,
    SurveyObject,
    SurveyObjectQuestion
} from "../../../types/interfaces";
import React from "react";
import {CustomFormFieldSizes, CustomFormFieldTypes, UserRole} from "../../../types/enums";
import {DataGrid, GridCellParams, GridValidRowModel} from "@mui/x-data-grid";
import {

    Button,
    FormControlLabel,
    FormGroup,
    IconButton,
    Stack, Switch,
    Tooltip,
} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faArrowAltCircleDown,
    faArrowAltCircleUp,
    faArrowDown,
    faArrowUp,
} from "@fortawesome/free-solid-svg-icons";
import {EditObject} from "../../../Shared/Components/EditObject";
import {
    AddNewListQuestion,
    AddNewQuestion,
    EditButtonModalContainer,
} from "./QuestionView";
import DeleteIcon from "@mui/icons-material/Delete";
import {BankDataController_QuickQuestion} from "../../Bank/BankDataController_QuickQuestion";
import {UploadOutlined as UploadOutlineIcon} from "@mui/icons-material";
import {BankDataController_QuickQuestionGroup} from "../../Bank/BankDataController_QuickQuestionGroup";
import {RenderCellValidInvalidIcon} from "./RenderCellValidInvalidIcon";
import {RenderCellMoveQuestionToAnotherObject} from "./RenderCellMoveQuestionToAnotherObject";
import {QuickBulkAddConditionModal} from "./QuickBulkAddConditionModal";
import {BulkImportQuestionModal} from "./BulkImportQuestionModal";
import {QuickBulkAddConditionFlagsModal} from "./QuickBulkAddConditionFlagsModal";
import {AuthContext} from "../../../Library/AuthContext";








export function ContentAccordionDetail(props: { item: SurveyObject, setItem: (item: SurveyObject) => void, survey: ISurvey, setSurvey: (survey: ISurvey) => void, userToken: string, showExpandedColumns: boolean }) {
    const { item } = props;


    const {
        user,
        userToken
    } = React.useContext(AuthContext)!;

    const [openNewQuestion, setOpenNewQuestion] = React.useState(false);
    const [openImportQuestion, setOpenImportQuestion] = React.useState(false);

    const fields = React.useMemo(
        () => {
            let fields: CustomFormField[] = [
                {
                    kind: CustomFormFieldTypes.TEXT,
                    size: CustomFormFieldSizes.HALF,
                    key: "key",
                    label: "Key"
                },
                {
                    kind: CustomFormFieldTypes.TEXT,
                    size: CustomFormFieldSizes.HALF,
                    key: "location",
                    label: "Object Name",
                },
                {
                    kind: CustomFormFieldTypes.TOGGLE,
                    size: CustomFormFieldSizes.TWO,
                    key: "isConditional",
                    label: "Installed / Not Installed",
                    readonly: item.emptyIntentionally
                },
                {
                    kind: CustomFormFieldTypes.TOGGLE,
                    size: CustomFormFieldSizes.TWO,
                    key: "emptyIntentionally",
                    label: "Empty Intentionally",
                },

                {
                    kind: CustomFormFieldTypes.SELECT,
                    size: CustomFormFieldSizes.HALF,
                    key: "parent",
                    label: "Parent",
                    options: [
                        {label: "", value: ""},
                        ...props.survey.content.filter((content: SurveyObject) => content.key !== item.key).map((content: SurveyObject) => ({ label: content.key + " - " + content.location, value: content.key }))
                    ]
                },

            ];

            if (user?.role === UserRole.ADMIN) {
                fields.push({
                    kind: CustomFormFieldTypes.TEXT,
                    size: CustomFormFieldSizes.TWO,
                    key: "parent",
                    label: "Parent",
                },)
            }

            return fields;
        },
        [item, props.survey]
    );

    function AddNewQuestionButtonClicked(question: SurveyObjectQuestion) {
        let questions: SurveyObjectQuestion[] = [...item.questions, question]
        console.log(`item: ${JSON.stringify(item)}, question: ${JSON.stringify(question)}`);
        props.setItem({...item, questions});
        return true;
    }

    function MoveToTopBtn (params: GridCellParams) {
        let index = item.questions.findIndex((question: SurveyObjectQuestion) => question.key === params.row.key);
        return (<Tooltip title="Move Question To Top" arrow><IconButton disabled={index === 0} color={"warning"} onClick={() => {
            let questions: SurveyObjectQuestion[] = [...item.questions];
            let temp = questions[index];
            questions.splice(index, 1);
            questions.unshift(temp);
            props.setItem({...item, questions});
        }}>
            <FontAwesomeIcon icon={faArrowAltCircleUp} />
        </IconButton></Tooltip>)
    }

    function MoveToUpBtn(params: GridCellParams) {
        let index = item.questions.findIndex((question: SurveyObjectQuestion) => question.key === params.row.key);
        return (<Tooltip title="Move Question Up" arrow><IconButton disabled={index === 0} color={"warning"} onClick={() => {
            let questions: SurveyObjectQuestion[] = [...item.questions];
            let temp = questions[index];
            questions[index] = questions[index - 1];
            questions[index - 1] = temp;
            props.setItem({...item, questions});
        }}>
            <FontAwesomeIcon icon={faArrowUp} />
        </IconButton></Tooltip>)
    }

    function MoveDownBtn(params: GridCellParams) {
        let index = item.questions.findIndex((question: SurveyObjectQuestion) => question.key === params.row.key);
        return (<Tooltip title="Move Question Down" arrow><IconButton disabled={index === item.questions.length - 1} color={"warning"} onClick={() => {
            let questions: SurveyObjectQuestion[] = [...item.questions];
            let temp = questions[index];
            questions[index] = questions[index + 1];
            questions[index + 1] = temp;
            props.setItem({...item, questions});
        }}>
            <FontAwesomeIcon icon={faArrowDown} />
        </IconButton></Tooltip>)
    }

    function MoveToBottomBtn  (params: GridCellParams) {
        let index = item.questions.findIndex((question: SurveyObjectQuestion) => question.key === params.row.key);
        return (<Tooltip title="Move Question To Bottom" arrow><IconButton disabled={index === item.questions.length - 1} color={"warning"} onClick={() => {
            let questions: SurveyObjectQuestion[] = [...item.questions];
            let temp = questions[index];
            questions.splice(index, 1);
            questions.push(temp);
            props.setItem({...item, questions});
        }}>
            <FontAwesomeIcon icon={faArrowAltCircleDown} />
        </IconButton></Tooltip>)
    }

    function DeleteBtn(params: GridCellParams) {
        return (<Tooltip title="Delete Question" arrow><IconButton color={"error"} onClick={() => {
            if (window.confirm(`Are you sure you want to delete ${params.row.key}?`)) {
                let questions: SurveyObjectQuestion[] = item.questions.filter((question: SurveyObjectQuestion) => question.key !== params.row.key);
                props.setItem({...item, questions});
            }
        }}><DeleteIcon /></IconButton></Tooltip>)
    }

    function EditBtn(params: GridCellParams) {
        let index = item.questions.findIndex((question: SurveyObjectQuestion) => question.key === params.row.key);
        return (<>
            <EditButtonModalContainer survey={props.survey} item={item} setItem={props.setItem} params={params} index={index} />
        </>)
    }

    function ExportBtn(params: GridCellParams) {
        return (
            <Tooltip title={"Export Question to Bank"}>
            <IconButton color={"secondary"} onClick={() => {
            if (props.userToken === null || props.userToken === undefined) {
                alert("You must be logged in to export.");
                return;
            }
            let objectToCreate: IQuickQuestion = {
                content : params.row as SurveyObjectQuestion,
            } as IQuickQuestion;

            objectToCreate.content.conditions = [];

            new BankDataController_QuickQuestion(props.userToken).create(objectToCreate).then((response) => {
                alert("Exported successfully.");
            });

            }}><UploadOutlineIcon /></IconButton></Tooltip>)
    }


    const columns = React.useMemo(() => {



        let columns: any[] = [
            { field: 'valid', headerName: '', width: 50, renderCell:  (params: GridCellParams) => (<><RenderCellValidInvalidIcon {...props} params={params}  /></>) },
        ];

        if (!item.emptyIntentionally) {
            columns.push(
                { field: 'edit_btn', headerName: '', width: 75, renderCell: EditBtn }
            )
        }

        columns.push({ field: 'questionText', headerName: 'Question', width: 500 });
        columns.push({ field: 'type', headerName: 'Type', width: 150, valueGetter: (params: GridCellParams) => { return (params.row as SurveyObjectQuestion).type.type } });
        columns.push({ field: 'key', headerName: 'Key', width: 150 });
        columns.push({ field: 'options', headerName: 'Options', width: 250, valueGetter: (params: GridCellParams) => { return (params.row as SurveyObjectQuestion).type.options?.join(", ") ?? "" }});
        columns.push({ field: 'defaultValue', headerName: 'Default Value', width: 250, valueGetter: (params: GridCellParams) => { return (params.row as SurveyObjectQuestion).type.defaultValue }});

        if (props.showExpandedColumns) {
            columns.push({ field: 'conditions', headerName: 'conditions', width: 250, valueGetter: (params: GridCellParams) => { return JSON.stringify((params.row as SurveyObjectQuestion).conditions) }})
            columns.push({ field: 'required', headerName: 'required', width: 250, valueGetter: (params: GridCellParams) => { return JSON.stringify((params.row as SurveyObjectQuestion).required) }});
            columns.push({ field: 'showInStandardReport', headerName: 'showInStandardReport', width: 250, valueGetter: (params: GridCellParams) => { return JSON.stringify((params.row as SurveyObjectQuestion).showInStandardReport) }});
            columns.push({ field: 'showInExceptionReport', headerName: 'showInExceptionReport', width: 500, valueGetter: (params: GridCellParams) => { return JSON.stringify((params.row as SurveyObjectQuestion).showInExceptionReport) }});
            columns.push({ field: 'requiredConditionFlags', headerName: 'requiredConditionFlags', width: 500, valueGetter: (params: GridCellParams) => { return JSON.stringify((params.row as SurveyObjectQuestion).requiredConditionFlags) }});
        }




        if (!item.emptyIntentionally) {
            columns.push(
                { field: 'move_to_top_btn', headerName: '', width: 75, renderCell: MoveToTopBtn }
            )

            columns.push(
                { field: 'move_up_btn', headerName: '', width: 75, renderCell: MoveToUpBtn }
            )

            columns.push(
                { field: 'move_down_btn', headerName: '', width: 75, renderCell: MoveDownBtn }
            )

            columns.push(
                { field: 'move_to_bottom_btn', headerName: '', width: 75, renderCell: MoveToBottomBtn }
            )
        }

        columns.push(
            { field: 'delete_btn', headerName: '', width: 75, renderCell: DeleteBtn}
        )

        if (!item.emptyIntentionally) {
            columns.push(
                { field: 'export_btn', headerName: '', width: 75, renderCell: ExportBtn }
            )

            columns.push(
                { field: 'move_question_to_another_object', headerName: '', width: 75,
                    renderCell: (params: GridCellParams) => (<><RenderCellMoveQuestionToAnotherObject {...props} params={params}  /></>),
                }
            )
        }

        return columns;

    }, [item, props.showExpandedColumns]);

    const [showBulkConditionAddModal, setShowBulkConditionAddModal] = React.useState<boolean>(false);

    const [showBulkImportQuestionModal, setShowBulkImportQuestionModal] = React.useState<boolean>(false);
    const [showBulkImportQuestionFlagsModal, setShowBulkImportQuestionFlagsModal] = React.useState<boolean>(false);


    const [bulkSelectMode, setBulkSelectMode] = React.useState<boolean>(false);



    const [selectionModel, setSelectionModel] = React.useState([]);
    const handleSelection = (newSelectionModel: any) => {
        setSelectionModel(newSelectionModel);
        // Handle the selection
    };

    return (<>
        <div style={{ padding: "1rem"}}>
            {/*<pre>ShowExpandedColumns: {JSON.stringify(props.showExpandedColumns)}</pre>*/}
            <EditObject item={item} setItem={props.setItem} form={fields} columns={12} />
            <br />
            <br />
            <Stack direction={"column"} spacing={2}>
                <Stack direction={"row"} spacing={2}>
                    <Button variant="contained" disabled={item.emptyIntentionally} onClick={() => {setOpenNewQuestion(true);}}>Add New Question</Button>
                    <Button variant="contained" disabled={item.emptyIntentionally} onClick={() => {setOpenImportQuestion(true);}}>Import Question</Button>
                    <Button variant="contained" disabled={item.emptyIntentionally} onClick={() => {setShowBulkImportQuestionFlagsModal(true);}}>Quick Bulk Add Condition Flags</Button>
                    <Button variant="contained" disabled={item.questions.length === 0} onClick={() => {if (window.confirm(`Are you sure you want to delete all questions?`)) {props.setItem({...item, questions: []})}}}>Delete All Questions</Button>

                    <Button variant={"contained"} disabled={item.questions.length === 0 || item.emptyIntentionally} onClick={() => {
                        setShowBulkConditionAddModal(true)
                    } }>Quick Bulk Add Condition</Button>

                    <Button variant="contained" disabled={item.emptyIntentionally} onClick={() => {
                        setShowBulkImportQuestionModal(true)
                    }}>Import Bulk from Bank</Button>

                </Stack>


                <Stack direction={"row"} spacing={2}>
                    <FormGroup>
                        <FormControlLabel
                            control={<Switch
                                checked={bulkSelectMode}

                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setBulkSelectMode(event.target.checked);
                                    if (!event.target.checked) {
                                        setSelectionModel([]);
                                    }
                                }}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />}
                            label="Bulk Question Select"
                        />
                    </FormGroup>


                    <Button variant="contained" disabled={!bulkSelectMode || selectionModel.length === 0} onClick={() => {
                        // setOpenImportQuestion(true);

                        async function exportBulkQuestions() {
                            let questionsToExport = selectionModel.map((key: string) => {
                                return item.questions.filter((question: SurveyObjectQuestion) => question.key === key)[0];
                            });

                            let groupName = window.prompt("Enter a name for the group", "New Group");

                            if (groupName === null || groupName === undefined || groupName === "") {
                                return;
                            }

                            let groupToCreate: IQuickQuestionGroup = {
                                name: groupName,
                                content: questionsToExport
                            } as IQuickQuestionGroup;

                            new BankDataController_QuickQuestionGroup(props.userToken).create(groupToCreate).then((response) => {
                                alert("Exported successfully.");
                            });
                        }


                        exportBulkQuestions().then();


                    }}>Export Selected to Bank</Button>

                    <Button variant="contained" disabled={!bulkSelectMode || selectionModel.length === 0 || item.emptyIntentionally} onClick={() => {
                        // delete selected questions
                        if (window.confirm(`Are you sure you want to delete ${selectionModel.length} questions?`)) {
                            // loop over the selected questions and remove them from the list.
                            let questionsToRemove = selectionModel.map((key: string) => {
                                return item.questions.filter((question: SurveyObjectQuestion) => question.key === key)[0];
                            });
                            let newParentItem = {...item};
                            newParentItem.questions = newParentItem.questions.filter((question) => !questionsToRemove.includes(question));
                            props.setItem(newParentItem);
                        }
                    }}>Delete Selected</Button>
                </Stack>

            </Stack>



            <br />
            <br />
            <AddNewQuestion survey={props.survey} onAddNewQuestion={AddNewQuestionButtonClicked} onClose={() => setOpenNewQuestion(false)} open={openNewQuestion} surveyObject={item}/>

            <AddNewListQuestion onAddNewQuestion={AddNewQuestionButtonClicked} onClose={() => setOpenImportQuestion(false)} open={openImportQuestion}/>

            {item.questions.length > 0 && (
                <DataGrid
                    rows={item.questions}
                    columns={columns}
                    getRowId={(row: GridValidRowModel) => row.key} // was _id
                    pageSizeOptions={[100]}
                    disableRowSelectionOnClick
                    checkboxSelection={bulkSelectMode}
                    onRowSelectionModelChange={handleSelection}
                    rowSelectionModel={selectionModel}
                    // may have to do some smarts with is row selectable
                />
            ) }



            <QuickBulkAddConditionModal
                item={item}
                setItem={props.setItem}
                showModal={showBulkConditionAddModal}
                setShowModal={setShowBulkConditionAddModal}
            />

            <BulkImportQuestionModal
                item={item}
                setItem={props.setItem}
                showModal={showBulkImportQuestionModal}
                setShowModal={setShowBulkImportQuestionModal}
            />

            <QuickBulkAddConditionFlagsModal
                item={item}
                setItem={props.setItem}
                showModal={showBulkImportQuestionFlagsModal}
                setShowModal={setShowBulkImportQuestionFlagsModal}
                survey={props.survey}
            />

        </div>
    </>);
}



