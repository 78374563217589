import {
    ICondition,
    ISurvey,
    SurveyObject,
    SurveyObjectQuestion,
    SurveyWarning,
    SurveyWarningType
} from "../types/interfaces";


function hasMoreThanXCharactersBetweenNewLines(count: number, text: string): boolean {
    // Split the text by newline characters
    const lines = text.split('\n');

    // Check each line's length
    for (let line of lines) {
        if (line.length > count) {
            return true;
        }
    }

    return false;
}

export function CheckSurveyObjectQuestionForWarnings(survey: ISurvey, surveyObject: SurveyObject, question: SurveyObjectQuestion) {
    let warnings:  SurveyWarning[] = [];

    if (question.questionText === undefined || question.questionText === null || question.questionText === ""){
        warnings.push({
            message: `Question ${surveyObject.key} ${question.key} has no question text.`,
            type: SurveyWarningType.error,
        })
    }

    question.conditions?.forEach((condition: ICondition) => {
        let selectedQuestions = surveyObject.questions.filter((q) => q.key === condition.key)[0];
        if (selectedQuestions === undefined) {
            warnings.push({
                message: `Question ${surveyObject.key} ${question.key} is missing. (Condition)`,
                type: SurveyWarningType.error,
            })

        }
    });

    // New part of the system.
    let questionsUsedInConditions: Record<string, number> = {};
    question.conditions.forEach((q) => {
        console.log(`q: ${JSON.stringify(q)}`);
        if (questionsUsedInConditions[q.key] === undefined){
            questionsUsedInConditions[q.key] = 0;
        }
        questionsUsedInConditions[q.key]++;
    })


    Object.keys(questionsUsedInConditions).forEach((key) => {
        if (questionsUsedInConditions[key] > 1){
            warnings.push({
                message: `Question  ${surveyObject.key} ${key} is used in multiple conditions. This may cause the question to never be shown.`,
                type: SurveyWarningType.warning,
            })
        }
    })


    // show warnings if there is a required condition flag that isn't included on the survey list level.
    if (question.requiredConditionFlags !== undefined && question.requiredConditionFlags !== null) {
        question.requiredConditionFlags.forEach((condition) => {
            if ((survey.conditionFlags !== undefined && Object.keys(survey.conditionFlags).length > 0 && survey.conditionFlags[condition] === undefined)|| survey.conditionFlags === undefined){
                warnings.push({
                    message: `Question ${surveyObject.key} ${question.key} has a required condition flag that is not set.`,
                    type: SurveyWarningType.error,
                })
            }
        });
    }
    // else {
    //     warnings.push(`Question ${surveyObject.key} ${question.key} has no required condition flags.`);
    // }

    if (question.showInStandardReport !== undefined && question.showInStandardReport !== null) {
        if (typeof question.showInStandardReport === 'boolean') {
            console.log(`booleanCondition: ${JSON.stringify(question.showInStandardReport)}`);
        } else if (question.showInStandardReport && 'key' in question.showInStandardReport) {
            // Assuming ICondition has a 'key' property
            let singleCondition = question.showInStandardReport as ICondition;
            let selectedQuestions = surveyObject.questions.filter((q) => q.key === singleCondition.key)[0];
            if (selectedQuestions === undefined) {
                warnings.push({
                    message: `Question ${surveyObject.key} ${question.key} is missing. (Standard Report)`,
                    type: SurveyWarningType.error,
                })
            }

        } else if (Array.isArray(question.showInStandardReport)) {
            let multipleConditions = question.showInStandardReport as ICondition[];
            multipleConditions.forEach((condition: ICondition) => {
                let selectedQuestions = surveyObject.questions.filter((q) => q.key === condition.key)[0];
                if (selectedQuestions === undefined) {
                    warnings.push({
                        message: `Question ${surveyObject.key} ${question.key} is missing. (Standard Report)`,
                        type: SurveyWarningType.error,
                    })
                }
            })

            if (multipleConditions.length === 1) {
                warnings.push({
                    message: `Question ${surveyObject.key} ${question.key} has only one condition, but is configured using multiple condition. (Standard Report)`,
                    type: SurveyWarningType.warning,
                })
            }
        }
    }

    if (question.showInExceptionReport !== undefined && question.showInExceptionReport !== null) {
        if (typeof question.showInExceptionReport === 'boolean') {
            console.log(`booleanCondition: ${JSON.stringify(question.showInExceptionReport)}`);
        } else if (question.showInExceptionReport && 'key' in question.showInExceptionReport) {
            // Assuming ICondition has a 'key' property
            let singleCondition = question.showInExceptionReport as ICondition;
            let selectedQuestions = surveyObject.questions.filter((q) => q.key === singleCondition.key)[0];
            if (selectedQuestions === undefined) {
                // warnings.push(`NEW!: Question ${surveyObject.key} ${question.key} is missing. (Exception Report)`);
                warnings.push({
                    message: `Question ${surveyObject.key} ${question.key} is missing. (Exception Report)`,
                    type: SurveyWarningType.error,
                })
            }

        } else if (Array.isArray(question.showInExceptionReport)) {
            let multipleConditions = question.showInExceptionReport as ICondition[];
            multipleConditions.forEach((condition: ICondition) => {
                let selectedQuestions = surveyObject.questions.filter((q) => q.key === condition.key)[0];
                if (selectedQuestions === undefined) {
                    warnings.push({
                        message: `Question ${surveyObject.key} ${question.key} is missing. (Exception Report)`,
                        type: SurveyWarningType.error,
                    })

                }
            })

            if (multipleConditions.length === 1) {
                warnings.push({
                    message: `Question ${surveyObject.key} ${question.key} has only one condition, but is configured using multiple condition. (Exception Report)`,
                    type: SurveyWarningType.warning,
                })
            }
        }
    }

    if (question.questionInstruction !== undefined && question.questionInstruction !== null && question.questionInstruction !== ""){
        if (hasMoreThanXCharactersBetweenNewLines(140, question.questionInstruction)){
            warnings.push({
                message: `Question ${surveyObject.key} ${question.key} has a question instruction with more than 140 characters between newlines.`,
                type: SurveyWarningType.warning,
            })
        }
    }

    return warnings
}

export function CheckSurveyObjectForWarnings(survey: ISurvey, surveyObject: SurveyObject): SurveyWarning[] {
    let warnings: SurveyWarning[] = [];

    if (surveyObject.emptyIntentionally === true && surveyObject.questions.length > 0){

        warnings.push({
            message: `Object ${surveyObject.key} is marked as 'Empty Intentionally' but has questions.`,
            type: SurveyWarningType.warning,
        })
    }

    if (surveyObject.emptyIntentionally === false && surveyObject.questions.length === 0){
        warnings.push({
            message: `Object ${surveyObject.key} has no questions.`,
            type: SurveyWarningType.warning,
        })
    }

    if (surveyObject.emptyIntentionally === undefined && surveyObject.questions.length === 0){
        // warnings.push(`Object ${surveyObject.key} has no questions.`);
        warnings.push({
            message: `Object ${surveyObject.key} has no questions.`,
            type: SurveyWarningType.warning,
        })
    }

    // this should work for the object level.
    surveyObject.questions.forEach((question: SurveyObjectQuestion) => {
        console.log(`question: ${JSON.stringify(question)}`);
        warnings = warnings.concat(CheckSurveyObjectQuestionForWarnings(survey, surveyObject, question));
    })

    return warnings
}

export function CheckSurveyForWarnings(survey: ISurvey): SurveyWarning[] {
    let warnings: SurveyWarning[] = [];

    if (survey.caseNumber.includes("/") || survey.caseNumber.includes("\\")){
        warnings.push({
            message: `Survey case number contains a slash.`,
            type: SurveyWarningType.error,
        })
    }

    if (survey.content === undefined || survey.content === null){
        return warnings;
    }

    if (survey.content.length === 0){
        warnings.push({
            message: `Survey has no objects.`,
            type: SurveyWarningType.warning,
        })
    }

    survey.content.forEach((object: SurveyObject) => {
        warnings = [...warnings, ...CheckSurveyObjectForWarnings(survey, object)]
        // warnings = warnings.concat(CheckSurveyObjectForWarnings(survey, object));
    })

    return warnings
}
