import {CustomFormField, SurveyObject} from "../../../types/interfaces";
import React, {Dispatch} from "react";
import {Button, Paper, Stack} from "@mui/material";

export const QuickSelectOptions = (props: { surveyObject: SurveyObject, item: any, setItem: Dispatch<any>, field: CustomFormField }) => {

    const quickSelectOptions = [
        // "Yes, No, N/A",
        "Yes, No",
        "Pass, Fail",
        "Pass, Fail,Fail/Fixed",
        "Installed, Not Installed",
        "None, Yes"
    ]

    return (<>
        <Paper variant={"outlined"} sx={{padding: 2}}>
            <Stack direction={"row"} spacing={2}>
                {quickSelectOptions.map((option: string) => {
                    return (
                        <>
                            <Button type={"button"} variant={"contained"} onClick={() => {
                                let x = {...props.item}
                                x.options = option;
                                props.setItem(x);
                            }}>{option}</Button>
                        </>
                    );
                })}
            </Stack>

        </Paper>
    </>);
};
