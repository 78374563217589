import {ReportType} from "../../../types/enums";
import React from "react";
import {AuthContext} from "../../../Library/AuthContext";
import {Button} from "@mui/material";
import {ReportDataController} from "../../Reports/ReportDataController";

export function GenerateButton(props: { survey_id: string, reportType: ReportType, reportTypeLabel: string, updateListRequested: (() => void)  }) {
    const {
        userToken,
    } = React.useContext(AuthContext)!;

    const survey_id = props.survey_id;
    const reportType = props.reportType;
    const reportTypeLabel = props.reportTypeLabel;

    const [isActive, setIsActive] = React.useState<boolean>(false);

    return (<>

        <Button variant="outlined" color="error" fullWidth={true} disabled={isActive} onClick={() => {
            async function generate() {

                if (userToken === null || userToken === undefined) {
                    return;
                }
                setIsActive(true);
                await new ReportDataController(userToken).generate(survey_id, reportType);
                // let all = await new ReportDataController(userToken).getAll();
                // props.setList(all);

                props.updateListRequested();

                setIsActive(false);
            }

            generate().then();
        }}>
            Generate {reportTypeLabel}
        </Button>

    </>);

}
