import {ICondition, IQuickQuestionGroup, SurveyObject, SurveyObjectQuestion} from "../../../types/interfaces";
import {generateCode} from "../SurveyEditorView";

export function UpdateQuestionsToCreateFromBank(group: IQuickQuestionGroup, survey:  SurveyObject): SurveyObjectQuestion[] {
    let newParentItemQuestions: SurveyObjectQuestion[] = [];
    let newKeysMap: { [key: string] : string } = {};

    var existingKeys = survey.questions.map((question) => question.key);

    let objectQuestionKeys = new Map<string, string>();


    group.content.forEach((question) => {

        let code = generateCode();

        while (existingKeys.includes(code)) {
            code = generateCode();
        }

        existingKeys.push(code);

        console.log(`code: ${code} for ${JSON.stringify(question)}`);

        newKeysMap[question.key] = code;
        objectQuestionKeys.set(question.key, code);
    })

    console.log(`existingKeys: ${JSON.stringify(existingKeys)}`);

    console.log(`newKeysMap: ${JSON.stringify(newKeysMap)}`);

    group.content.forEach((question) => {

        let newQuestion = {...question, key: objectQuestionKeys.get(question.key) ?? question.key};
        newQuestion.conditions = newQuestion.conditions?.map((condition) => {
            let newCondition = {...condition};
            newCondition.key = objectQuestionKeys.get(newCondition.key) ?? newCondition.key;
            return newCondition;
        })

        // TODO: handle the new showInStandardReport and showInExceptionReport and required
        if (newQuestion.required !== undefined && newQuestion.required !== null) {
            if (Array.isArray(newQuestion.required)) {
                let conditions = newQuestion.required as ICondition[];
                conditions.forEach((condition) => {
                    condition.key = objectQuestionKeys.get(condition.key) ?? condition.key;
                })
            }
            else if (typeof newQuestion.required === "object") {
                let condition = newQuestion.required as ICondition;
                condition.key = objectQuestionKeys.get(condition.key) ?? condition.key;
            }
            else {
                // boolean
            }
        }

        if (newQuestion.showInStandardReport !== undefined && newQuestion.showInStandardReport !== null) {
            if (Array.isArray(newQuestion.showInStandardReport)) {
                let conditions = newQuestion.showInStandardReport as ICondition[];
                conditions.forEach((condition) => {
                    condition.key = objectQuestionKeys.get(condition.key) ?? condition.key;
                })
            }
            else if (typeof newQuestion.showInStandardReport === "object") {
                let condition = newQuestion.showInStandardReport as ICondition;
                condition.key = objectQuestionKeys.get(condition.key) ?? condition.key;
            }
            else {
                // boolean
            }
        }

        if (newQuestion.showInExceptionReport !== undefined && newQuestion.showInExceptionReport !== null) {
            if (Array.isArray(newQuestion.showInExceptionReport)) {
                let conditions = newQuestion.showInExceptionReport as ICondition[];
                conditions.forEach((condition) => {
                    condition.key = objectQuestionKeys.get(condition.key) ?? condition.key;
                })
            }
            else if (typeof newQuestion.showInExceptionReport === "object") {
                let condition = newQuestion.showInExceptionReport as ICondition;
                condition.key = objectQuestionKeys.get(condition.key) ?? condition.key;
            }
            else {
                // boolean
            }
        }

        if (newQuestion.showInExceptionCount !== undefined && newQuestion.showInExceptionCount !== null) {
            if (Array.isArray(newQuestion.showInExceptionCount)) {
                let conditions = newQuestion.showInExceptionCount as ICondition[];
                conditions.forEach((condition) => {
                    condition.key = objectQuestionKeys.get(condition.key) ?? condition.key;
                })
            }
            else if (typeof newQuestion.showInExceptionCount === "object") {
                let condition = newQuestion.showInExceptionCount as ICondition;
                condition.key = objectQuestionKeys.get(condition.key) ?? condition.key;
            }
            else {
                // boolean
            }
        }

        // return newQuestion;



        // let updatedQuestion = {...question };
        //
        // let newQuestionKey = newKeysMap[question.key];
        // if (newQuestionKey === undefined) {
        //     alert(`newKey: ${newQuestionKey} for ${question.key}`)
        // }
        // updatedQuestion.key = newQuestionKey;
        // ;
        //
        // updatedQuestion.conditions = updatedQuestion.conditions?.map((condition) => {
        //     let updatedCondition = {...condition};
        //     let newKey = newKeysMap[updatedCondition.key];
        //     if (newKey === undefined) {
        //         alert(`newKey: ${newKey} for ${updatedCondition.key}`)
        //     }
        //     updatedCondition.key = newKey;
        //     // updatedCondition.key = newKeysMap.get(updatedCondition.key) ?? updatedCondition.key;
        //     return updatedCondition;
        // })
        // // this theoretically should remove unused keys.
        // // TODO: test without this line
        // // .filter((condition) => group.content.map((question) => question.key).includes(condition.key));
        //
        //
        //
        // if (updatedQuestion.required !== undefined && updatedQuestion.required !== null) {
        //     if (typeof updatedQuestion.required === 'boolean') {
        //         // Handle the boolean case
        //         // Example: console.log(`booleanCondition: ${updatedQuestion.required}`);
        //     } else if (updatedQuestion.required && 'key' in updatedQuestion.required) {
        //         // Assuming ICondition has a 'key' property and it's an object
        //         let singleCondition: ICondition = {...updatedQuestion.required};
        //
        //         let newKey = newKeysMap[singleCondition.key];
        //         if (newKey === undefined) {
        //             alert(`newKey: ${newKey} for ${singleCondition.key}`)
        //         }
        //         singleCondition.key = newKey;
        //
        //         // singleCondition.key = newKeysMap[singleCondition.key] ?? singleCondition.key;
        //         updatedQuestion.required = singleCondition;
        //     } else if (Array.isArray(updatedQuestion.required)) {
        //         // Handle the case when it's an array of ICondition
        //         let multipleConditions: ICondition[] = updatedQuestion.required;
        //         multipleConditions.forEach((condition) => {
        //             condition.key = newKeysMap[condition.key] ?? condition.key;
        //         });
        //         updatedQuestion.required = multipleConditions;
        //     }
        //
        // }
        //
        //
        // if (updatedQuestion.showInStandardReport !== undefined && updatedQuestion.showInStandardReport !== null) {
        //     if (typeof updatedQuestion.showInStandardReport === 'boolean') {
        //         // Handle the boolean case
        //         // Example: console.log(`booleanCondition: ${updatedQuestion.showInStandardReport}`);
        //     } else if (updatedQuestion.showInStandardReport && 'key' in updatedQuestion.showInStandardReport) {
        //         // Assuming ICondition has a 'key' property and it's an object
        //         let singleCondition: ICondition = {...updatedQuestion.showInStandardReport};
        //         let newKey = newKeysMap[singleCondition.key];
        //         if (newKey === undefined) {
        //             alert(`newKey: ${newKey} for ${singleCondition.key}`)
        //         }
        //         singleCondition.key = newKey;
        //         // singleCondition.key = newKeysMap[singleCondition.key] ?? singleCondition.key;
        //         updatedQuestion.showInStandardReport = singleCondition;
        //     } else if (Array.isArray(updatedQuestion.showInStandardReport)) {
        //         // Handle the case when it's an array of ICondition
        //         let multipleConditions: ICondition[] = updatedQuestion.showInStandardReport;
        //         multipleConditions.forEach((condition) => {
        //             condition.key = newKeysMap[condition.key] ?? condition.key;
        //         });
        //         updatedQuestion.showInStandardReport = multipleConditions;
        //     }
        //
        //
        // }
        //
        // if (updatedQuestion.showInExceptionReport !== undefined && updatedQuestion.showInExceptionReport !== null) {
        //     if (typeof updatedQuestion.showInExceptionReport === 'boolean') {
        //         console.log(`booleanCondition: ${JSON.stringify(updatedQuestion.showInExceptionReport)}`);
        //     } else if (updatedQuestion.showInExceptionReport && 'key' in updatedQuestion.showInExceptionReport) {
        //         // Assuming ICondition has a 'key' property
        //         let singleCondition = {...updatedQuestion.showInExceptionReport};
        //         console.log(`singleCondition: ${JSON.stringify(singleCondition)}`);
        //         let newKey = newKeysMap[singleCondition.key];
        //         if (newKey === undefined) {
        //             alert(`newKey: ${newKey} for ${singleCondition.key}`)
        //         }
        //         singleCondition.key = newKey;
        //         // singleCondition.key = newKeysMap[singleCondition.key] ?? singleCondition.key;
        //         updatedQuestion.showInExceptionReport = singleCondition;
        //     } else if (Array.isArray(updatedQuestion.showInExceptionReport)) {
        //         let multipleConditions = updatedQuestion.showInExceptionReport;
        //         console.log(`multipleConditions: ${JSON.stringify(multipleConditions)}`);
        //         multipleConditions.forEach((condition) => {
        //             if ('key' in condition) {
        //                 condition.key = newKeysMap[condition.key] ?? condition.key;
        //             }
        //         });
        //         updatedQuestion.showInExceptionReport = multipleConditions;
        //     }
        // }
        //
        // if (updatedQuestion.showInExceptionCount !== undefined && updatedQuestion.showInExceptionCount !== null) {
        //     if (typeof updatedQuestion.showInExceptionCount === 'boolean') {
        //         console.log(`booleanCondition: ${JSON.stringify(updatedQuestion.showInExceptionCount)}`);
        //     } else if (updatedQuestion.showInExceptionCount && 'key' in updatedQuestion.showInExceptionCount) {
        //         // Assuming ICondition has a 'key' property
        //         let singleCondition = {...updatedQuestion.showInExceptionCount};
        //         console.log(`singleCondition: ${JSON.stringify(singleCondition)}`);
        //         let newKey = newKeysMap[singleCondition.key];
        //         if (newKey === undefined) {
        //             alert(`newKey: ${newKey} for ${singleCondition.key}`)
        //         }
        //         singleCondition.key = newKey;
        //         // singleCondition.key = newKeysMap[singleCondition.key] ?? singleCondition.key;
        //         updatedQuestion.showInExceptionCount = singleCondition;
        //     } else if (Array.isArray(updatedQuestion.showInExceptionCount)) {
        //         let multipleConditions = updatedQuestion.showInExceptionCount;
        //         console.log(`multipleConditions: ${JSON.stringify(multipleConditions)}`);
        //         multipleConditions.forEach((condition) => {
        //             if ('key' in condition) {
        //                 condition.key = newKeysMap[condition.key] ?? condition.key;
        //             }
        //         });
        //         updatedQuestion.showInExceptionCount = multipleConditions;
        //     }
        // }

        newParentItemQuestions.push(newQuestion);
    })

    return newParentItemQuestions;
}

// export function UpdateQuestionsToCreateFromBank(group: IQuickQuestionGroup, survey:  SurveyObject): SurveyObjectQuestion[] {
//     let newParentItemQuestions: SurveyObjectQuestion[] = [];
//     let newKeysMap: { [key: string] : string } = {};
//
//     var existingKeys = survey.questions.map((question) => question.key);
//
//
//
//     group.content.forEach((question) => {
//
//         let code = generateCode();
//
//         while (existingKeys.includes(code)) {
//             code = generateCode();
//         }
//
//         existingKeys.push(code);
//
//         console.log(`code: ${code} for ${JSON.stringify(question)}`);
//
//         newKeysMap[question.key] = code;
//     })
//
//     console.log(`existingKeys: ${JSON.stringify(existingKeys)}`);
//
//     console.log(`newKeysMap: ${JSON.stringify(newKeysMap)}`);
//
//     group.content.forEach((question) => {
//         let updatedQuestion = {...question };
//
//         let newQuestionKey = newKeysMap[question.key];
//         if (newQuestionKey === undefined) {
//             alert(`newKey: ${newQuestionKey} for ${question.key}`)
//         }
//         updatedQuestion.key = newQuestionKey;
//         ;
//
//         updatedQuestion.conditions = updatedQuestion.conditions?.map((condition) => {
//             let updatedCondition = {...condition};
//             let newKey = newKeysMap[updatedCondition.key];
//             if (newKey === undefined) {
//                 alert(`newKey: ${newKey} for ${updatedCondition.key}`)
//             }
//             updatedCondition.key = newKey;
//             // updatedCondition.key = newKeysMap.get(updatedCondition.key) ?? updatedCondition.key;
//             return updatedCondition;
//         })
//         // this theoretically should remove unused keys.
//         // TODO: test without this line
//         // .filter((condition) => group.content.map((question) => question.key).includes(condition.key));
//
//
//
//         if (updatedQuestion.required !== undefined && updatedQuestion.required !== null) {
//             if (typeof updatedQuestion.required === 'boolean') {
//                 // Handle the boolean case
//                 // Example: console.log(`booleanCondition: ${updatedQuestion.required}`);
//             } else if (updatedQuestion.required && 'key' in updatedQuestion.required) {
//                 // Assuming ICondition has a 'key' property and it's an object
//                 let singleCondition: ICondition = {...updatedQuestion.required};
//
//                 let newKey = newKeysMap[singleCondition.key];
//                 if (newKey === undefined) {
//                     alert(`newKey: ${newKey} for ${singleCondition.key}`)
//                 }
//                 singleCondition.key = newKey;
//
//                 // singleCondition.key = newKeysMap[singleCondition.key] ?? singleCondition.key;
//                 updatedQuestion.required = singleCondition;
//             } else if (Array.isArray(updatedQuestion.required)) {
//                 // Handle the case when it's an array of ICondition
//                 let multipleConditions: ICondition[] = updatedQuestion.required;
//                 multipleConditions.forEach((condition) => {
//                     condition.key = newKeysMap[condition.key] ?? condition.key;
//                 });
//                 updatedQuestion.required = multipleConditions;
//             }
//
//         }
//
//
//         if (updatedQuestion.showInStandardReport !== undefined && updatedQuestion.showInStandardReport !== null) {
//             if (typeof updatedQuestion.showInStandardReport === 'boolean') {
//                 // Handle the boolean case
//                 // Example: console.log(`booleanCondition: ${updatedQuestion.showInStandardReport}`);
//             } else if (updatedQuestion.showInStandardReport && 'key' in updatedQuestion.showInStandardReport) {
//                 // Assuming ICondition has a 'key' property and it's an object
//                 let singleCondition: ICondition = {...updatedQuestion.showInStandardReport};
//                 let newKey = newKeysMap[singleCondition.key];
//                 if (newKey === undefined) {
//                     alert(`newKey: ${newKey} for ${singleCondition.key}`)
//                 }
//                 singleCondition.key = newKey;
//                 // singleCondition.key = newKeysMap[singleCondition.key] ?? singleCondition.key;
//                 updatedQuestion.showInStandardReport = singleCondition;
//             } else if (Array.isArray(updatedQuestion.showInStandardReport)) {
//                 // Handle the case when it's an array of ICondition
//                 let multipleConditions: ICondition[] = updatedQuestion.showInStandardReport;
//                 multipleConditions.forEach((condition) => {
//                     condition.key = newKeysMap[condition.key] ?? condition.key;
//                 });
//                 updatedQuestion.showInStandardReport = multipleConditions;
//             }
//
//
//         }
//
//         if (updatedQuestion.showInExceptionReport !== undefined && updatedQuestion.showInExceptionReport !== null) {
//             if (typeof updatedQuestion.showInExceptionReport === 'boolean') {
//                 console.log(`booleanCondition: ${JSON.stringify(updatedQuestion.showInExceptionReport)}`);
//             } else if (updatedQuestion.showInExceptionReport && 'key' in updatedQuestion.showInExceptionReport) {
//                 // Assuming ICondition has a 'key' property
//                 let singleCondition = {...updatedQuestion.showInExceptionReport};
//                 console.log(`singleCondition: ${JSON.stringify(singleCondition)}`);
//                 let newKey = newKeysMap[singleCondition.key];
//                 if (newKey === undefined) {
//                     alert(`newKey: ${newKey} for ${singleCondition.key}`)
//                 }
//                 singleCondition.key = newKey;
//                 // singleCondition.key = newKeysMap[singleCondition.key] ?? singleCondition.key;
//                 updatedQuestion.showInExceptionReport = singleCondition;
//             } else if (Array.isArray(updatedQuestion.showInExceptionReport)) {
//                 let multipleConditions = updatedQuestion.showInExceptionReport;
//                 console.log(`multipleConditions: ${JSON.stringify(multipleConditions)}`);
//                 multipleConditions.forEach((condition) => {
//                     if ('key' in condition) {
//                         condition.key = newKeysMap[condition.key] ?? condition.key;
//                     }
//                 });
//                 updatedQuestion.showInExceptionReport = multipleConditions;
//             }
//         }
//
//         if (updatedQuestion.showInExceptionCount !== undefined && updatedQuestion.showInExceptionCount !== null) {
//             if (typeof updatedQuestion.showInExceptionCount === 'boolean') {
//                 console.log(`booleanCondition: ${JSON.stringify(updatedQuestion.showInExceptionCount)}`);
//             } else if (updatedQuestion.showInExceptionCount && 'key' in updatedQuestion.showInExceptionCount) {
//                 // Assuming ICondition has a 'key' property
//                 let singleCondition = {...updatedQuestion.showInExceptionCount};
//                 console.log(`singleCondition: ${JSON.stringify(singleCondition)}`);
//                 let newKey = newKeysMap[singleCondition.key];
//                 if (newKey === undefined) {
//                     alert(`newKey: ${newKey} for ${singleCondition.key}`)
//                 }
//                 singleCondition.key = newKey;
//                 // singleCondition.key = newKeysMap[singleCondition.key] ?? singleCondition.key;
//                 updatedQuestion.showInExceptionCount = singleCondition;
//             } else if (Array.isArray(updatedQuestion.showInExceptionCount)) {
//                 let multipleConditions = updatedQuestion.showInExceptionCount;
//                 console.log(`multipleConditions: ${JSON.stringify(multipleConditions)}`);
//                 multipleConditions.forEach((condition) => {
//                     if ('key' in condition) {
//                         condition.key = newKeysMap[condition.key] ?? condition.key;
//                     }
//                 });
//                 updatedQuestion.showInExceptionCount = multipleConditions;
//             }
//         }
//
//         newParentItemQuestions.push(updatedQuestion);
//     })
//
//     return newParentItemQuestions;
// }
