import {ISurveyAnswer} from "../../../types/interfaces";
import {GridCellParams} from "@mui/x-data-grid";
import React from "react";
import {Button} from "@mui/material";
import {ReportDataController} from "../../Reports/ReportDataController";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";

export function RenderCellDeleteReportButton({userToken, params, rowData, onRemove}: { userToken: string | undefined | null, rowData: ISurveyAnswer, onRemove: any, params: GridCellParams}) {
    const [isDeleting, setIsDeleting] = React.useState<boolean>(false);
    return (<>
        <Button variant="outlined" color="error" fullWidth={true} disabled={isDeleting} onClick={() => {
            // alert(params.row._id);
            async function removeRow() {
                if (userToken === null || userToken === undefined) {
                    return;
                }

                // rowData = row as ISurveyAnswer;
                console.log(rowData)
                rowData.reports = rowData.reports.filter((obj) => obj.key !== params.row.key);
                console.log(rowData)

                await new ReportDataController(userToken).deleteReport(params.row.bucket, params.row.key);

                await new ReportDataController(userToken).update(rowData._id, rowData);

                onRemove();

                // let all = await new ReportDataController(userToken).getAll();
                // setList(all);
            }

            if (window.confirm("Are you sure you want to delete this survey?")){
                setIsDeleting(true)
                removeRow().then(() => setIsDeleting(false));
            }
        }}>
            <FontAwesomeIcon icon={faTrash} />
        </Button>
    </>)
}
