import {Stack, Switch, TextField} from '@mui/material';
import React from 'react';
import AuthenticatedLayout, {AuthenticatedLayoutCustomButton} from "../../Library/AuthenticatedLayout";
import {GridRowId, GridRowParams, GridValueGetterParams} from "@mui/x-data-grid";
import {
    Clone, CustomFormField,
    ISurvey,
    ISurveyAnswer,
    SurveyObject,
    SurveyObjectQuestion,
} from "../../types/interfaces";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {useParams} from "react-router-dom";
import {ReportDataController} from "../Reports/ReportDataController";
import {AuthContext} from "../../Library/AuthContext";
import {SurveyDataController} from "../Surveys/SurveyDataController";
import {SurveyObjectEdit} from "./Components/SurveyAnswerEdit";
import {AllConditionPassOnQuestion} from "../../utils/additional";
import {EditObject} from "../../Shared/Components/EditObject";
import {CustomFormFieldSizes, CustomFormFieldTypes} from "../../types/enums";
import {ConditionFlagsPassthru, SurveyDateTimePassthru} from "../Surveys/SurveyEditorView";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRotateRight} from "@fortawesome/free-solid-svg-icons";
import {faObjectGroup, faObjectUngroup} from "@fortawesome/free-regular-svg-icons";

// export type exportType = SurveyObject & { questions: SurveyObjectQuestion & { answer: any }[] };

/*

you have to have the s3 bucket configured with cors.

[
    {
        "AllowedHeaders": [
            "*"
        ],
        "AllowedMethods": [
            "PUT",
            "POST",
            "DELETE",
            "GET",
            "HEAD"
        ],
        "AllowedOrigins": [
            "*"
        ],
        "ExposeHeaders": [
            "x-amz-server-side-encryption",
            "x-amz-request-id",
            "x-amz-id-2"
        ],
        "MaxAgeSeconds": 3000
    }
]

 */





function DetailViewBuilder(surveyAnswer: ISurveyAnswer, setSurveyAnswer: React.Dispatch<React.SetStateAction<ISurveyAnswer | undefined>>) {

    return function SurveyAnswerDetailView(row: any) {
        return (<>
            <div style={{padding: '0.5rem'}}>


                <div style={{ padding: '0.5rem' }}>
                    <TextField disabled={true}
                        fullWidth={true}
                        label="Label"
                        value={(surveyAnswer.answers[row.key] ?? {}).label || ""}
                        onChange={(e) => {
                            if (surveyAnswer === undefined) { return; }
                            let updatedSurveyAnswer = {...surveyAnswer};
                            if (updatedSurveyAnswer.answers[row.key] === undefined) {
                                updatedSurveyAnswer.answers[row.key] = {
                                    label: "",
                                    values: {}
                                }
                            }
                            updatedSurveyAnswer.answers[row.key].label = e.target.value;
                            setSurveyAnswer(updatedSurveyAnswer);
                        }}

                    />
                </div>

                {/* TODO: add the parent drop down here. */}

                { row.isConditional &&
                    (<div style={{ padding: '0.5rem' }}>
                        <Switch
                            // disabled={surveyAnswer.isCompleted}
                            checked={(surveyAnswer.answers[row.key] ?? {
                                values: {},
                                conditionalExists : false
                            }).conditionalExists || false}
                            onChange={(e) => {
                                if (surveyAnswer === undefined) { return; }
                                let updatedSurveyAnswer = {...surveyAnswer};
                                if (updatedSurveyAnswer.answers[row.key] === undefined) {
                                    updatedSurveyAnswer.answers[row.key] = {
                                        values: {},
                                        conditionalExists : false
                                    }
                                }
                                updatedSurveyAnswer.answers[row.key].conditionalExists = e.target.checked;
                                setSurveyAnswer(updatedSurveyAnswer);
                            }}
                            disabled={true}
                        />
                        <label>Installed</label>
                    </div>)
                }
                <SurveyObjectEdit surveyAnswer={surveyAnswer} setSurveyAnswer={setSurveyAnswer} row={row} disabled={true}  />
            </div>
        </>);

    }

}

function ReadonlySurveyAnswerView() {

    const {
        // user,
        userToken
    } = React.useContext(AuthContext)!;

    const { id } = useParams<{ id: string }>(); // answer id // so how do we do this if the person needs to do the whole survey on the web.

    const [surveyAnswer, setSurveyAnswer] = React.useState<ISurveyAnswer | undefined>(undefined);
    const [survey, setSurvey] = React.useState<ISurvey | undefined>(undefined);

    const loadData = React.useCallback(async () => {
        if (userToken === null || userToken === undefined) {
            return;
        }
        if (id === undefined) {
            return;
        }
        let answer = await new ReportDataController(userToken).getOneByAnswerId(id);
        setSurveyAnswer(answer);

        let survey = await new SurveyDataController(userToken).getOne(answer.survey_id);
        setSurvey(survey);
    }, [userToken, id, setSurveyAnswer, setSurvey]); // Dependencies array


    React.useEffect(() => {
        loadData().then();
    }, [id, loadData]);

    const fields = React.useMemo(
        () => {
            let fields: CustomFormField[] = [
                {
                    kind: CustomFormFieldTypes.TEXT,
                    size: CustomFormFieldSizes.SMALL,
                    key: "caseNumber",
                    label: "Case Number"
                },
                {
                    kind: CustomFormFieldTypes.TEXT,
                    size: CustomFormFieldSizes.SMALL,
                    key: "surveyName",
                    label: "Survey Name"
                },
                {
                    kind: CustomFormFieldTypes.TEXT,
                    size: CustomFormFieldSizes.SMALL,
                    key: "customerTicketNumber",
                    label: "Customer Ticket Number"
                },
                {
                    kind: CustomFormFieldTypes.TEXT,
                    size: CustomFormFieldSizes.TWO,
                    key: "siteContactName",
                    label: "Site Contact Name"
                },

                {
                    kind: CustomFormFieldTypes.TEXT,
                    size: CustomFormFieldSizes.TWO,
                    key: "siteContactPhone",
                    label: "Site Contact Phone"
                },

                {
                    kind: CustomFormFieldTypes.TEXT,
                    size: CustomFormFieldSizes.TWO,
                    key: "siteContactEmail",
                    label: "Site Contact Email"
                },
                {
                    kind: CustomFormFieldTypes.TEXT,
                    size: CustomFormFieldSizes.SMALL,
                    key: "locationName",
                    label: "Location Name"
                },
                {
                    kind: CustomFormFieldTypes.TEXT,
                    size: CustomFormFieldSizes.MEDIUM,
                    key: "address",
                    label: "Site Address"
                },
                {
                    kind: CustomFormFieldTypes.PASSTHRU,
                    size: CustomFormFieldSizes.THREE,
                    key: "surveyDate",
                    label: "Survey Date",
                    passthru: SurveyDateTimePassthru,
                    passthruProps: { survey: survey, setSurvey: setSurvey }
                },
                {
                    kind: CustomFormFieldTypes.PASSTHRU,
                    size: CustomFormFieldSizes.ONE,
                    key: "conditionFlags",
                    label: "Condition Flags",
                    passthru: ConditionFlagsPassthru,
                    passthruProps: { survey: survey, setSurvey: setSurvey }
                },
            ];


            // TODO: add question instructions (possibly a toggle for it)
            // TODO: also add option for default value

            return fields.map((field: CustomFormField) => {
                return {
                    ...field,
                    readonly: true
                }
            });
        },
        [setSurvey, survey] // survey?.address, survey?.surveyDate,
    );

    const [headerExpanded, setHeaderExpanded] = React.useState<boolean>(true);

    const getDetailPanelContent = React.useCallback(
        ({ row }: GridRowParams) => {
            console.log("getDetailPanelContent called")
            if (surveyAnswer === undefined || survey === undefined) { return (<></>); }
            else {
                return DetailViewBuilder(surveyAnswer, setSurveyAnswer)(row)
            }

        },
        [survey, surveyAnswer],
    );

    const SurveyObjectsMemo = React.useMemo(() => {

        let objects: any[] = [];

        if (survey === undefined) {
            console.log("survey undefined")
            return [];
        }
        if (surveyAnswer === undefined) {
            console.log("surveyAnswer undefined")
            return [];
        } // TODO: change this!
        if (surveyAnswer.answers === undefined) {
            console.log("surveyAnswer.answers undefined")
            return [];
        }

        let surveyObjects = survey.content.map((surveyObject: SurveyObject) => {
            function test(object: SurveyObject) {
                if (object.isConditional === undefined || !object.isConditional) {
                    return true;
                }
                if (surveyAnswer === undefined) {
                    return false;
                }
                let objectAnswers = surveyAnswer.answers[object.key];
                if (objectAnswers === undefined) {
                    return false; // does objects that are conditional but have no answer show up
                }

                return objectAnswers.conditionalExists === true;
            }

            if (test(surveyObject)) {
                return surveyObject;
            }
            else {
                return {
                    key: surveyObject.key,
                    location: surveyObject.location,
                    isConditional: surveyObject.isConditional,
                    parent: surveyObject.parent,
                    questions: []
                } as SurveyObject;
            }



        });

        surveyObjects.forEach((surveyObject: SurveyObject) => {
            let filteredQuestions = surveyObject.questions.filter((question: SurveyObjectQuestion) => {
                if (survey.conditionFlags !== undefined && survey.conditionFlags !== null) {

                    if (question.requiredConditionFlags !== undefined && question.requiredConditionFlags !== null && Object.keys(question.requiredConditionFlags).length > 0) {
                        let filteredFlags = question.requiredConditionFlags.filter((flag: string) => {
                            if (survey.conditionFlags !== undefined) { // not sure why I need to check this again!!!!!!!!!!!!!
                                return survey.conditionFlags[flag];
                            }
                            return false;
                        })

                        if (filteredFlags.length !== Object.keys(question.requiredConditionFlags).length) {
                            return false;
                        }
                    }
                }
                return AllConditionPassOnQuestion(question, surveyAnswer.answers[surveyObject.key] ?? { values: {}, clones: {} });
            });

            let questions = filteredQuestions.map((question: SurveyObjectQuestion) => {

                let objAnswer = surveyAnswer.answers[surveyObject.key];
                if (objAnswer === undefined || objAnswer.values === undefined) {
                    return {
                        ...question,
                        answer: ""
                    }
                }

                return {
                    ...question,
                    answer: objAnswer.values[question.key] ?? {}
                }
            })




            objects.push({
                ...surveyObject,
                questions: questions
            })

            if (surveyAnswer.clones === undefined) {
                return;
            }

            let clone = surveyAnswer.clones[surveyObject.key];
            if (clone) {
                clone.forEach((clone: Clone) => {
                    objects.push({
                        ...surveyObject,
                        key: clone.key,
                        questions: surveyObject.questions.map((question: SurveyObjectQuestion) => {


                            let objAnswer = surveyAnswer.answers[clone.key];
                            if (objAnswer === undefined) {
                                return {
                                    ...question,
                                    answer: ""
                                }
                            }

                            if (objAnswer.values === undefined) {
                                return {
                                    ...question,
                                    answer: ""
                                }
                            }



                            let answer = objAnswer.values[question.key];
                            if (answer === undefined) {
                                return {
                                    ...question,
                                    answer: ""
                                }
                            }

                            return {
                                ...question,
                                answer: objAnswer.values[question.key] ?? {}
                            }
                        })
                        , isClone: true,
                        parentKey: surveyObject.key
                    })
                })
            }
        })


        // TODO: handle the clone.

        // TODO: merge the answers into the questions.

        return objects;
    }, [surveyAnswer, survey]);

    // these buttons didn't allow the save to work properly.?????
    const customButtonsMemo = React.useMemo<AuthenticatedLayoutCustomButton[]>(() => {
        let buttons: AuthenticatedLayoutCustomButton[] = [];

        // Expand button
        buttons.push({
            icon:  <FontAwesomeIcon icon={headerExpanded ? faObjectUngroup : faObjectGroup} />,
            label: (headerExpanded ? "Collapse" : "Expand"),
            action: () => {
                setHeaderExpanded(!headerExpanded)
            }
        });

        buttons.push({
            label: `Reload`,
            action: () => {
                loadData().then();
            },
            icon: <FontAwesomeIcon icon={faRotateRight} />
        });


        return buttons;

    }, [ headerExpanded, loadData ]);

    // This forces it to have only one expanded row at a time.
    const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = React.useState<
        GridRowId[]
    >([]);

    const handleDetailPanelExpandedRowIdsChange = React.useCallback(
        (newIds: GridRowId[]) => {
            setDetailPanelExpandedRowIds(
                newIds.length > 1 ? [newIds[newIds.length - 1]] : newIds,
            );
        },
        [],
    );
    // end of forcing only one expanded row at a time.


    return (<>
        <AuthenticatedLayout  pageTitle={`Surveys Answers - ${id?.trim()} - ${surveyAnswer === undefined ? '' : surveyAnswer.updatedAt}`}
            customButtons={customButtonsMemo}
        >

            <Stack sx={{ height: '100%' }}>
                {headerExpanded && (
                    <EditObject item={survey} setItem={setSurvey} form={fields} columns={12}></EditObject>
                )}

                <DataGridPro
                    style={{ height: 'calc( 100% - 3.5rem )', width: '100%' }}
                    columns={[
                        { field: 'key', headerName: 'Key', width: 200 },
                        { field: 'location', headerName: 'Object Name', width: 200 },
                        { field: 'isConditional', headerName: 'Is Conditional', width: 130 },
                        { field: 'parent', headerName: 'Parent', width: 130 },

                        { field: 'count', headerName: "Questions Count", width: 150, valueGetter: (params: GridValueGetterParams) => {
                            let obj = params.row as SurveyObject;
                            return obj.questions.filter((q) => q.type.type !== 'instruction').length;
                        } },
                        { field: 'answeredCount', headerName: "Answered Count", width: 150, valueGetter: (params: GridValueGetterParams) => {
                            let obj = params.row as SurveyObject;
                            let objectAnswers = surveyAnswer?.answers[obj.key] ?? { values: {} };
                            // TODO: filter based on the actual answer type.
                            return obj.questions.filter((q) => {
                                if (q.type.type === 'instruction') {
                                    return false;
                                }
                                if (objectAnswers === undefined || objectAnswers.values === undefined || objectAnswers.values === null) {
                                    return false;
                                }
                                if (objectAnswers.values[q.key] === undefined) {
                                    return false;
                                }
                                if (objectAnswers.values[q.key] === null) {
                                    return false;
                                }
                                return true;
                                // (objectAnswers.values[q.key] !== undefined) && q.type.type !== 'instruction'
                            // ).
                            //     length
                            }).length;
                        } },
                    ]}
                    rows={SurveyObjectsMemo}
                    rowThreshold={0}
                    getRowId={(row) => row.key || row.location}
                    getDetailPanelHeight={(row) => {
                        let questionCount = (row.row as SurveyObject).questions.length;
                        if (questionCount === 0) {
                            return 275;
                        }
                        // 350

                        let height: number = 120;
                        height += 65; // for the label
                        height += 65; // for the conditional
                        (row.row as SurveyObject).questions.forEach((question: SurveyObjectQuestion) => {
                            if (question.type.type === "photo"){
                                height += 445;
                            }
                            else {
                                height += 75;
                            }
                        });
                        return height;
                    }}
                    getDetailPanelContent={getDetailPanelContent}
                    detailPanelExpandedRowIds={detailPanelExpandedRowIds}
                    onDetailPanelExpandedRowIdsChange={handleDetailPanelExpandedRowIdsChange}
                    disableRowSelectionOnClick
                />

            </Stack>
        </AuthenticatedLayout>
    </>);
}

export default ReadonlySurveyAnswerView;
