import {CustomFormField, ISurvey, SiteContactPhone} from "../../../types/interfaces";
import React from "react";
import {
    Box,
    Button, IconButton,
    List,
    ListItem, ListItemIcon,
    ListItemText,
    ListSubheader,
    Modal,
    Paper,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

export const SiteContactPhonePassThru = (props : { survey: ISurvey, setSurvey: (survey: ISurvey) => void, field: CustomFormField }) => {

    const [open, setOpen] = React.useState<boolean>(false);
    const [newPhoneNumber, setNewPhoneNumber] = React.useState<string>("");
    const [newPhoneNumberLabel, setNewPhoneNumberLabel] = React.useState<string>("");

    const phoneNumberCount = React.useMemo(() => {
        if (props.survey.siteContactPhone === null || props.survey.siteContactPhone === undefined) {
            return 0;
        }
        if (Array.isArray(props.survey.siteContactPhone)) {
            return props.survey.siteContactPhone.length;
        }
        return 1;
    }, [props.survey.siteContactPhone])

    return (<>
        <Button type={"button"} variant={"outlined"} size={"large"} fullWidth={true} onClick={() => {
            setOpen(true)
        }}>Site Contact Phone Number ({phoneNumberCount})</Button>
        <Modal open={open} onClose={() => {
            setOpen(false);
        }}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '600px',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                }}
            >
                <Typography id="modal-modal-title" variant="h5" component="h2" sx={{ mb: 2, textAlign: 'center' }}>
                    Contact Phone ({phoneNumberCount})
                </Typography>

                <Stack direction={'column'} spacing={2}>


                    {!Array.isArray(props.survey.siteContactPhone) && (
                        <>
                            <Typography variant="body2" style={{ textAlign: 'center', opacity: '0.7' }}>
                                <TextField
                                    sx={{ minWidth: '200px' }}
                                    fullWidth={true}
                                    type={"text"}
                                    label={"Phone Number"}
                                    value={props.survey.siteContactPhone as string}
                                    onChange={(e) => {
                                        let newItem = { ...props.survey };
                                        newItem.siteContactPhone = e.target.value;
                                        props.setSurvey(newItem);
                                    }}
                                    disabled={props.field.readonly || Array.isArray(props.survey.siteContactPhone)}
                                ></TextField>
                            </Typography>
                        </>
                    )}

                    {props.survey.siteContactPhone !== undefined && Array.isArray(props.survey.siteContactPhone) && (
                        <>
                            <Paper elevation={1}>
                                <List
                                    sx={{ width: '100%', bgcolor: 'background.paper' }}
                                    subheader={<ListSubheader>Contact Phone Numbers</ListSubheader>}
                                >
                                    {(props.survey.siteContactPhone as SiteContactPhone[]).map((item) => {
                                        return (<>
                                            <ListItem>
                                                <ListItemText id="switch-list-label-wifi" primary={item.label} secondary={`${item.number}`} />

                                                <ListItemIcon>
                                                    <IconButton edge="end" aria-label="delete" disabled={props.field.readonly} onClick={() => {
                                                        // prompt to delete
                                                        if(!window.confirm(`Are you sure you want to delete ${item.label}?`)) {
                                                            return;
                                                        }

                                                        let newItem = { ...props.survey };

                                                        if (Array.isArray(newItem.siteContactPhone) === false || newItem.siteContactPhone === null || newItem.siteContactPhone === undefined) {
                                                            newItem.siteContactPhone = [] as SiteContactPhone[];
                                                        }
                                                        newItem.siteContactPhone = (newItem.siteContactPhone as SiteContactPhone[]).filter((phone) => !(phone.label === item.label && phone.number === item.number));
                                                        props.setSurvey(newItem);
                                                    }}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </ListItemIcon>
                                            </ListItem>
                                        </>)
                                    })}
                                </List>
                            </Paper>
                        </>
                    )}

                    <Paper elevation={1}>

                        <Stack direction={"row"} spacing={1} sx={{padding: 2}}>
                            <TextField
                                sx={{ minWidth: '200px' }}
                                fullWidth={true}
                                type={"text"}
                                label={"Label"}
                                value={newPhoneNumberLabel}
                                onChange={(e) => {
                                    setNewPhoneNumberLabel(e.target.value);
                                }}
                                disabled={props.field.readonly}
                            ></TextField>
                            <TextField
                                sx={{ minWidth: '200px' }}
                                fullWidth={true}
                                type={"text"}
                                label={"Phone Number"}
                                value={newPhoneNumber}
                                onChange={(e) => {
                                    setNewPhoneNumber(e.target.value);
                                }}
                                disabled={props.field.readonly}
                            ></TextField>
                            <Button type={"button"} variant={"outlined"} size={"large"} disabled={newPhoneNumber === "" || newPhoneNumberLabel === "" || props.field.readonly} fullWidth={true} onClick={() => {
                                let newItem = { ...props.survey };



                                if (Array.isArray(newItem.siteContactPhone) === false || newItem.siteContactPhone === null || newItem.siteContactPhone === undefined) {
                                    newItem.siteContactPhone = [] as SiteContactPhone[];
                                }
                                (newItem.siteContactPhone as SiteContactPhone[]).push({label: newPhoneNumberLabel, number: newPhoneNumber});
                                props.setSurvey(newItem);
                                setNewPhoneNumber("");
                                setNewPhoneNumberLabel("");
                            }}>Create</Button>
                        </Stack>
                    </Paper>
                </Stack>



            </Box>
        </Modal>
    </>);
}
