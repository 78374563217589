import {ICondition, SurveyObject, SurveyObjectQuestion} from "../../../types/interfaces";
import React from "react";
import {DataGrid, GridCellParams, GridRowSelectionModel, GridValidRowModel} from "@mui/x-data-grid";
import {Box, Button, Modal, Paper, Typography} from "@mui/material";
import {EditObject} from "../../../Shared/Components/EditObject";
import {CustomFormFieldSizes, CustomFormFieldTypes} from "../../../types/enums";

export const QuickBulkAddConditionModal = (props : { item: SurveyObject, setItem: (item: SurveyObject) => void, showModal: boolean, setShowModal: React.Dispatch<React.SetStateAction<boolean>> }) => {
    const [rowSelectionModel, setRowSelectionModel] = React.useState<GridRowSelectionModel>([]);

    const columns = [
        { field: 'questionText', headerName: 'Question', width: 500 },
        { field: 'type', headerName: 'Type', width: 150, valueGetter: (params: GridCellParams) => { return (params.row as SurveyObjectQuestion).type.type } },
        { field: 'key', headerName: 'Key', width: 150 },
    ];

    const [sourceQuestion, setSourceQuestion] = React.useState<any>({});

    const quickConditions = React.useMemo(
        () => {
            let allowedTypes = ["select", "toggle"];
            let questions = props.item.questions.filter((question) => question.key !== props.item.key && allowedTypes.includes(question.type.type) );

            let conditions: ICondition[] = [];

            questions.forEach((question: SurveyObjectQuestion) => {
                switch (question.type.type) {
                    case "select":
                        question.type.options?.forEach((option: string) => {
                            conditions.push({
                                key: question.key,
                                op: "=",
                                value: option
                            });

                            conditions.push({
                                key: question.key,
                                op: "≠",
                                value: option
                            });
                        })

                        break;

                    case "toggle":
                        conditions.push({
                            key: question.key,
                            op: "=",
                            value: true
                        });

                        conditions.push({
                            key: question.key,
                            op: "=",
                            value: false
                        });


                }
            });
            return conditions;
        }, [props.item.questions, props.item.key]
    );


    return (<>
        <Modal open={props.showModal} onClose={() => {
            props.setShowModal(false);
        }}>
            {props.showModal ? (<>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '60%',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        overflowY: 'auto',
                        maxHeight: '90vh',
                    }}
                >
                    <Typography id="modal-modal-title" variant="h5" component="h2" sx={{ mb: 2, textAlign: 'center' }}>
                        Quick Bulk Add Condition
                    </Typography>

                    <DataGrid
                        rows={props.item.questions}
                        columns={columns}
                        getRowId={(row: GridValidRowModel) => row.key} // was _id
                        pageSizeOptions={[100]}
                        disableRowSelectionOnClick
                        checkboxSelection
                        onRowSelectionModelChange={(newRowSelectionModel) => {
                            setRowSelectionModel(newRowSelectionModel);
                        }}
                        rowSelectionModel={rowSelectionModel}
                    />

                    <br />
                    <hr />
                    <br />


                    <Paper variant={"outlined"} sx={{padding: 2}}>
                        <EditObject item={sourceQuestion} setItem={setSourceQuestion} form={[
                            {
                                kind: CustomFormFieldTypes.SELECT,
                                size: CustomFormFieldSizes.ONE,
                                key: "quickCondition",
                                label: "QuickCondition",
                                options: quickConditions.map((condition) => {
                                    let selectedQuestions = props.item.questions.filter((q) => q.key === condition.key)[0];
                                    return {label: `${selectedQuestions.questionText} (${condition.key}) ${condition.op} ${condition.value}`, value: JSON.stringify(condition) }
                                })
                                // TODO: check that it isn't already in the list
                            }
                        ]} columns={1} />

                        {/*<pre>{JSON.stringify(rowSelectionModel, undefined, 4)}</pre>*/}

                        <Button fullWidth={true} disabled={sourceQuestion === null || rowSelectionModel.length === 0} variant={"contained"} onClick={() => {
                            // remove the selected source question from the list of selected questions.

                            try {
                                let quickCondition = JSON.parse(sourceQuestion['quickCondition']) as ICondition;
                                let newParentItem = {...props.item};

                                // loop over the selected questions and add the condition to each one.
                                newParentItem.questions.map((question) => {
                                    let updatedQuestion = {...question};
                                    if (rowSelectionModel.includes(updatedQuestion.key) && updatedQuestion.key !== quickCondition.key) {
                                        if (updatedQuestion.conditions === undefined) {
                                            updatedQuestion.conditions = [];
                                        }
                                        updatedQuestion.conditions.push(quickCondition);
                                    }
                                    return updatedQuestion;
                                })


                                props.setItem(newParentItem);
                                props.setShowModal(false);
                                setRowSelectionModel([]);
                                setSourceQuestion({});
                            }
                            catch (e) {
                                console.log(e)
                            }


                        } }>Add Condition</Button>

                    </Paper>

                </Box>
            </>) : (<></>)}
        </Modal>
    </>);
};
