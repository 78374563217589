import {
    Box,
    Button,
    IconButton, List, ListItem, ListItemIcon, ListItemText,
    Modal,
    Stack,
    Switch,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import React from 'react';
import AuthenticatedLayout, {AuthenticatedLayoutCustomButton} from "../../Library/AuthenticatedLayout";
import {GridCellParams, GridRowId, GridRowParams, GridValueGetterParams} from "@mui/x-data-grid";
import {
    Clone, CustomFormField, ICompany, IProject,
    ISurvey,
    ISurveyAnswer, IUser,
    SurveyObject,
    SurveyObjectQuestion,
} from "../../types/interfaces";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {useParams} from "react-router-dom";
import {ReportDataController} from "../Reports/ReportDataController";
import {AuthContext} from "../../Library/AuthContext";
import {SurveyDataController} from "../Surveys/SurveyDataController";
import {SurveyObjectEdit} from "./Components/SurveyAnswerEdit";
import {AllConditionPassOnQuestion} from "../../utils/additional";
import {EditObject} from "../../Shared/Components/EditObject";
import {CustomFormFieldSizes, CustomFormFieldTypes, UserRole} from "../../types/enums";
import {ConditionFlagsPassthru, SurveyDateTimePassthru} from "../Surveys/SurveyEditorView";
import {UserDataController} from "../Users/UserDataController";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faArrowAltCircleDown,
    faArrowAltCircleUp,
    faArrowDown,
    faArrowUp, faCircleCheck,
    faRotateRight,
    faSave, faTriangleExclamation
} from "@fortawesome/free-solid-svg-icons";
import {faObjectGroup, faObjectUngroup} from "@fortawesome/free-regular-svg-icons";
import {CompanyDataController} from "../Projects/CompanyDataController";
import {createContentWithClone, showInReport} from "../Surveys/Management/RenderCellExceptionCount";

// export type exportType = SurveyObject & { questions: SurveyObjectQuestion & { answer: any }[] };

/*

you have to have the s3 bucket configured with cors.

[
    {
        "AllowedHeaders": [
            "*"
        ],
        "AllowedMethods": [
            "PUT",
            "POST",
            "DELETE",
            "GET",
            "HEAD"
        ],
        "AllowedOrigins": [
            "*"
        ],
        "ExposeHeaders": [
            "x-amz-server-side-encryption",
            "x-amz-request-id",
            "x-amz-id-2"
        ],
        "MaxAgeSeconds": 3000
    }
]

 */


export function RenderCellSurveyAnswerExceptionCountCell(props: { survey: ISurvey, answer: ISurveyAnswer, userToken: string, params: GridCellParams}) {
    const surveyObject = props.params.row as SurveyObject;
    // let obj = props.obj;
    let survey = props.survey;
    // if (survey === undefined) {
    //     return (<></>);
    // }

    const exceptionMemo = React.useMemo(() => {
        try {
            // let survey = props.params.row as ISurvey;
            // if (survey === undefined) {
            //     console.info("Survey is undefined")
            //     return null;
            // }
            // let answers = survey.answers;
            // if (answers === undefined || answers === null || answers.length === 0) {
            //     console.info("Answers is code 96")
            //     return null;
            // }
            let answer = props.answer;
            // let answer = answers[0];
            if (answer === undefined || answer === null || answer.answers === undefined || answer.answers === null) {
                console.info("code 101")
                // console.info(answers)
                return null;
            }

            // let count = 0;

            let contentWithClones = createContentWithClone(survey.content, answer, answer.survey_id, true);

            // TODO: get list of objects that either oare non-conditional or have passed the conditional check
            let contentWithConditionalObjects = contentWithClones.filter((object) => {

                if (object.isConditional === undefined || !object.isConditional) {
                    return true;
                }
                let objectAnswers = answer.answers[object.key];
                if (objectAnswers === undefined) {
                    return false; // does objects that are conditional but have no answer show up
                }

                return objectAnswers.conditionalExists === true;
            });

            // let answeredObjects = await asyncMap<SurveyObject, { tableRows: TableRow[], object: SurveyObject }>(contentWithConditionalObjects, GetSurveyObjectToTableRowsFunction(reportType, item))
            // console.log("answeredObjects", answeredObjects);

            // console.log("contentWithConditionalObjects", contentWithConditionalObjects);

            let results: {key: string, question: string}[] = [];

            // [props.obj].forEach((obj) => {
            //     console.log(obj)
            contentWithConditionalObjects.forEach((obj) => {

                if (surveyObject.key !== obj.key) {
                    return
                }

                if (answer === undefined || answer === null || answer.answers === undefined || answer.answers === null) {
                    return;
                }
                // console.log(obj)
                let objectAnswers = answer.answers[obj.key];
                if (objectAnswers === undefined || objectAnswers === null || objectAnswers.values === undefined || objectAnswers.values === null) {
                    return;
                }
                // console.log(objectAnswers)
                let questions = obj.questions;
                if (questions === undefined || questions === null || questions.length === 0) {
                    return;
                }

                questions.forEach((question) => {
                    let questionAnswers = objectAnswers.values[question.key];
                    if (questionAnswers === undefined) {
                        return null;
                    }
                    // console.log("question", question)
                    if (question.showInExceptionCount === undefined || question.showInExceptionCount === null){
                        return null;
                    }

                    // console.log(questionAnswers)
                    if (showInReport(question.showInExceptionCount, objectAnswers)) {
                        // console.log("showInReport", question.showInExceptionCount)
                        // count++;
                        results.push({
                            key: question.key,
                            question: question.questionText,
                            // answer: objectAnswers.values
                        })
                    }
                    // if (questionAnswers.exception !== undefined && questionAnswers.exception !== null && questionAnswers.exception !== "") {
                    //     count++;
                    // }
                })
            });

            return results;
        }
        catch (e) {
            console.log(e);
            return null;
        }

    }, [surveyObject.key, survey ]);




    const warningsTitleMemo = React.useMemo(() => {
        if (exceptionMemo === null || exceptionMemo.length === 0) {
            // return "No Warnings";
            return (<>
                <div style={{ maxHeight: '250px', overflowY: "auto"}}>
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <FontAwesomeIcon
                                    icon={faCircleCheck}
                                    style={{
                                        color: 'green',
                                        fontSize: '2rem'
                                    }}
                                />
                            </ListItemIcon>

                            <ListItemText primary={"No Exceptions"} />
                        </ListItem>
                    </List>
                </div>
            </>)
        }

        return (<>
            <div style={{ maxHeight: '250px', overflowY: "scroll"}}>
                <List>
                    {exceptionMemo.map((warning) => {
                        return (<>
                            <ListItem>
                                <ListItemIcon>
                                    <FontAwesomeIcon
                                        icon={faTriangleExclamation}
                                        // icon={warning.type === SurveyWarningType.warning ? faShield : faTriangleExclamation}
                                        style={{
                                            // color: warning.type === SurveyWarningType.warning ? 'orange' : 'red',
                                            color: 'orange',
                                            fontSize: '2rem'
                                        }}
                                    />
                                </ListItemIcon>

                                <ListItemText primary={warning.question} />
                            </ListItem>
                        </>)
                    })}
                </List>
            </div>
        </>)
    }, [exceptionMemo])

    const iconMemo = React.useMemo(() => {
        console.info(exceptionMemo)
        if (exceptionMemo === null || exceptionMemo.length === 0) {
            return {icon: faCircleCheck, color: 'green'};
        }
        // if (exceptionMemo.find((item) => item.type === SurveyWarningType.error) !== undefined) {
        //     return {icon:faTriangleExclamation, color: 'red'};
        // }
        return {icon:faTriangleExclamation, color: 'orange'};
    }, [exceptionMemo])

    return (<>
        <Tooltip title={warningsTitleMemo} >
            {exceptionMemo !== null ? (<>
                <Stack direction="row">
                    <FontAwesomeIcon
                        icon={iconMemo.icon}
                        style={{
                            color: iconMemo.color,
                            fontSize: '2em'
                        }}
                    />

                    <Typography>&nbsp;{exceptionMemo.length}</Typography>
                </Stack>
            </>): <></>}
        </Tooltip>
    </>)
}



function DetailViewBuilder(surveyAnswer: ISurveyAnswer, setSurveyAnswer: React.Dispatch<React.SetStateAction<ISurveyAnswer | undefined>>) {

    return function SurveyAnswerDetailView(row: any) {

        return (<>
            <div style={{padding: '0.5rem'}}>


                <div style={{ padding: '0.5rem' }}>
                    <TextField disabled={surveyAnswer.isCompleted}
                        fullWidth={true}
                        label="Label"
                        value={(surveyAnswer.answers[row.key] ?? {}).label || ""}
                        onChange={(e) => {
                            if (surveyAnswer === undefined) { return; }
                            let updatedSurveyAnswer = {...surveyAnswer};
                            if (updatedSurveyAnswer.answers[row.key] === undefined) {
                                updatedSurveyAnswer.answers[row.key] = {
                                    label: "",
                                    values: {}
                                }
                            }
                            updatedSurveyAnswer.answers[row.key].label = e.target.value;
                            setSurveyAnswer(updatedSurveyAnswer);
                        }}
                    />
                </div>

                { row.isConditional &&
                    (<div style={{ padding: '0.5rem' }}>
                        <Switch
                            // disabled={surveyAnswer.isCompleted}
                            checked={(surveyAnswer.answers[row.key] ?? {
                                values: {},
                                conditionalExists : false
                            }).conditionalExists || true}
                            onChange={(e) => {
                                if (surveyAnswer === undefined) { return; }
                                let updatedSurveyAnswer = {...surveyAnswer};
                                if (updatedSurveyAnswer.answers[row.key] === undefined) {
                                    updatedSurveyAnswer.answers[row.key] = {
                                        values: {},
                                        conditionalExists : false
                                    }
                                }
                                updatedSurveyAnswer.answers[row.key].conditionalExists = e.target.checked;
                                setSurveyAnswer(updatedSurveyAnswer);
                            }}
                            disabled={surveyAnswer.isCompleted}
                        />
                        <label>Installed</label>
                    </div>)
                }
                <SurveyObjectEdit surveyAnswer={surveyAnswer} setSurveyAnswer={setSurveyAnswer} row={row}  />
            </div>
        </>);

    }

}

function SurveyAnswerView() {

    const {
        user,
        userToken
    } = React.useContext(AuthContext)!;

    const { id } = useParams<{ id: string }>(); // answer id // so how do we do this if the person needs to do the whole survey on the web.


    const [usersList, setUserList] = React.useState<(IUser & {_id: string})[]>([])
    const [coordinatorsList, setCoordinatorList] = React.useState<(IUser & {_id: string})[]>([])
    React.useEffect(() => {
        async function loadData() {
            console.log(`userToken: ${userToken} id: ${id}`)
            if (userToken === null || userToken === undefined) {
                return;
            }
            if (id === null || id === undefined) {
                return;
            }
            try {
                let list = await new UserDataController(userToken).getAll();
                if (list === null || list === undefined) {
                    alert("Error loading");
                    return;
                }
                setUserList(list.filter((u) => u.role !== UserRole.CLIENT).sort((a, b) => a.name.localeCompare(b.name)));
                setCoordinatorList(list.filter((u) => u.role === UserRole.USER_MANAGER || u.role === UserRole.MANAGER || u.role === UserRole.ADMIN).sort((a, b) => a.name.localeCompare(b.name)));
            } catch (e) {
                console.warn(`exception: ${(e as any).message}`)
            }

        }
        loadData().then();
    }, [userToken, id]);


    const [surveyAnswer, setSurveyAnswer] = React.useState<ISurveyAnswer | undefined>(undefined);
    const [survey, setSurvey] = React.useState<ISurvey | undefined>(undefined);


    const loadData = React.useCallback(async () => {
        if (userToken === null || userToken === undefined) {
            return;
        }
        if (id === undefined) {
            return;
        }
        let answer = await new ReportDataController(userToken).getOneByAnswerId(id);
        setSurveyAnswer(answer);

        console.log("answer", answer);

        let survey = await new SurveyDataController(userToken).getOne(answer.survey_id);
        setSurvey(survey);

        console.log("survey", survey);
        // setList(all);
    }, [userToken, id, setSurveyAnswer, setSurvey]); // Dependencies array


    React.useEffect(() => {
        loadData().then();
    }, [id, loadData]);

    const [companies, setCompanies] = React.useState<(ICompany & {_id: string})[]>([]);
    const projectsMemo = React.useMemo(() => {
        if (companies === undefined || companies === null || companies.length === 0) {
            return [];
        }
        if (survey === null || survey === undefined || survey.company === null) {
            return [];
        }
        let projects = companies.find((company) => {
            return (company as ICompany & {_id: string})._id === survey.company;
        })?.projects as IProject[] ?? [];
        return projects.sort((a, b) => a.name.localeCompare(b.name));
    }, [companies, survey ]);

    const loadCompanyData = React.useCallback(async () => {
        if (userToken === null || userToken === undefined) {
            return;
        }
        let all = (await new CompanyDataController(userToken).getAll()) as (ICompany & {_id: string})[] ?? [];
        setCompanies(all.sort((a, b) => a.name.localeCompare(b.name)));
    }, [userToken]); // Dependencies array


    React.useEffect(() => {

        loadCompanyData().then();
    }, [userToken, loadCompanyData]);

    const saveData = React.useCallback(async () => {
        // async function saveData() {
            if (userToken === null || userToken === undefined) {
                return;
            }
            if (id === undefined) {
                return;
            }
            if (surveyAnswer === undefined) {
                return;
            }

            console.log("surveyAnswer", surveyAnswer)
            console.log("surveyAnswer.isCompleted", surveyAnswer.isCompleted)

            let answer = await new ReportDataController(userToken).update(surveyAnswer._id, surveyAnswer);
            setSurveyAnswer(answer);

            alert("Saved")

            await loadData();
        // }
    }, [id, loadData, surveyAnswer, userToken]);



    const fields = React.useMemo(
        () => {
            let fields: CustomFormField[] = [
                {
                    kind: CustomFormFieldTypes.TEXT,
                    size: CustomFormFieldSizes.SMALL,
                    key: "caseNumber",
                    label: "Case Number"
                },
                {
                    kind: CustomFormFieldTypes.SELECT,
                    size: CustomFormFieldSizes.SMALL,
                    key: "assigned_user_id",
                    label: "Assigned User Id",
                    options: [
                        { label: "Un-assigned", value: null },
                        ...usersList.map((opt: (IUser & {_id: string})) => { return { label: opt.name, value: opt._id }; })
                    ]
                },
                {
                    kind: CustomFormFieldTypes.SELECT,
                    size: CustomFormFieldSizes.SMALL,
                    key: "coordinator_user_id",
                    label: "Coordinator",
                    options: [
                        { label: "Un-assigned", value: null },
                        ...coordinatorsList.map((opt: (IUser & {_id: string})) => { return { label: opt.name, value: opt._id }; })
                    ]

                },
                {
                    kind: CustomFormFieldTypes.TEXT,
                    size: CustomFormFieldSizes.SMALL,
                    key: "surveyName",
                    label: "Survey Name"
                },
                {
                    kind: CustomFormFieldTypes.TEXT,
                    size: CustomFormFieldSizes.SMALL,
                    key: "customerTicketNumber",
                    label: "Customer Ticket Number"
                },
                {
                    kind: CustomFormFieldTypes.TEXT,
                    size: CustomFormFieldSizes.TWO,
                    key: "siteContactName",
                    label: "Site Contact Name"
                },

                {
                    kind: CustomFormFieldTypes.TEXT,
                    size: CustomFormFieldSizes.TWO,
                    key: "siteContactPhone",
                    label: "Site Contact Phone"
                },

                {
                    kind: CustomFormFieldTypes.TEXT,
                    size: CustomFormFieldSizes.TWO,
                    key: "siteContactEmail",
                    label: "Site Contact Email"
                },

                {
                    kind: CustomFormFieldTypes.SELECT,
                    size: CustomFormFieldSizes.SMALL,
                    key: "company",
                    label: "Company",
                    options: [
                        { label: "Un-assigned", value: null },

                        ...companies.map((opt: (ICompany & {_id: string} )) => { return { label: opt.name, value: opt._id }; })
                    ]
                },
                {
                    kind: CustomFormFieldTypes.SELECT,
                    size: CustomFormFieldSizes.SMALL,
                    key: "project",
                    label: "Project",
                    options: [
                        { label: "Un-assigned", value: null },
                        ...projectsMemo.map((opt: (IProject)) => { return { label: opt.name, value: (opt as IProject & {_id: string})._id }; })
                    ],
                    readonly: survey === undefined || survey.company === null
                },


                {
                    kind: CustomFormFieldTypes.TEXT,
                    size: CustomFormFieldSizes.SMALL,
                    key: "locationName",
                    label: "Location Name"
                },


                {
                    kind: CustomFormFieldTypes.TEXT,
                    size: CustomFormFieldSizes.ONE,
                    key: "surveyOwner",
                    label: "Survey Owner"
                },
                {
                    kind: CustomFormFieldTypes.TEXT,
                    size: CustomFormFieldSizes.MEDIUM,
                    key: "address",
                    label: "Site Address"
                },
                {
                    kind: CustomFormFieldTypes.PASSTHRU,
                    size: CustomFormFieldSizes.THREE,
                    key: "surveyDate",
                    label: "Survey Date",
                    passthru: SurveyDateTimePassthru,
                    passthruProps: { survey: survey, setSurvey: setSurvey, readonly: surveyAnswer?.isCompleted }
                },
                {
                    kind: CustomFormFieldTypes.PASSTHRU,
                    size: CustomFormFieldSizes.ONE,
                    key: "conditionFlags",
                    label: "Condition Flags",
                    passthru: ConditionFlagsPassthru,
                    passthruProps: { survey: survey, setSurvey: setSurvey }
                },
                {
                    kind: CustomFormFieldTypes.TOGGLE,
                    size: CustomFormFieldSizes.SMALL,
                    key: "isTemplate",
                    label: "Is Template"
                },
            ];

            return fields.map((field: CustomFormField) => {
                return {
                    ...field,
                    readonly: true
                }
            });
        },
        [usersList, coordinatorsList, setSurvey, survey, companies, projectsMemo, surveyAnswer?.isCompleted] // survey?.address, survey?.surveyDate,
    );

    const [headerExpanded, setHeaderExpanded] = React.useState<boolean>(true);

    const getDetailPanelContent = React.useCallback(
        ({ row }: GridRowParams) => {
            console.log("getDetailPanelContent called")
            if (surveyAnswer === undefined || survey === undefined) { return (<></>); }
            else {
                return DetailViewBuilder(surveyAnswer, setSurveyAnswer)(row)
            }

        },
        [survey, surveyAnswer],
    );

    const SurveyObjectsMemo = React.useMemo(() => {

        let objects: any[] = [];

        if (survey === undefined) {
            console.log("survey undefined")
            return [];
        }
        if (surveyAnswer === undefined) {
            console.log("surveyAnswer undefined")
            return [];
        } // TODO: change this!
        if (surveyAnswer.answers === undefined) {
            console.log("surveyAnswer.answers undefined")
            return [];
        }

        let surveyObjects = survey.content.map((surveyObject: SurveyObject) => {
            function test(object: SurveyObject) {
                if (object.isConditional === undefined || !object.isConditional) {
                    return true;
                }
                if (surveyAnswer === undefined) {
                    return false;
                }
                let objectAnswers = surveyAnswer.answers[object.key];
                if (objectAnswers === undefined) {


                    return true
                    // return false; // does objects that are conditional but have no answer show up
                }


                console.info(`line 430 objectAnswers ${surveyObject.key} ${object.key}`, objectAnswers)

                if (objectAnswers.conditionalExists === undefined) {
                    objectAnswers.conditionalExists = true; // noot sure if this will set it.
                    console.info("line 433 objectAnswers.conditionalExists", objectAnswers.conditionalExists)
                    surveyAnswer.answers[object.key] = objectAnswers; // this may not work
                    return true;
                }

                // return objectAnswers.conditionalExists !== false;
                console.info("line 437 objectAnswers.conditionalExists", objectAnswers.conditionalExists)
                return objectAnswers.conditionalExists;
            }

            if (test(surveyObject)) {
                return surveyObject;
            }
            else {
                return {
                    key: surveyObject.key,
                    location: surveyObject.location,
                    isConditional: surveyObject.isConditional,
                    parent: surveyObject.parent,
                    questions: []
                } as SurveyObject;
            }



        });

        surveyObjects.forEach((surveyObject: SurveyObject) => {

            // TODO: still handle the installed/notinstalled

            // TODO: handle the condition based questions. This is where the question is only asked if the answer to a previous question is a certain value.

            let filteredQuestions = surveyObject.questions.filter((question: SurveyObjectQuestion) => {

                // TODO: check if it is in survey level condition flags
                // survey.conditionFlags
                if (survey.conditionFlags !== undefined && survey.conditionFlags !== null) {

                    if (question.requiredConditionFlags !== undefined && question.requiredConditionFlags !== null && Object.keys(question.requiredConditionFlags).length > 0) {
                        console.log("question", question)
                        console.log("survey.conditionFlags", survey.conditionFlags)

                        console.log("question.requiredConditionFlags", question.requiredConditionFlags)

                        let filteredFlags = question.requiredConditionFlags.filter((flag: string) => {
                            if (survey.conditionFlags !== undefined) { // not sure why I need to check this again!!!!!!!!!!!!!
                                console.log("flag", flag)
                                console.log(`survey.conditionFlags[${flag}]`, survey.conditionFlags[flag])
                                return survey.conditionFlags[flag];
                            }
                            return false;
                        })

                        console.log("filteredFlags", filteredFlags)
                        if (filteredFlags.length !== Object.keys(question.requiredConditionFlags).length) {
                            return false;
                        }
                    }

                }


                // let objectAnswers = surveyAnswer.answers[surveyObject.key];
                return AllConditionPassOnQuestion(question, surveyAnswer.answers[surveyObject.key] ?? { values: {}, clones: {} });
            });

            let questions = filteredQuestions.map((question: SurveyObjectQuestion) => {

                let objAnswer = surveyAnswer.answers[surveyObject.key];
                if (objAnswer === undefined || objAnswer.values === undefined) {
                    return {
                        ...question,
                        answer: ""
                    }
                }

                return {
                    ...question,
                    answer: objAnswer.values[question.key] ?? {}
                }
            })




            objects.push({
                ...surveyObject,
                questions: questions
            })

            if (surveyAnswer.clones === undefined) {
                return;
            }

            let clone = surveyAnswer.clones[surveyObject.key];
            if (clone) {
                clone.forEach((clone: Clone) => {
                    objects.push({
                        ...surveyObject,
                        key: clone.key,
                        questions: surveyObject.questions.map((question: SurveyObjectQuestion) => {


                            let objAnswer = surveyAnswer.answers[clone.key];
                            if (objAnswer === undefined) {
                                return {
                                    ...question,
                                    answer: ""
                                }
                            }

                            if (objAnswer.values === undefined) {
                                return {
                                    ...question,
                                    answer: ""
                                }
                            }



                            let answer = objAnswer.values[question.key];
                            if (answer === undefined) {
                                return {
                                    ...question,
                                    answer: ""
                                }
                            }

                            return {
                                ...question,
                                answer: objAnswer.values[question.key] ?? {}
                            }
                        })
                        , isClone: true,
                        parentKey: surveyObject.key
                    })
                })
            }
        })


        // TODO: handle the clone.

        // TODO: merge the answers into the questions.

        return objects;
    }, [surveyAnswer, survey]);

    // these buttons didn't allow the save to work properly.?????
    const customButtonsMemo = React.useMemo<AuthenticatedLayoutCustomButton[]>(() => {
        let buttons: AuthenticatedLayoutCustomButton[] = [];

        buttons.push({
            icon:  <FontAwesomeIcon icon={faSave} />,
            label: "Save",
            action: () => {
                console.log("save clicked")
                saveData().then(() => {})
            }
        });

        // Expand button
        buttons.push({
            icon:  <FontAwesomeIcon icon={headerExpanded ? faObjectUngroup : faObjectGroup} />,
            label: (headerExpanded ? "Collapse" : "Expand"),
            action: () => {
                setHeaderExpanded(!headerExpanded)
            }
        });

        buttons.push({
            label: `Reload`,
            action: () => {
                loadData().then();
            },
            icon: <FontAwesomeIcon icon={faRotateRight} />
        });


        return buttons;

    }, [ headerExpanded, saveData, loadData ]);

    // This forces it to have only one expanded row at a time.
    const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = React.useState<
        GridRowId[]
    >([]);

    const handleDetailPanelExpandedRowIdsChange = React.useCallback(
        (newIds: GridRowId[]) => {
            setDetailPanelExpandedRowIds(
                newIds.length > 1 ? [newIds[newIds.length - 1]] : newIds,
            );
        },
        [],
    );
    // end of forcing only one expanded row at a time.

    const [showEasyOrder, setShowEasyOrder] = React.useState<boolean>(false);


    return (<>
        <AuthenticatedLayout  pageTitle={`Surveys Answers - ${id?.trim()} - ${surveyAnswer === undefined ? '' : surveyAnswer.updatedAt}`}
            customButtons={customButtonsMemo}
        >

            <Stack sx={{ height: '100%' }}>
                {headerExpanded && (
                    <EditObject item={survey} setItem={setSurvey} form={fields} columns={12}></EditObject>
                )}

                {/*<DebugView value={surveyAnswer?.updatedBy} />*/}

                <div style={{ padding: '0.5rem' }}>
                    {surveyAnswer !== undefined && (<>
                    <Switch
                        // disabled={surveyAnswer.isCompleted}
                        checked={surveyAnswer.isCompleted}
                        onChange={(e) => {
                            console.log("checked? ", e.target.checked)

                            if (surveyAnswer === undefined) {

                                return;
                            }
                            let updatedSurveyAnswer = {...surveyAnswer};
                            console.log(e.target.checked)
                            updatedSurveyAnswer.isCompleted = e.target.checked;
                            console.log("updatedSurveyAnswer", updatedSurveyAnswer)
                            // setSurveyAnswer(updatedSurveyAnswer);
                            // this is needed to make sure the data saves, something else appears to be overwriting it.

                            async function saveData() {
                                if (userToken === null || userToken === undefined) {
                                    return;
                                }
                                if (surveyAnswer === undefined) {
                                    return;
                                }
                                let answer = await new ReportDataController(userToken).update(surveyAnswer._id, updatedSurveyAnswer);
                                setSurveyAnswer(answer);
                            }

                            saveData().then(() => {})
                        }}
                        // disabled={props.field.readonly}
                    />
                    <label>Survey Completed</label>
                   </> )}
                </div>

                {surveyAnswer !== undefined && user !== undefined && user !== null && user.role === UserRole.ADMIN  && (
                    <>
                        <Stack direction={"column"} spacing={2} sx={{padding: 1}}>
                            <TextField
                                fullWidth={true}
                                label="Clones DEBUG!!!! ONLY USE THIS IF YOU KNOW WHAT YOU ARE DOING!!!!"
                                value={(surveyAnswer.clones === undefined) ? "" : JSON.stringify(surveyAnswer.clones)}
                                onChange={(e) => {
                                    if (surveyAnswer === undefined) { return; }
                                    let updatedSurveyAnswer = {...surveyAnswer};
                                    try {
                                        updatedSurveyAnswer.clones = JSON.parse(e.target.value);
                                        setSurveyAnswer(updatedSurveyAnswer);
                                    }
                                    catch (e) {

                                    }

                                }}
                            />

                            <Stack direction={"row"} spacing={2}>

                            {/*<TextField*/}
                            {/*    fullWidth={true}*/}
                            {/*    label="Ordering the keys, DEBUG!!!! ONLY USE THIS IF YOU KNOW WHAT YOU ARE DOING!!!! JSON ARRAY!"*/}
                            {/*    value={(surveyAnswer.overriddenOrderKeys === undefined) ? "[]" : JSON.stringify(surveyAnswer.overriddenOrderKeys)}*/}
                            {/*    onChange={(e) => {*/}
                            {/*        if (surveyAnswer === undefined) { return; }*/}
                            {/*        let updatedSurveyAnswer = {...surveyAnswer};*/}
                            {/*        try {*/}
                            {/*            updatedSurveyAnswer.overriddenOrderKeys = JSON.parse(e.target.value);*/}
                            {/*            setSurveyAnswer(updatedSurveyAnswer);*/}
                            {/*        }*/}
                            {/*        catch (e) {*/}
                            {/*            console.log(e)*/}
                            {/*        }*/}
                            {/*    }}*/}
                            {/*/>*/}

                                <Button variant="outlined" color="primary" fullWidth={true} onClick={() => {
                                    setShowEasyOrder(true);
                                }} >Quick Order</Button>
                                {survey !== undefined && (
                                    <QuickOrderModal
                                        setShowModal={setShowEasyOrder}
                                        setSurveyAnswer={setSurveyAnswer}
                                        surveyAnswer={surveyAnswer}
                                        showModal={showEasyOrder}
                                        survey={survey}
                                        SurveyObjectsMemo={SurveyObjectsMemo}
                                    />
                                )}


                            </Stack>
                        </Stack>

                    </>

                    )}

                <br />



                {/*<DebugView value={surveyAnswer} />*/}

                <DataGridPro
                    style={{ height: 'calc( 100% - 3.5rem )', width: '100%' }}
                    columns={[
                        { field: 'key', headerName: 'Key', width: 200 },
                        { field: 'location', headerName: 'Object Name', width: 200 },
                        { field: 'isConditional', headerName: 'Is Conditional', width: 130 },
                        { field: 'parent', headerName: 'Parent', width: 130 },
                        // { field: 'isClone', headerName: "IsClone", width: 150, valueGetter: (params: GridValueGetterParams) => {
                        //         let obj = params.row as SurveyObject & { isClone: boolean };
                        //         return obj.isClone ? "Yes" : "No";
                        //         // return obj.questions.filter((q) => q.type.type !== 'instruction').length;
                        //     } },


                        {
                            field: 'add_clone', headerName: ' ', width: 100, editable: false,
                            renderCell: (params) => {
                                let obj = params.row as SurveyObject & { isClone: boolean, parentKey: string };
                                // if (!obj.isClone) {
                                //     return (<></>);
                                // } else {
                                    return (

                                        <>
                                            <Button variant="outlined" color="primary" fullWidth={true}
                                                    disabled={surveyAnswer?.isCompleted}
                                                    // dibled={true}
                                                    onClick={async () => {
                                                        // remove the clone from the surveyAnswer
                                                        console.log("line 803")
                                                        if (surveyAnswer === undefined) {
                                                            // this still is needed here
                                                            console.log("line 805")
                                                            return;
                                                        }
                                                        let updatedSurveyAnswer = {...surveyAnswer};
                                                        if (updatedSurveyAnswer.clones === undefined) {
                                                            // return;
                                                            console.log("line 811")
                                                            updatedSurveyAnswer.clones = {};
                                                        }
                                                        if (updatedSurveyAnswer.clones[obj.key] === undefined) {
                                                            // return;
                                                            console.log("line 817")
                                                            updatedSurveyAnswer.clones[obj.key] = [];
                                                        }
                                                        // let clone = updatedSurveyAnswer.clones[obj.parentKey].find((c) => c.key === obj.key);
                                                        // if (clone === undefined) {
                                                        //     console.log("line 822")
                                                        //     return;
                                                        // }
                                                        let clone = {
                                                            key: obj.key + "_" + (updatedSurveyAnswer.clones[obj.key].length + 1),
                                                            parent: obj.key,
                                                        }
                                                        updatedSurveyAnswer.clones[obj.key].push(clone);
                                                        // let index = updatedSurveyAnswer.clones[obj.parentKey].indexOf(clone);
                                                        // if (index === -1) {
                                                        //     return;
                                                        // }
                                                        // updatedSurveyAnswer.clones[obj.parentKey].splice(index, 1);
                                                        console.log("line 830")
                                                        setSurveyAnswer(updatedSurveyAnswer);
                                                    }}>CLone</Button>
                                        </>
                                    )
                                // }

                            }
                        },

                        {
                            field: 'remove_clone', headerName: ' ', width: 100, editable: false,
                            renderCell: (params) => {
                                let obj = params.row as SurveyObject & { isClone: boolean, parentKey: string };
                                if (!obj.isClone) {
                                    return (<></>);
                                } else {
                                    return (

                                        <>
                                            <Button variant="outlined" color="primary" fullWidth={true}
                                                    disabled={surveyAnswer?.isCompleted}
                                                    onClick={async () => {
                                                        // remove the clone from the surveyAnswer
                                                        if (surveyAnswer === undefined) {
                                                            return;
                                                        }
                                                        let updatedSurveyAnswer = {...surveyAnswer};
                                                        if (updatedSurveyAnswer.clones === undefined) {
                                                            return;
                                                        }
                                                        if (updatedSurveyAnswer.clones[obj.parentKey] === undefined) {
                                                            return;
                                                        }
                                                        let clone = updatedSurveyAnswer.clones[obj.parentKey].find((c) => c.key === obj.key);
                                                        if (clone === undefined) {
                                                            return;
                                                        }
                                                        let index = updatedSurveyAnswer.clones[obj.parentKey].indexOf(clone);
                                                        if (index === -1) {
                                                            return;
                                                        }
                                                        updatedSurveyAnswer.clones[obj.parentKey].splice(index, 1);
                                                        setSurveyAnswer(updatedSurveyAnswer);

                                                    }}>Remove</Button>
                                        </>
                                    )
                                }

                            }
                        },



                        { field: 'count', headerName: "Questions Count", width: 150, valueGetter: (params: GridValueGetterParams) => {
                            let obj = params.row as SurveyObject;
                            return obj.questions.filter((q) => q.type.type !== 'instruction').length;
                        } },
                        { field: 'answeredCount', headerName: "Answered Count", width: 150, valueGetter: (params: GridValueGetterParams) => {
                            let obj = params.row as SurveyObject;
                            let objectAnswers = surveyAnswer?.answers[obj.key] ?? { values: {} };
                            // TODO: filter based on the actual answer type.
                            return obj.questions.filter((q) => {
                                if (q.type.type === 'instruction') {
                                    return false;
                                }
                                if (objectAnswers === undefined || objectAnswers.values === undefined || objectAnswers.values === null) {
                                    return false;
                                }
                                if (objectAnswers.values[q.key] === undefined) {
                                    return false;
                                }
                                if (objectAnswers.values[q.key] === null) {
                                    return false;
                                }

                                // if (q.type.type === 'location') {
                                //     let location = objectAnswers.values[q.key] as ;
                                //     return true;
                                // }
                                
                                return true;
                                // (objectAnswers.values[q.key] !== undefined) && q.type.type !== 'instruction'
                            // ).
                            //     length
                            }).length;
                        } },
                        // if (userToken !== null && userToken !== undefined) {
                        {field: 'valid', headerName: 'Exception Count', width: 120, renderCell: (params: GridCellParams) => (<>
                            {userToken !== null && userToken !== undefined && surveyAnswer !== null && surveyAnswer !== undefined && survey !== null && survey !== undefined && (<RenderCellSurveyAnswerExceptionCountCell survey={survey} answer={surveyAnswer} userToken={userToken} params={params}/>)}
                        </>)},
                        // }
                    ]}
                    rows={SurveyObjectsMemo}
                    rowThreshold={0}
                    getRowId={(row) => row.key || row.location}
                    getDetailPanelHeight={(row) => {
                        // row.row
                        // return 800;

                        let questionCount = (row.row as SurveyObject).questions.length;
                        if (questionCount === 0) {
                            return 275;
                        }
                        // 350

                        let height: number = 120;
                        height += 65; // for the label
                        height += 65; // for the conditional
                        // height += 400; // for the base
                        (row.row as SurveyObject).questions.forEach((question: SurveyObjectQuestion) => {
                            if (question.type.type === "photo"){
                                height += 445;
                            }
                            else {
                                height += 75;
                            }
                        });
                        return height;

                        // return 400 + ( (row.row as SurveyObject).questions.length * 50);
                    }}
                    getDetailPanelContent={getDetailPanelContent}
                    detailPanelExpandedRowIds={detailPanelExpandedRowIds}
                    onDetailPanelExpandedRowIdsChange={handleDetailPanelExpandedRowIdsChange}
                    disableRowSelectionOnClick
                />

            </Stack>
        </AuthenticatedLayout>
    </>);
}

export default SurveyAnswerView;

function QuickOrderModal(props: {
    surveyAnswer: ISurveyAnswer,
    setSurveyAnswer: React.Dispatch<React.SetStateAction<ISurveyAnswer | undefined>>,
    showModal: boolean,
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>,
    survey: ISurvey,
    SurveyObjectsMemo: any
})
{

    interface QuickOrderItem {
        key: string;
        name: string;
    }

    const [itemOrder, setItemOrder] = React.useState<QuickOrderItem[]>([]);

    React.useEffect(() => {
        if (props.surveyAnswer === undefined) {
            return;
        }
        let order = props.surveyAnswer.overriddenOrderKeys ?? [];

        if (order.length === 0) {
            let objects = props.SurveyObjectsMemo;
            order = objects.map((obj: SurveyObject) => obj.key);
        }

        let mappedOrder = order.map((obj: string) => {
            let object = props.SurveyObjectsMemo.find((o: SurveyObject) => o.key === obj);
            if (object === undefined) {
                return { key: obj, name: "Unknown" }
            }

            return { key: obj, name: object.location }
        });

        setItemOrder(mappedOrder);
    }, [props.showModal, props.surveyAnswer, props.SurveyObjectsMemo]);

    function MoveToTopBtn (params: GridCellParams) {
        let index = itemOrder.findIndex((o: QuickOrderItem) => o.key === params.row.key);
        return (<Tooltip title="Move Object To Top" arrow><IconButton disabled={index === 0} color={"warning"} onClick={() => {
            let orderedObjects: QuickOrderItem[] = [...itemOrder];
            let temp = orderedObjects[index];
            orderedObjects.splice(index, 1);
            orderedObjects.unshift(temp);
            setItemOrder(orderedObjects);
        }}>
            <FontAwesomeIcon icon={faArrowAltCircleUp} />
        </IconButton></Tooltip>)
    }

    function MoveToUpBtn(params: GridCellParams) {
        let index = itemOrder.findIndex((o: QuickOrderItem) => o.key === params.row.key);
        return (<Tooltip title="Move Object Up" arrow><IconButton disabled={index === 0} color={"warning"} onClick={() => {
            let orderedObjects: QuickOrderItem[] = [...itemOrder];
            let temp = orderedObjects[index];
            orderedObjects[index] = orderedObjects[index - 1];
            orderedObjects[index - 1] = temp;
            setItemOrder(orderedObjects);
        }}>
            <FontAwesomeIcon icon={faArrowUp} />
        </IconButton></Tooltip>)
    }

    function MoveDownBtn(params: GridCellParams) {
        let index = itemOrder.findIndex((o: QuickOrderItem) => o.key === params.row.key);
        return (<Tooltip title="Move Object Down" arrow><IconButton disabled={index === itemOrder.length - 1} color={"warning"} onClick={() => {

            let orderedObjects: QuickOrderItem[] = [...itemOrder];
            let temp = orderedObjects[index];
            orderedObjects[index] = orderedObjects[index + 1];
            orderedObjects[index + 1] = temp;
            setItemOrder(orderedObjects);
        }}>
            <FontAwesomeIcon icon={faArrowDown} />
        </IconButton></Tooltip>)
    }

    function MoveToBottomBtn  (params: GridCellParams) {
        let index = itemOrder.findIndex((o: QuickOrderItem) => o.key === params.row.key);
        return (<Tooltip title="Move Object To Bottom" arrow><IconButton disabled={index === itemOrder.length - 1} color={"warning"} onClick={() => {
            let orderedObjects: QuickOrderItem[] = [...itemOrder];
            let temp = orderedObjects[index];
            orderedObjects.splice(index, 1);
            orderedObjects.push(temp);
            setItemOrder(orderedObjects);
        }}>
            <FontAwesomeIcon icon={faArrowAltCircleDown} />
        </IconButton></Tooltip>)
    }
    //


    return (
        <Modal
            open={props.showModal}
            onClose={() => { props.setShowModal(false) }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{
                position: 'absolute' as 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 800,
                bgcolor: 'background.paper',
                border: '2px solid #000',
                boxShadow: 24,
                p: 4
            }}>
                <Typography id="modal-modal-title" variant="h5" component="h2" sx={{ mb: 2, textAlign: 'center' }}>
                    Edit order
                </Typography>


                {/*<DebugView value={itemOrder} />*/}

                {/* Trying to get the initial order is going to be interesting? */}

                <DataGridPro
                    style={{ minHeight:'300px', minWidth:"300px",  width: '100%', maxHeight: '500px' }}
                    columns={[
                        { field: 'key', headerName: 'Key', width: 200 },
                        // get the name of the object
                        { field: 'name', headerName: 'Object Name', width: 200 },
                        // { field: 'location', headerName: 'Object Name', width: 200, valueGetter: (params: GridValueGetterParams) => {
                        //     let key = params.row as string;
                        //     let objects = props.SurveyObjectsMemo;
                        //     let obj = objects.find((o: SurveyObject) => o.key === key);
                        //     return obj?.location ?? key;
                        // }},
                        // object name
                        // move up to top
                        // move down to bottom
                        // move up
                        // move down
                        // change key for parent

                        { field: 'move_to_top_btn', headerName: '', width: 75, renderCell: MoveToTopBtn },
                        { field: 'move_up_btn', headerName: '', width: 75, renderCell: MoveToUpBtn },
                        { field: 'move_down_btn', headerName: '', width: 75, renderCell: MoveDownBtn },
                        { field: 'move_to_bottom_btn', headerName: '', width: 75, renderCell: MoveToBottomBtn }

                    ]}
                    rows={itemOrder}
                    rowThreshold={0}
                    getRowId={(row) => row.key }
                    getDetailPanelHeight={(row) => {
                        // row.row
                        // return 800;

                        let questionCount = (row.row as SurveyObject).questions.length;
                        if (questionCount === 0) {
                            return 275;
                        }
                        let height: number = 120;
                        height += 65; // for the label
                        height += 65; // for the conditional
                        (row.row as SurveyObject).questions.forEach((question: SurveyObjectQuestion) => {
                            if (question.type.type === "photo"){
                                height += 445;
                            }
                            else {
                                height += 75;
                            }
                        });
                        return height;
                    }}
                    disableRowSelectionOnClick
                />

                <br />

                <Stack direction={"row"} spacing={2}>

                    <Button type={"submit"} variant={"contained"} size={"large"} fullWidth={true} onClick={() => {
                        // props.onSave(survey);
                        props.setSurveyAnswer((prev) => {
                            if (prev === undefined) {
                                return prev;
                            }
                            let updatedSurveyAnswer = {...prev};
                            updatedSurveyAnswer.overriddenOrderKeys = itemOrder.map((item) => item.key);
                            return updatedSurveyAnswer;
                        })
                        props.setShowModal(false);
                    }}>Update</Button>

                    <Button type={"submit"} variant={"outlined"} size={"large"} fullWidth={true} onClick={() => {
                        // props.onSave(survey);
                        props.setSurveyAnswer((prev) => {
                            if (prev === undefined) {
                                return prev;
                            }
                            let updatedSurveyAnswer = {...prev};
                            updatedSurveyAnswer.overriddenOrderKeys = [];
                            return updatedSurveyAnswer;
                        })
                        props.setShowModal(false);
                    }}>Clear</Button>
                </Stack>

            </Box>
        </Modal>


    );
}
