import {MenuItem, Select} from "@mui/material";
import {UserRole} from "../../types/enums";
import React from "react";

export const UserRoleChangeCell = ({ value, onChange }: any) => {
    const handleStatusChange = (event: any) => {
        onChange(event.target.value);
    };

    return (
        <Select value={value} onChange={handleStatusChange} fullWidth={true}>
            {[
                { label: "Admin", value: UserRole.ADMIN },
                { label: "User Manager", value: UserRole.USER_MANAGER},
                { label: "Manager / PC", value: UserRole.MANAGER },
                { label: "Technician", value: UserRole.TECHNICIAN },
                { label: "Client", value: UserRole.CLIENT }
            ].map((statusOption) => (
                <MenuItem key={statusOption.label} value={statusOption.value}>
                    {statusOption.label}
                </MenuItem>
            ))}

        </Select>
    );
};
