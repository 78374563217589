import {
    DataController, IQuickObject
} from "../../types/interfaces";

export interface PaginatedInput {
    page: number,
    pageSize: number
}

export type IPaginated<T> = {
    items: T[],
    count: number
}

export class BankDataController_QuickObject implements DataController<IQuickObject> {

    private readonly baseURL : string

    constructor(private userToken: string) {
        // Nothing to do here yet.
        // const WS_URL = `ws${ process.env.REACT_APP_SERVER_URL ? "s" :"" }://${baseURL}`;
        const domainURL: string = process.env.REACT_APP_SERVER_URL || "127.0.0.1:3000";
        const protocol: string = `http${ process.env.REACT_APP_SERVER_URL ? "s" :"" }`;
        this.baseURL = `${protocol}://${domainURL}`;
    }

    public async getAll(): Promise<IQuickObject[]> {
        let results = await fetch(`${this.baseURL}/api/quickObjects`, {
            headers: {
                "authorization": `Bearer ${this.userToken}`
            }
        });
        let data = await results.json();
        return await data; // this doesn't seem to type check.
    }

    public async getPaginated(filter: PaginatedInput): Promise<IPaginated<IQuickObject>> {
        console.log(`filter: ${JSON.stringify(filter)}`);
        let results = await fetch(`${this.baseURL}/api/quickObjects/paginated/?page=${filter.page}&limit=${filter.pageSize}`, {
            headers: {
                "authorization": `Bearer ${this.userToken}`
            }
        });
        let data = await results.json();
        return await data; // this doesn't seem to type check.
    }

    public async getOne(id: String): Promise<IQuickObject> {
        let results = await fetch(`${this.baseURL}/api/quickObjects/${id}`, {
            headers: {
                "authorization": `Bearer ${this.userToken}`
            }
        });
        let data = await results.json();
        return await data; // this doesn't seem to type check.
    }

    public async create(item: IQuickObject): Promise<IQuickObject> {
        let results = await fetch(`${this.baseURL}/api/quickObjects/`, {
            method: "POST",
            headers: {
                "authorization": `Bearer ${this.userToken}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(item)
        });
        let data = await results.json();
        console.log(data);
        return await data.item; // this doesn't seem to type check.
    }

    public async update(id: string, item: IQuickObject): Promise<IQuickObject> {
        let results = await fetch(`${this.baseURL}/api/quickObjects/${id}`, {
            method: "PUT",
            headers: {
                "authorization": `Bearer ${this.userToken}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(item)
        });

        // handle when results is 304
        if (results.status === 304) {
            console.log("304");
            return item;
        }

        console.log(results)
        let data = await results.json();
        console.log(data);
        return await data; // this doesn't seem to type check. change to .item when api updated
    }

    public async delete(id: string): Promise<IQuickObject> {
        let results = await fetch(`${this.baseURL}/api/quickObjects/${id}`, {
            method: "DELETE",
            headers: {
                "authorization": `Bearer ${this.userToken}`,
                "Content-Type": "application/json"
            }
        });

        console.log(results)
        let data = await results.json();
        console.log(data);
        return await data; // this doesn't seem to type check. change to .item when api updated
    }

}
