import React from "react";
import {AuthContext} from "../../Library/AuthContext";
import {IQuickQuestionGroup, SurveyObjectQuestion} from "../../types/interfaces";
import {DataGrid, GridCellParams, GridRowParams, GridValidRowModel} from "@mui/x-data-grid";
import {DEFAULT_PAGE_SIZE_OPTIONS} from "../../types/enums";

import {useLocalStorage} from "../../Hooks/useLocalStorage";
import AuthenticatedLayout, {AuthenticatedLayoutCustomButton} from "../../Library/AuthenticatedLayout";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileUpload,} from "@fortawesome/free-solid-svg-icons";
import {IconButton} from "@mui/material";
import {DownloadOutlined as DownloadOutlineIcon} from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {BankDataController_QuickQuestionGroup} from "./BankDataController_QuickQuestionGroup";
import {StoredPaginationModel} from "../Surveys/SurveyManagementView";

function QuickQuestionGroupView() {

    const {
        // user,
        userToken
    } = React.useContext(AuthContext)!;


    const [paginationModel, setPaginationModel] = useLocalStorage("QuickQuestionsGroup.paginationModel", {
        pageSize: 25,
        page: 0,
    }) as [StoredPaginationModel, React.Dispatch<React.SetStateAction<StoredPaginationModel>>];
    const [totalRows, setTotalRows] = React.useState<number>(0);

    const loadData = React.useCallback(async () => {
        if (userToken === null || userToken === undefined) {
            return;
        }
        let response = await new BankDataController_QuickQuestionGroup(userToken).getPaginated(paginationModel);
        setQuickQuestionGroupsList(response.items);
        setTotalRows(response.count)
    }, [userToken, paginationModel]);


    React.useEffect(() => {
        loadData().then()
    }, [loadData]);

    const [quickQuestionGroupsList, setQuickQuestionGroupsList] = React.useState<IQuickQuestionGroup[]>([]);


    const getDetailPanelContent = React.useCallback(
        ({ row }: GridRowParams) => {
            let rowData = row as IQuickQuestionGroup & { _id: string };
            return (<>
                <div style={{padding: '1rem', height: '100%'}}>
                    <DataGrid
                        style={{height: '100%'}}
                        columns={[
                            { field: 'key', headerName: 'Key', width: 150, editable: false },
                            { field: 'questionText', headerName: 'Question Text', width: 400, editable: false },
                            { field: 'type.type', headerName: 'Question Type', width: 150, editable: false, valueGetter: (params: GridCellParams) => {
                                    let question = params.row as SurveyObjectQuestion;
                                    return question.type.type;
                                }},
                        ]}
                        rows={rowData.content || []}
                        getRowId={(row) => row.key}
                    />
                </div>
            </>);
        }, []
    );

    const columns = [
        { field: '_id', headerName: 'ID', width: 250, editable: false },
        { field: 'name', headerName: 'Name', width: 250, editable: false },
        { field: 'questions.length', headerName: 'Question Count', width: 150, editable: false, valueGetter: (params: GridCellParams) => {
                let quickQuestion: IQuickQuestionGroup = params.row as IQuickQuestionGroup;
                return quickQuestion.content.length;
            }},
        { field: 'export_btn', headerName: '', width: 75, renderCell: (params: GridCellParams) => {
                return (<IconButton color={"warning"} onClick={() => {
                    let quickObject: IQuickQuestionGroup = params.row as IQuickQuestionGroup;
                    let fileContent = JSON.stringify(quickObject, null, 4);
                    // Create a Blob from the string data
                    const blob = new Blob([fileContent], { type: 'application/json' });

                    // Create a Blob URL
                    const blobUrl = window.URL.createObjectURL(blob);

                    // Create a download link
                    const downloadLink = document.createElement('a');
                    downloadLink.href = blobUrl;
                    downloadLink.download = params.row._id + ".json_group"; // Specify the file name here

                    downloadLink.click();
                }}><DownloadOutlineIcon /></IconButton>)
            }},


        { field: 'delete_btn', headerName: '', width: 75, renderCell: (params: GridCellParams) => {
                return (<IconButton color={"error"} onClick={() => {
                    if (window.confirm(`Are you sure you want to delete ${params.row.name}?`)) {
                        let item = params.row as IQuickQuestionGroup & { _id: string };
                        if (userToken === null || userToken === undefined) {
                            alert("You must be logged in to export.");
                            return;
                        }
                        new BankDataController_QuickQuestionGroup(userToken).delete(item._id).then(async (response) => {
                            loadData().then();
                        });
                    }
                }}><DeleteIcon /></IconButton>)
            }},
    ]


    const customButtonsMemo = React.useMemo(() => {
        let buttons: AuthenticatedLayoutCustomButton[] = [

        ];

        if (userToken !== null && userToken !== undefined) {

            buttons.push({
                label: "Import",
                action: () => {
                    // setShowAddNewCodeModal(true);

                    // make sure you update the key or remove id from it so it doesn't collide with others.
                    const input = document.createElement('input');
                    input.type = 'file';
                    input.accept = '.json_group';
                    input.onchange = async (e) => {
                        const file = (e.target as HTMLInputElement).files![0];
                        const reader = new FileReader();
                        reader.onload = async (e) => {
                            const text = (e.target as FileReader).result;
                            console.log(`file content: ${text}`);

                            let parsed = JSON.parse(text as string) as IQuickQuestionGroup;
                            console.log(`parsed: ${JSON.stringify(parsed)}`);
                            if (parsed) {
                                console.log(`parsed: ${JSON.stringify(parsed.content)}`);

                                if (parsed.content.length > 0) {
                                    parsed.name = parsed.name + " (imported)";
                                    // console.log(`parsed: ${JSON.stringify(parsed.content.questions[0])}`);
                                    let controller = new BankDataController_QuickQuestionGroup(userToken);
                                    let response = await controller.create(parsed);
                                    console.log(`response: ${JSON.stringify(response)}`);
                                    alert("Imported successfully.")
                                    return
                                }
                            }
                            alert("Failed to parse file.");
                        }
                        reader.readAsText(file);
                    }
                    input.click();
                },
                icon: <FontAwesomeIcon icon={faFileUpload}/>,
                disabled: true,
            });
        }

        return buttons;
    }, [userToken]);


    return (
        <div>
            {/* move the sidebar stuff into a provider system. */}
            <AuthenticatedLayout  pageTitle={"Question Group Bank"}
                                  customButtons={customButtonsMemo}
            >
                <DataGridPro
                    // make sure to set height to 100% so that the grid fills the whole container
                    style={{ height: 'calc( 100% - 5rem )' }}
                    rows={quickQuestionGroupsList}
                    columns={columns}
                    getRowId={(row: GridValidRowModel) => row._id}
                    pageSizeOptions={DEFAULT_PAGE_SIZE_OPTIONS}
                    disableRowSelectionOnClick
                    getDetailPanelHeight={(row) => {
                        return 400;
                    }}
                    getDetailPanelContent={ getDetailPanelContent }

                    pagination={true}
                    rowCount={totalRows}
                    paginationMode={"server"}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                />

            </AuthenticatedLayout>
        </div>
    );
}

export default QuickQuestionGroupView;
