import React from 'react';
import {DataGrid, GridCellParams, GridColDef, GridRowId, GridRowParams, GridValidRowModel} from '@mui/x-data-grid';
import AuthenticatedLayout from "../../Library/AuthenticatedLayout";
import {CustomFormField, ICompany, IProject, ISurvey, IUser} from "../../types/interfaces";
import {CompanyDataController} from "./CompanyDataController";
import {AuthContext} from "../../Library/AuthContext";
import {Box, Button, IconButton, Modal, Stack, Tooltip, Typography} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencil, faPlus, faRotateRight, faTrash} from "@fortawesome/free-solid-svg-icons";
import {CustomFormFieldSizes, CustomFormFieldTypes, UserRole} from "../../types/enums";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {EditObject} from "../../Shared/Components/EditObject";
import {ProjectDataController} from "./ProjectDataController";
import DeleteIcon from "@mui/icons-material/Delete";

// https://mui.com/material-ui/icons/#font-awesome

// type IProjectWithID = IProject &  & { _id : string}

export interface EditProjectButtonProps {
    // userToken: string | null | undefined,
    project: IProject & { _id : string},
    onSave: ((project: IProject & { _id : string}) => Promise<boolean>),
    // usersList: IUser[]
}

export function EditProjectButton(props: EditProjectButtonProps) {

    const {
        // user,
        userToken
    } = React.useContext(AuthContext)!;

    const [show, setShow] = React.useState<boolean>(false);

    const [project, setProject] = React.useState<IProject & { _id : string}>({
        _id: props.project._id,
        name: props.project.name,
        logoBucket: props.project.logoBucket,
        logoKey: props.project.logoKey,
        company: props.project.company
    });

    const form = React.useMemo(() => {
        const form: CustomFormField[] = [
            {
                kind: CustomFormFieldTypes.TEXT,
                size: CustomFormFieldSizes.FULL,
                key: "name",
                label: "Name"
            },
            {
                kind: CustomFormFieldTypes.TEXT,
                size: CustomFormFieldSizes.FULL,
                key: "logoBucket",
                label: "logoBucket"
            },

            {
                kind: CustomFormFieldTypes.TEXT,
                size: CustomFormFieldSizes.FULL,
                key: "logoKey",
                label: "logoKey (JPEG format only)"
            },
        ];

        return form;
    }, [project]);




    React.useEffect(() => {
        setProject(props.project)
    }, [props.project]);

    return (<>
        <Modal
            open={show}
            onClose={() => { setShow(false) }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            {<>
                <Box sx={{
                    position: 'absolute' as 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 600,
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 4
                }}>
                    <Typography id="modal-modal-title" variant="h5" component="h2" sx={{ mb: 2, textAlign: 'center' }}>
                        Edit Project
                    </Typography>

                    <EditObject item={project} setItem={setProject} form={form} columns={1}></EditObject>

                    <br />

                    <Button type={"submit"} variant={"contained"} size={"large"} fullWidth={true} onClick={async () => {
                        // console.log(survey)
                        if (await props.onSave(project)) {
                            setShow(false)
                        }
                     }} disabled={ project === undefined || project.name === "" }>Save</Button>

                </Box>
            </>}
        </Modal>


        <Tooltip title={"Clone Survey"} arrow={true}>
            <Button variant="outlined" color="error" fullWidth={true} onClick={() => {
                setShow(true);
            }}>
                Edit
                {/*<FontAwesomeIcon icon={faCopy} />*/}
            </Button>
        </Tooltip>


    </>);
}




function AddCompanyProject(props: { isEnabled: boolean, company: ICompany & { _id: string }, onSave: ((project: IProject) => Promise<boolean>) })  {

    const [showAddNewProjectModal, setShowAddNewProjectModal] = React.useState<boolean>(false);
    const [project, setProject] = React.useState<IProject>({
        name: "",
        company: props.company._id,
        logoBucket: "",
        logoKey: ""
    });

    const form: CustomFormField[] = [
        {
            kind: CustomFormFieldTypes.TEXT,
            size: CustomFormFieldSizes.FULL,
            key: "name",
            label: "Name"
        },
    ];

    return (<>
            <Button variant={"outlined"} disabled={!props.isEnabled} color={"success"} onClick={() => {
                setShowAddNewProjectModal(true);
            }}><FontAwesomeIcon icon={faPlus} />&nbsp;Create Project</Button>
        <Modal
            open={showAddNewProjectModal}
            onClose={() => { setShowAddNewProjectModal(false) }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{
                position: 'absolute' as 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                border: '2px solid #000',
                boxShadow: 24,
                p: 4
            }}>
                <Typography id="modal-modal-title" variant="h5" component="h2" sx={{ mb: 2, textAlign: 'center' }}>
                    Add New Project: {props.company.name}
                </Typography>

                <EditObject item={project} setItem={setProject} form={form} columns={5}></EditObject>

                <br />

                <Button type={"submit"} variant={"contained"} size={"large"} fullWidth={true} onClick={async () => {
                    console.log(project)
                    project.name = project.name.trim();
                    await props.onSave(project);
                    setShowAddNewProjectModal(false);
                    setProject({
                        name: "",
                        company: props.company._id,
                        logoBucket: "",
                        logoKey: ""
                    })
                }} disabled={ project === undefined || project['name'] === "" }>CREATE</Button>

            </Box>
        </Modal>
    </>);
}


function ProjectsManagementView() {

    const {
        // user,
        userToken
    } = React.useContext(AuthContext)!;

    const [showAddNewCompanyModal, setShowAddNewCompanyModal] = React.useState<boolean>(false);

    const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = React.useState<
        GridRowId[]
    >([]);

    const handleDetailPanelExpandedRowIdsChange = React.useCallback(
        (newIds: GridRowId[]) => {
            setDetailPanelExpandedRowIds(
                newIds.length > 1 ? [newIds[newIds.length - 1]] : newIds,
            );
        },
        [],
    );

    const columns: GridColDef[] = [

        // { field: '_id', headerName: 'ID', width: 250, editable: false },
        { field: '_id', headerName: 'ID', width: 400, editable: false,
            renderCell: (params) => (
                <>
                    <Stack direction={"row"} sx={{ width: '100%' }}>

                        <Button variant="outlined" color="primary" size={"small"} disabled={ (params.row as IUser).role === UserRole.ADMIN || (params.row as IUser).isDeactivated } fullWidth={false} onClick={async () => {
                            // let user = params.row as IUser;
                            try {
                                await navigator.clipboard.writeText(params.value);
                                console.log('Text copied to clipboard');
                            } catch (err) {
                                console.error('Failed to copy: ', err);
                            }
                        }}>Copy</Button>

                        <Typography sx={{ padding: "3px" }}>&nbsp;{params.value}</Typography>
                    </Stack>
                </>
            ),
        },
        { field: 'name', headerName: 'Name', width: 250, editable: false },
        { field: 'count_project', headerName: 'Project Count', width: 120, editable: false, valueGetter: (params) => {
            return (params.row as ICompany).projects.length;
        }},
        { field: 'create_project', headerName: '',
            width: 200,
            renderCell: (params: GridCellParams) => {

            return (<AddCompanyProject company={params.row as ICompany & {_id: string}} isEnabled={params.id === detailPanelExpandedRowIds[0]} onSave={async (project: IProject) => {
                if (userToken === null || userToken === undefined) {
                    return false;
                }
                let result = await new ProjectDataController(userToken).create(project) as IProject & {_id: string};

                let company = {...params.row} as ICompany & {_id: string};
                company.projects = [...company.projects, result._id] as IProject[];

                await new CompanyDataController(userToken).update(company._id, company);
                let all = await new CompanyDataController(userToken).getAll();
                setList(all);
                return true;
            }}/>)
        }},
        // { field: 'edit_btn_n', headerName: '', width: 75, renderCell: (params: GridCellParams) => {
        //         return (<EditProjectButton project={params.row as IProject & {_id: string}} updateList={async () => {
        //             await new CompanyDataController(userToken).update(company._id, company);
        //             let all = await new CompanyDataController(userToken).getAll();
        //             setList(all);
        //         }} />)
        //     }},
        { field: 'edit_btn', headerName: '', width: 75, renderCell: (params: GridCellParams) => {
            return (<IconButton color={"warning"} onClick={() => {
                if (userToken === null || userToken === undefined) {
                    alert("You must be logged in to rename.");
                    return;
                }
                let newName = window.prompt("Enter the new name for the company", params.row.name);
                if (newName === null || newName === undefined) {
                    return;
                }
                newName = newName.trim();
                if (newName === "") {
                    return;
                }
                let company = {...params.row} as ICompany & {_id: string};
                company.name = newName;

                new CompanyDataController(userToken).update(company._id, company).then(async (response) => {
                    let all = await new CompanyDataController(userToken).getAll();
                    setList(all);
                });
            }}><FontAwesomeIcon icon={faPencil} /></IconButton>)
        }},
        { field: 'delete_btn', headerName: '', width: 75, renderCell: (params: GridCellParams) => {
            return (<IconButton color={"error"} onClick={() => {
                if (window.confirm(`Are you sure you want to delete "${params.row.name}"?`)) {
                    let item = params.row as IProject & { _id: string };
                    if (userToken === null || userToken === undefined) {
                        alert("You must be logged in to delete.");
                        return;
                    }
                    let company = {...params.row} as ICompany & {_id: string};

                    let projectDataController = new ProjectDataController(userToken);
                    let promises = company.projects.map((project) => {
                        return projectDataController.delete((project as IProject & {_id: string})._id);
                    });
                    Promise.all(promises).then(async (response) => {
                        await new CompanyDataController(userToken).delete(company._id);
                        let all = await new CompanyDataController(userToken).getAll();
                        setList(all);
                    });
                }
            }}><DeleteIcon /></IconButton>)
        }},
    ]



    const loadData = React.useCallback(async () => {
        if (userToken === null || userToken === undefined) {
            return;
        }
        let all = await new CompanyDataController(userToken).getAll();
        setList(all);
    }, [userToken]); // Dependencies array


    React.useEffect(() => {

        loadData().then();
    }, [userToken, loadData]);

    const [list, setList] = React.useState<ICompany[]>([]);

    // const [searchText, setSearchText] = React.useState<string>("");
    //
    //
    // const filteredList = React.useMemo(() => {
    //     return list.filter((survey) => {
    //
    //         if ( survey.survey_id.caseNumber.toLowerCase().includes(searchText.toLowerCase())) {
    //             return true;
    //         }
    //
    //         // if (survey.survey_id.assigned_user_id !== undefined && survey.survey_id.assigned_user_id !== null) {
    //         //     if ((survey.survey_id.assigned_user_id as IUser).name.toLowerCase().includes(searchText.toLowerCase())) {
    //         //         return true;
    //         //     }
    //         // }
    //
    //         return false;
    //     });
    // }, [list, searchText]);

    const getDetailPanelContent = React.useCallback(
        ({ row }: GridRowParams) => {
            let rowData = row as ICompany & { _id: string };

            return (<>
                <div style={{padding: '1rem', height: '100%'}}>
                    {/*<DebugView value={rowData} />*/}
                    <DataGrid
                        // make sure to set height to 100% so that the grid fills the whole container
                        style={{height: '100%'}}
                        rows={rowData.projects as any[]}
                        columns={[
                            // { field: '_id', headerName: 'ID', width: 250, editable: false },
                            { field: '_id', headerName: 'ID', width: 400, editable: false,
                                renderCell: (params) => (
                                    <>
                                        <Stack direction={"row"} sx={{ width: '100%' }}>

                                            <Button variant="outlined" color="primary" size={"small"} disabled={ (params.row as IUser).role === UserRole.ADMIN || (params.row as IUser).isDeactivated } fullWidth={false} onClick={async () => {
                                                // let user = params.row as IUser;
                                                try {
                                                    await navigator.clipboard.writeText(params.value);
                                                    console.log('Text copied to clipboard');
                                                } catch (err) {
                                                    console.error('Failed to copy: ', err);
                                                }
                                            }}>Copy</Button>

                                            <Typography sx={{ padding: "3px" }}>&nbsp;{params.value}</Typography>
                                        </Stack>
                                    </>
                                ),
                            },
                            { field: 'name', headerName: 'Name', width: 300, editable: false },

                            // { field: 'edit_btn_bucket', headerName: 'Bucket', width: 75, renderCell: (params: GridCellParams) => {
                            //     return (<Button color={"warning"} onClick={() => {
                            //         if (userToken === null || userToken === undefined) {
                            //             alert("You must be logged in to rename.");
                            //             return;
                            //         }
                            //         let newName = window.prompt("Enter the bucket", params.row.name);
                            //         if (newName === null || newName === undefined) {
                            //             return;
                            //         }
                            //         newName = newName.trim();
                            //         if (newName === "") {
                            //             return;
                            //         }
                            //         let project = {...params.row} as IProject & {_id: string};
                            //         if (project.logo === undefined){
                            //             project.logo = {
                            //                 bucket: "",
                            //                 key: ""
                            //             }
                            //         }
                            //         project.logo.bucket = newName;
                            //
                            //         new ProjectDataController(userToken).update(project._id, project).then(async (response) => {
                            //             let all = await new CompanyDataController(userToken).getAll();
                            //             setList(all);
                            //         });
                            //     }}>Edit {params.row.bucket}</Button>)
                            // }},
                            // { field: 'edit_btn_key', headerName: 'Key', width: 75, renderCell: (params: GridCellParams) => {
                            //     return (<Button color={"warning"} onClick={() => {
                            //         if (userToken === null || userToken === undefined) {
                            //             alert("You must be logged in to rename.");
                            //             return;
                            //         }
                            //         let newName = window.prompt("Enter the bucket", params.row.name);
                            //         if (newName === null || newName === undefined) {
                            //             return;
                            //         }
                            //         newName = newName.trim();
                            //         if (newName === "") {
                            //             return;
                            //         }
                            //         let project = {...params.row} as IProject & {_id: string};
                            //         if (project.logo === undefined){
                            //             project.logo = {
                            //                 bucket: "",
                            //                 key: ""
                            //             }
                            //         }
                            //         project.logo.key = newName;
                            //
                            //         new ProjectDataController(userToken).update(project._id, project).then(async (response) => {
                            //             let all = await new CompanyDataController(userToken).getAll();
                            //             setList(all);
                            //         });
                            //     }}>Edit {params.row.key}</Button>)
                            // }},

                            { field: 'edit_btn_n', headerName: '', width: 75, renderCell: (params: GridCellParams) => {
                                return (<EditProjectButton project={params.row as IProject & {_id: string}} onSave={async (project) => {
                                    if (userToken !== undefined && userToken !== null) {
                                        await new ProjectDataController(userToken).update(project._id, project);
                                        let all = await new CompanyDataController(userToken).getAll();
                                        setList(all);
                                        return true
                                    }
                                    return false
                                }} />)
                            }},


                            { field: 'edit_btn', headerName: '', width: 75, renderCell: (params: GridCellParams) => {
                                return (<IconButton color={"warning"} onClick={() => {
                                    if (userToken === null || userToken === undefined) {
                                        alert("You must be logged in to rename.");
                                        return;
                                    }
                                    let newName = window.prompt("Enter the new name for the project", params.row.name);
                                    if (newName === null || newName === undefined) {
                                        return;
                                    }
                                    newName = newName.trim();
                                    if (newName === "") {
                                        return;
                                    }
                                    let project = {...params.row} as IProject & {_id: string};
                                    project.name = newName;

                                    new ProjectDataController(userToken).update(project._id, project).then(async (response) => {
                                        let all = await new CompanyDataController(userToken).getAll();
                                        setList(all);
                                    });
                                }}><FontAwesomeIcon icon={faPencil} /></IconButton>)
                            }},
                            // add remove button
                            { field: 'delete_btn', headerName: '', width: 75, renderCell: (params: GridCellParams) => {
                                return (<IconButton color={"error"} onClick={() => {
                                    if (window.confirm(`Are you sure you want to delete ${params.row.key}?`)) {
                                        let item = params.row as IProject & { _id: string };
                                        if (userToken === null || userToken === undefined) {
                                            alert("You must be logged in to delete.");
                                            return;
                                        }
                                        new ProjectDataController(userToken).delete(item._id).then(async (response) => {
                                            // update the company
                                            let company = rowData;
                                            company.projects = (company.projects as (IProject & { _id: string })[]).filter((project) => {
                                                return project._id !== item._id;
                                            })
                                            await new CompanyDataController(userToken).update(company._id, company);

                                            let all = await new CompanyDataController(userToken).getAll();
                                            setList(all);
                                        });
                                    }
                                }}><DeleteIcon /></IconButton>)
                            }},
                        ]}
                        getRowId={(row: GridValidRowModel) => row._id}
                        pageSizeOptions={[100]}
                        disableRowSelectionOnClick
                        initialState={{
                            sorting: {
                                sortModel: [{ field: 'date', sort: 'desc' }],
                            },
                        }}
                    />
                </div>

            </>)
        },
        [userToken],
    );

    return (
        <div>
            {/* move the sidebar stuff into a provider system. */}
            <AuthenticatedLayout  pageTitle={"Clients | Project Management"}
                                  // searchText={{text: searchText, setText: setSearchText, label: "Search (Case Number)"}}
                customButtons={[
                    {
                        label: "Create",
                        action: () => {
                            setShowAddNewCompanyModal(true);
                        },
                        icon: <FontAwesomeIcon icon={faPlus} />
                    },
                    {
                        label: "Reload",
                        action: () => {
                            loadData().then();
                        },
                        icon: <FontAwesomeIcon icon={faRotateRight} />
                    }
                ]}
            >
                {/* TODO: add some controlled thing, so that it will open the newly created company */}
                {/* Also disable the create project button when the company is collapsed. */}
                <DataGridPro
                    // make sure to set height to 100% so that the grid fills the whole container
                    style={{ height: '100%' }}
                    rows={list}
                    columns={columns}
                    getRowId={(row: GridValidRowModel) => row._id}
                    pageSizeOptions={[100]}
                    disableRowSelectionOnClick
                    getDetailPanelHeight={(row) => {
                        return  500;
                        // let count = (row.row as ICompany).projects.length;
                        // if (count === 0) {
                        //     return 275;
                        // }
                        // return 150 + ( count * 51);
                    }}
                    getDetailPanelContent={getDetailPanelContent}
                detailPanelExpandedRowIds={detailPanelExpandedRowIds}
                onDetailPanelExpandedRowIdsChange={handleDetailPanelExpandedRowIdsChange}
                />

                <Modal
                    open={showAddNewCompanyModal}
                    onClose={() => { setShowAddNewCompanyModal(false) }}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    {<>
                        <AddNewCompany
                           onSave={async (company: ICompany) => {
                                if (userToken === null || userToken === undefined) {
                                    return;
                                }
                                company.name = company.name.trim();
                                await new CompanyDataController(userToken).create(company);
                                let all = await new CompanyDataController(userToken).getAll();
                                setList(all);
                               setShowAddNewCompanyModal(false);
                            }}
                            onCancel={() => { setShowAddNewCompanyModal(false) }}
                        ></AddNewCompany>
                    </>}
                </Modal>

            </AuthenticatedLayout>
        </div>
    );
}


function AddNewCompany(props: { onSave: ((company: ICompany) => void), onCancel: (() => void) })  {

    const form: CustomFormField[] = [
        {
            kind: CustomFormFieldTypes.TEXT,
            size: CustomFormFieldSizes.FULL,
            key: "name",
            label: "Name"
        },
    ];
    const [company, setCompany] = React.useState<ICompany>({
        name: "",
        projects: []
        // selected technician
    });

    return (
        <Box sx={{
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4
        }}>
            <Typography id="modal-modal-title" variant="h5" component="h2" sx={{ mb: 2, textAlign: 'center' }}>
                Add New Client
            </Typography>

            <EditObject item={company} setItem={setCompany} form={form} columns={12}></EditObject>

            <br />

            <Button type={"submit"} variant={"contained"} size={"large"} fullWidth={true} onClick={() => {
                props.onSave(company);
            }} disabled={ company === undefined || company['name'] === "" }>CREATE</Button>

        </Box>
    );
}



export default ProjectsManagementView;
