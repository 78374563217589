import {MultiSelectAnswer} from "../../../../types/interfaces";
import {Paper, Switch, Toolbar, Typography} from "@mui/material";
import React from "react";
import {AnswerViewProps} from "../SurveyAnswerEdit";

export function MultiSelectAnswerView(props: AnswerViewProps) {
    const { answer } = props;

    let multiSelectAnswer: MultiSelectAnswer = answer as MultiSelectAnswer;
    if (multiSelectAnswer === undefined) {
        multiSelectAnswer = { multiSelect: { data: [ ] } } as MultiSelectAnswer;
    }

    return (<>

        <Paper style={{ padding: '1rem' }}>
            <Toolbar variant={"dense"} color={"primary"}>
                <Typography variant={"h6"}>{props.question.questionText}</Typography>
            </Toolbar>


            <div>
                {(props.question.type.options ?? []).map((option, index) => {


                    return (
                        <div key={index} style={{ padding: '0.5rem' }}>
                            <Switch
                                disabled={props.surveyAnswer.isCompleted || props.disabled}
                                checked={multiSelectAnswer.multiSelect.data.includes(option)}
                                onChange={(e) => {
                                    let newMultiSelectAnswer = { multiSelect: { data: [ ...multiSelectAnswer.multiSelect.data ] } } as MultiSelectAnswer;
                                    if (e.target.checked) {
                                        newMultiSelectAnswer.multiSelect.data.push(option);
                                    } else {
                                        newMultiSelectAnswer.multiSelect.data = newMultiSelectAnswer.multiSelect.data.filter((item) => item !== option);
                                    }
                                    let surveyAnswerObject = props.surveyAnswer.answers[props.surveyObject.key];
                                    if (surveyAnswerObject === undefined || surveyAnswerObject === null || surveyAnswerObject.values === undefined || surveyAnswerObject.values === null) {
                                        surveyAnswerObject = { values: {} };
                                    }
                                    let newValues = surveyAnswerObject.values;
                                    newValues[props.question.key] = newMultiSelectAnswer;
                                    let newSurveyAnswer = {...props.surveyAnswer, answers: {...props.surveyAnswer.answers, [props.surveyObject.key]: {...surveyAnswerObject, values: newValues}}};
                                    props.setSurveyAnswer(newSurveyAnswer);
                                }}
                            />
                            <label>{option}</label>
                        </div>
                    );

                })}
            </div>
        </Paper>
    </>);
}
