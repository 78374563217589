// https://saiankit.medium.com/setting-up-mongodb-on-macos-catalina-common-errors-and-their-solutions-72fd66fb8a86
import React from 'react';
import {
    AppBar,
    Box,
    Button,
    Modal,
    Grid,
    Paper,
    Toolbar,
    Typography,
    Stack
} from "@mui/material";
import { useNavigate } from 'react-router-dom';
import {AuthContext} from "../Library/AuthContext";
import {LoginModal} from "./LoginModal";
import {AccountRecoveryModal} from "./AccountRecoveryModal";

enum UnauthenticatedViewModalMode {
    Hidden,
    Login,
    Register,
    AccountRecovery
}

function UnauthenticatedMainView() {
    const navigate = useNavigate();

    const {
        userToken
    } = React.useContext(AuthContext)!;


    const [mode, setMode] = React.useState<UnauthenticatedViewModalMode>(UnauthenticatedViewModalMode.Hidden);

    const baseURL = process.env.REACT_APP_SERVER_URL || "127.0.0.1:3000";
    let titleText = "POST"
    switch (baseURL) {
        case "127.0.0.1:3000":
            titleText += " (Local)"
            break;
        case "survey-stage.post.pivital.com":
            titleText += " (Stage)"
            break;
        case "survey.post.pivital.com":
            titleText += " (Prod)"
            break;
        case "survey-demo.post.pivital.com":
            titleText += " (Demo)"
            break;
        default:
            break;
    }

    return (
            <div>
                <AppBar position="static">
                    <Toolbar variant="dense">
                        <Typography variant="h6" color="inherit" component="div">
                            {titleText}
                        </Typography>
                        <Box sx={{ flexGrow: 1 }}></Box>


                        {(userToken === null || userToken === undefined)  && (<>
                            <Button onClick={() => {
                                setMode(UnauthenticatedViewModalMode.Login);
                            }} color="secondary">Login</Button>
                        </>)}

                        {userToken !== undefined && userToken !== null && (<>
                            <Button onClick={() => {
                                navigate('/dashboard');
                            }}  color="secondary">Dashboard</Button>
                        </>)}
                    </Toolbar>
                </AppBar>

                <Grid container spacing={0}>



                    <Grid item xs>
                        <Paper elevation={1} style={{
                            margin: '1rem', height: 'calc( var(--app-height) - 2rem - 48px )', width:"calc ( 100% - 2rem )", padding: '1rem',
                        }}>
                            <Paper elevation={2} style={{ padding: '1rem',
                                height: 'calc( 100% )', overflow: 'auto'
                            }}>
                                <Stack style={{textAlign: "center", width: '100%'}}>

                                    <Typography variant="h4" component="h2" sx={{ mb: 2, textAlign: 'center' }}>
                                        POST@Pivital
                                    </Typography>
                                </Stack>
                            </Paper>
                            <br />

                        </Paper>
                    </Grid>

                </Grid>


                <Modal
                    open={mode !== UnauthenticatedViewModalMode.Hidden}
                    onClose={() => { setMode(UnauthenticatedViewModalMode.Hidden) }}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    {<>
                        {mode === UnauthenticatedViewModalMode.Login && (<LoginModal openAccountRecovery={() => { setMode(UnauthenticatedViewModalMode.AccountRecovery) }}/>)}
                        {mode === UnauthenticatedViewModalMode.AccountRecovery && (<AccountRecoveryModal closeAccountRecovery={ () => { setMode(UnauthenticatedViewModalMode.Hidden) } }/>)}
                    </>}
                </Modal>

            </div>
    );
}

export default UnauthenticatedMainView;
