import {
    DataController,
    ISurveyAnswer
} from "../../types/interfaces";
import {ReportType} from "../../types/enums";

export class ReportDataController implements DataController<ISurveyAnswer> {

    private readonly baseURL : string

    constructor(private userToken: string) {
        // Nothing to do here yet.
        // const WS_URL = `ws${ process.env.REACT_APP_SERVER_URL ? "s" :"" }://${baseURL}`;
        const domainURL: string = process.env.REACT_APP_SERVER_URL || "127.0.0.1:3000";
        const protocol: string = `http${ process.env.REACT_APP_SERVER_URL ? "s" :"" }`;
        this.baseURL = `${protocol}://${domainURL}`;
    }

    public async getAll(): Promise<ISurveyAnswer[]> {
        let results = await fetch(`${this.baseURL}/api/surveyAnswers`, {
            headers: {
                "authorization": `Bearer ${this.userToken}`
            }
        });
        let data = await results.json();
        return await data; // this doesn't seem to type check.
    }

    public async getOneByAnswerId(id: String): Promise<ISurveyAnswer> {
        let results = await fetch(`${this.baseURL}/api/surveyAnswers/answer/${id}`, {
            headers: {
                "authorization": `Bearer ${this.userToken}`
            }
        });
        let data = await results.json();
        // console.log(data);
        return await data; // this doesn't seem to type check.
    }

    public async getOne(id: String): Promise<ISurveyAnswer> {
        let results = await fetch(`${this.baseURL}/api/surveyAnswers/${id}`, {
            headers: {
                "authorization": `Bearer ${this.userToken}`
            }
        });
        let data = await results.json();
        // console.log(data);
        return await data; // this doesn't seem to type check.
    }

    public async create(item: ISurveyAnswer): Promise<ISurveyAnswer> {
        let results = await fetch(`${this.baseURL}/api/surveyAnswers/`, {
            method: "POST",
            headers: {
                "authorization": `Bearer ${this.userToken}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(item)
        });
        let data = await results.json();
        console.log(data);
        return await data.item; // this doesn't seem to type check.
    }

    public async update(id: string, item: ISurveyAnswer): Promise<ISurveyAnswer> {
        let results = await fetch(`${this.baseURL}/api/surveyAnswers/${id}`, {
            method: "PUT",
            headers: {
                "authorization": `Bearer ${this.userToken}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(item)
        });

        // handle when results is 304
        if (results.status === 304) {
            console.log("304");
            return item;
        }

        console.log(results)
        let data = await results.json();
        console.log(data);
        return await data; // this doesn't seem to type check. change to .item when api updated
    }

    // public async delete(id: string): Promise<ISurvey> {
    //     let results = await fetch(`${this.baseURL}/api/surveys/${id}`, {
    //         method: "DELETE",
    //         headers: {
    //             "authorization": `Bearer ${this.userToken}`,
    //             "Content-Type": "application/json"
    //         }
    //         // body: JSON.stringify(item)
    //     });
    //
    //     console.log(results)
    //     let data = await results.json();
    //     console.log(data);
    //     return await data; // this doesn't seem to type check. change to .item when api updated
    // }



    public async generate(id: String, type: ReportType): Promise<ISurveyAnswer> {
        let results = await fetch(`${this.baseURL}/api/report/generate/${id}/${type}`, {
            headers: {
                "authorization": `Bearer ${this.userToken}`
            }
        });
        let data = await results.json();
        // console.log(data);
        return await data; // this doesn't seem to type check.
    }

    public async downloadReport(bucket: string, key: string): Promise<string> {
        let results = await fetch(`${this.baseURL}/api/report/download/${bucket}/${key}`, {
            headers: {
                "authorization": `Bearer ${this.userToken}`
            }
        });
        let data = await results.json();
        console.log(data);

        return data.url;
    }

    public async deleteReport(bucket: string, key: string): Promise<boolean> {
        let results = await fetch(`${this.baseURL}/api/report/download/${encodeURIComponent(bucket)}/${encodeURIComponent(key)}`, {
            method: "DELETE",
            headers: {
                "authorization": `Bearer ${this.userToken}`,
                "Content-Type": "application/json"
            }
            // body: JSON.stringify(item)
        });

        return results.status === 200;
        // console.log(results)
        // let data = await results.json();
        // console.log(data);
        // return await data; // this doesn't seem to type check. change to .item when api updated
    }
}


export class SurveyAnswerDataController {

    private readonly baseURL : string

    constructor(private userToken: string) {
        // Nothing to do here yet.
        // const WS_URL = `ws${ process.env.REACT_APP_SERVER_URL ? "s" :"" }://${baseURL}`;
        const domainURL: string = process.env.REACT_APP_SERVER_URL || "127.0.0.1:3000";
        const protocol: string = `http${ process.env.REACT_APP_SERVER_URL ? "s" :"" }`;
        this.baseURL = `${protocol}://${domainURL}`;
    }

    public async create(survey_id: String, item: ISurveyAnswer): Promise<ISurveyAnswer> {
        let results = await fetch(`${this.baseURL}/api/surveyAnswers/${survey_id}`, {
            method: "POST",
            headers: {
                "authorization": `Bearer ${this.userToken}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(item)
        });
        let data = await results.json();
        console.log(data);
        return await data.item; // this doesn't seem to type check.
    }


}
