import {CustomFormField, ICompany, IProject, IUser} from "../../types/interfaces";
import React from "react";
import {AuthContext} from "../../Library/AuthContext";
import {CompanyDataController} from "../Projects/CompanyDataController";
import {CustomFormFieldSizes, CustomFormFieldTypes, UserRole} from "../../types/enums";
import {Box, Button, Modal, Typography} from "@mui/material";
import {EditObject} from "../../Shared/Components/EditObject";

export const AddNewUser = ({ open, onClose, onAddNewUser } : { open: boolean, onClose: () => void, onAddNewUser: (user: IUser) => Promise<boolean> }) => {
    const [item, setItem] = React.useState<any>({});

    const {
        userToken,
    } = React.useContext(AuthContext)!;

    const loadData = React.useCallback(async () => {
        if (userToken === null || userToken === undefined) {
            return;
        }
        let all = await new CompanyDataController(userToken).getAll();
        setCompanies(all);
    }, [userToken]); // Dependencies array


    React.useEffect(() => {

        loadData().then();
    }, [userToken, loadData]);

    const [companies, setCompanies] = React.useState<ICompany[]>([]);
    const projectsMemo = React.useMemo(() => {
        if (companies === undefined || companies === null || companies.length === 0) {
            return [];
        }
        if (item.company === null) {
            return [];
        }
        return companies.find((company) => {
            return (company as ICompany & {_id: string})._id === item.company;
        })?.projects as IProject[] ?? [];
    }, [companies, item ]);


    const fields = React.useMemo(
        () => {
            let fields: CustomFormField[] = [
                {
                    kind: CustomFormFieldTypes.TEXT,
                    size: CustomFormFieldSizes.FULL,
                    key: "name",
                    label: "Name",
                },
                {
                    kind: CustomFormFieldTypes.TEXT,
                    size: CustomFormFieldSizes.FULL,
                    key: "email_address",
                    label: "Email Address",
                },
                {
                    kind: CustomFormFieldTypes.TEXT,
                    size: CustomFormFieldSizes.FULL,
                    key: "phone_number",
                    label: "Phone Number",
                },
                {
                    kind: CustomFormFieldTypes.SELECT,
                    size: CustomFormFieldSizes.FULL,
                    key: "role",
                    label: "Role",
                    options: [
                        { label: "Admin", value: UserRole.ADMIN },
                        { label: "User Manager", value: UserRole.USER_MANAGER},
                        { label: "Manager / PC", value: UserRole.MANAGER },
                        { label: "Technician", value: UserRole.TECHNICIAN },
                        { label: "Client", value: UserRole.CLIENT }
                    ]
                },

                {
                    kind: CustomFormFieldTypes.SELECT,
                    size: CustomFormFieldSizes.HALF,
                    key: "company",
                    label: "Client",
                    options: [
                        { label: "Un-assigned", value: null },
                        ...companies.map((opt: (ICompany)) => { return { label: opt.name, value: (opt as ICompany & {_id: string})._id }; })
                    ]
                },
                {
                    kind: CustomFormFieldTypes.SELECT,
                    size: CustomFormFieldSizes.HALF,
                    key: "project",
                    label: "Project",
                    options: [
                        { label: "Un-assigned", value: null },
                        ...projectsMemo.map((opt: (IProject)) => { return { label: opt.name, value: (opt as IProject & {_id: string})._id }; })
                    ],
                    readonly: item.company === null
                },
            ];


            return fields;
        },
        [item, projectsMemo, companies]
    );

    const disableButton = React.useMemo(() => {
        return item.name === undefined || item.name === "" ||
            item.email_address === undefined || item.email_address === "" ||
            item.phone_number === undefined || item.phone_number === "" ||
            item.role === undefined || item.role === "";
    }, [item]);

    return (<>
        <Modal open={open} onClose={() => {
            setItem({});
            onClose();
        }}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '60%',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                }}
            >
                <Typography id="modal-modal-title" variant="h5" component="h2" sx={{ mb: 2, textAlign: 'center' }}>
                    Create New User
                </Typography>
                <EditObject
                    item={item}
                    setItem={setItem}
                    form={fields}
                    columns={12}
                />
                <br />

                <Button type={"submit"} variant={"contained"} size={"large"} disabled={disableButton} fullWidth={true} onClick={async () => {
                    let userToCreate: IUser = {
                        _id: "", // this will be ignored by the server.
                        name: item.name,
                        email_address: item.email_address,
                        phone_number: item.phone_number,
                        role: item.role,
                        activeSessions: [],
                        projects: [],
                    };

                    if (item.project !== null) {
                        userToCreate.projects = [item.project];
                    }



                    try {
                        if (await onAddNewUser(userToCreate)) {
                            setItem({});
                            onClose();
                        } else {
                            // alert("Invalid User");
                            alert("Could not create user");
                        }
                    }
                    catch (e) {
                        const error = e as Error;
                        console.log(error);
                        // alert("Error Creating User");
                        switch (error.message) {
                            case "Conflict":
                                alert("User with this email address already exists.");
                                return;
                        }
                        alert(`Could not create user, error occurred. ${error.message}`);
                    }


                }}>CREATE</Button>
            </Box>
        </Modal>
    </>);
};
