import {CustomFormField, ICondition, ISurvey, SurveyObject, SurveyObjectQuestion} from "../../../types/interfaces";
import React, {Dispatch} from "react";
import {CustomFormFieldSizes, CustomFormFieldTypes} from "../../../types/enums";
import {Accordion, AccordionDetails, AccordionSummary, Paper, Stack, Typography} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {EditObject} from "../../../Shared/Components/EditObject";
import { MultipleConditionStack } from "./MultipleConditionStack";

export const MultipleConditionSingleConditionStaticConditionEditorPassThru = (props: { conditionKey: "required" | "showInExceptionReport" | "showInStandardReport", label: string,  survey: ISurvey, surveyObject: SurveyObject, item: any, setItem: Dispatch<any>, field: CustomFormField, expanded: string | false, handleAccordionChange: any }) => {
    enum RequiredOptions {
        Boolean = "Boolean",
        SingleCondition = "SingleCondition",
        MultipleConditions = "MultipleConditions",
    }

    const [activeType, setActiveType] = React.useState<{type:RequiredOptions}>({type:RequiredOptions.Boolean});

    const quickConditions = React.useMemo(() => {
        let allowedTypes = ["select", "toggle"];
        let filteredQuestions = props.surveyObject.questions.filter((question) => allowedTypes.includes(question.type.type) );

        let quickConditions: ICondition[] = [];
        filteredQuestions.forEach((question: SurveyObjectQuestion) => {
            switch (question.type.type) {
                case "select":
                    question.type.options?.forEach((option: string) => {
                        quickConditions.push({
                            key: question.key,
                            op: "=",
                            value: option
                        });

                        quickConditions.push({
                            key: question.key,
                            op: "≠",
                            value: option
                        });
                    })

                    break;

                case "toggle":
                    quickConditions.push({
                        key: question.key,
                        op: "=",
                        value: true
                    });

                    quickConditions.push({
                        key: question.key,
                        op: "=",
                        value: false
                    });


            }
        });

        return quickConditions;
    }, [ props.surveyObject.questions ]);

    const activeTypeMemoFields = React.useMemo(() => {
        let fields: CustomFormField[] = []

        switch (activeType.type) {
            case RequiredOptions.Boolean:
                fields.push({
                    kind: CustomFormFieldTypes.SELECT,
                    size: CustomFormFieldSizes.FULL,
                    key: props.conditionKey,
                    label: props.label,
                    options: [
                        { label: "Always", value: true },
                        { label: "Never", value: false },
                    ]
                })

                break;
            case RequiredOptions.SingleCondition:
                fields.push({
                    kind: CustomFormFieldTypes.SELECT,
                    size: CustomFormFieldSizes.FULL,
                    key: props.conditionKey,
                    label: props.label,
                    options: [
                        ...quickConditions.map((condition) => {
                            let selectedQuestions = props.surveyObject.questions.filter((q) => q.key === condition.key)[0];
                            return {label: `${selectedQuestions.questionText} (${condition.key}) ${condition.op} ${condition.value}`, value: condition }
                        }),
                    ]
                })
                break;
            case RequiredOptions.MultipleConditions:
                break;
        }

        return fields;

    }, [activeType.type, quickConditions, props.surveyObject.questions, RequiredOptions.Boolean, RequiredOptions.MultipleConditions, RequiredOptions.SingleCondition, props.conditionKey, props.label ]);

    // TODO: set the initial active type based on the item[key] value
    React.useEffect(() => {
        if (Array.isArray(props.item[props.conditionKey])) {
            setActiveType({type:RequiredOptions.MultipleConditions});
        }
        else if (typeof props.item[props.conditionKey] === "object") {
            setActiveType({type:RequiredOptions.SingleCondition});
        }
        else {
            setActiveType({type:RequiredOptions.Boolean});
        }
    }, [ RequiredOptions.Boolean, RequiredOptions.MultipleConditions, RequiredOptions.SingleCondition, props.conditionKey, props.item ]);

    return (<>

        <Accordion expanded={props.expanded === props.conditionKey} onChange={props.handleAccordionChange(props.conditionKey)}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
            >
                <Typography sx={{ width: '33%', flexShrink: 0 }}>
                    {props.label}
                </Typography>
                {/*<Typography sx={{ color: 'text.secondary' }}></Typography>*/}
            </AccordionSummary>
            <AccordionDetails>
                <Paper variant={"outlined"} sx={{padding: 2}}>


                    <Stack direction={"row"} spacing={2} style={{ display: 'flex', width: '100%' }}>
                        <Paper style={{ flex: 1, padding: '1rem' }}>
                            Type
                            <hr />
                            <EditObject item={activeType} setItem={setActiveType} form={[
                                {
                                    kind: CustomFormFieldTypes.RADIO_GROUP,
                                    size: CustomFormFieldSizes.FULL,
                                    key: "type",
                                    label: props.label,
                                    options: [
                                        { label: "Static", value: RequiredOptions.Boolean },
                                        { label: "Single Condition", value: RequiredOptions.SingleCondition },
                                        { label: "Multiple Conditions [OR]", value: RequiredOptions.MultipleConditions },
                                    ]
                                }
                            ]} columns={12} />
                        </Paper>

                        <Paper style={{ flex: 2, padding: '1rem' }}>
                            Configuration
                            <hr />
                            {(activeType.type === RequiredOptions.Boolean || activeType.type === RequiredOptions.SingleCondition) && (
                                <EditObject item={props.item} setItem={props.setItem} form={activeTypeMemoFields} columns={12} />
                            )}

                            {activeType.type === RequiredOptions.MultipleConditions && (
                                <>
                                    <MultipleConditionStack quickConditions={quickConditions} item={props.item}  setItem={props.setItem} conditionKey={props.conditionKey} surveyObject={props.surveyObject} />
                                </>
                            )}

                            {/*<DebugView value={props.item[props.conditionKey]} />*/}
                        </Paper>
                    </Stack>
                </Paper>
            </AccordionDetails>
        </Accordion>

    </>);

};
