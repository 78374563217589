import {
    CustomFormField,
    ICompany,
    IProject,
    ISurvey,
    IUser,
} from "../../types/interfaces";
import React from "react";
import * as XLSX from "xlsx";
import {CustomFormFieldSizes, CustomFormFieldTypes, UserRole} from "../../types/enums";
import {DataGrid, GridColDef} from "@mui/x-data-grid";
import {Stack} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {EditObject} from "../../Shared/Components/EditObject";
import {AuthContext} from "../../Library/AuthContext";
import {UserDataController} from "../Users/UserDataController";
import AuthenticatedLayout from "../../Library/AuthenticatedLayout";
import {SurveyDataController} from "./SurveyDataController";
import {CompanyDataController} from "../Projects/CompanyDataController";




function ExcelImportSurveys() {
    const {
        // user,
        userToken
    } = React.useContext(AuthContext)!;

    const [inputData, setInputData] = React.useState<object[]>([]);

    const [importActive, setImportActive] = React.useState<boolean>(false);

    const [userList, setUserList] = React.useState<IUser[]>([]);
    // TODO: bring over the thing that loads this list.
    React.useEffect(() => {
        async function loadData() {
            // console.log(`userToken: ${userToken}`)
            if (userToken === null || userToken === undefined) {
                return;
            }
            try {
                let list = await new UserDataController(userToken).getAll();
                if (list === null || list === undefined) {
                    alert("Error loading");
                    return;
                }
                setUserList(list.filter((u) => u.role !== UserRole.CLIENT).sort((a, b) => a.name.localeCompare(b.name)));
            } catch (e) {
                console.warn(`exception: ${(e as any).message}`)
            }

        }
        loadData().then();
    }, [userToken]);


    const [companies, setCompanies] = React.useState<ICompany[]>([]);
    const projectsMemo = React.useMemo(() => {
        if (companies === undefined || companies === null || companies.length === 0) {
            return [];
        }

        let projects: IProject[] = companies.flatMap((company) => {
            return company.projects as IProject[];
        })

        return projects.sort((a, b) => a.name.localeCompare(b.name));
    }, [companies ]);

    const loadCompanyData = React.useCallback(async () => {
        if (userToken === null || userToken === undefined) {
            return;
        }
        let all = await new CompanyDataController(userToken).getAll();
        setCompanies(all.sort((a, b) => a.name.localeCompare(b.name)));
    }, [userToken]); // Dependencies array


    React.useEffect(() => {

        loadCompanyData().then();
    }, [userToken, loadCompanyData]);

    async function ImportSelectedSurveys() {
        // TODO: async loop over each survey and import it.
        if (userToken === null || userToken === undefined) {
            return;
        }

        setImportActive(true);

        interface MyObject {
            [key: string]: any;  // Now the object can be indexed with any string, and will return any type.
        }


        let controller = new SurveyDataController(userToken);
        let asyncFunctions = inputData.map(async (row) => {

            let object= row as MyObject;



            // return async () => {
                console.log("importing row")
                // get the source id from the row
                let sourceMapping = mapping["sourceSurveyId"];
                let sourceId = object[sourceMapping];

                let surveyObject: ISurvey = {
                    caseNumber: object[mapping["caseNumber"]],
                    surveyName: object[mapping["surveyName"]],
                    assigned_user_id: object[mapping["assigned_user_id"]],
                    coordinator_user_id: object[mapping["coordinator_user_id"]],
                    company: object[mapping["company"]],
                    project: object[mapping["project"]],
                    surveyOwner: object[mapping["surveyOwner"]],
                    locations: [],
                    address: object[mapping["address"]],
                    customerTicketNumber: object[mapping["customerTicketNumber"]],
                    // surveyDate: object["surveyDate"],
                    // conditionFlags
                    content: [],
                    siteContactPhone: object[mapping["siteContactPhone"]],
                    siteContactEmail: object[mapping["siteContactEmail"]],
                    siteContactName: object[mapping["siteContactName"]],
                    locationName: object[mapping["locationName"]],
                    surveyDate_year: object[mapping["surveyDate_year"]],
                    surveyDate_month: object[mapping["surveyDate_month"]],
                    surveyDate_day: object[mapping["surveyDate_day"]],
                    surveyDate_hour: object[mapping["surveyDate_hour"]],
                    surveyDate_minute: object[mapping["surveyDate_minute"]],



                }

                let survey = await controller.import(surveyObject, sourceId);

                console.log(survey)
                // get the rest of the mapped data into the correct object for the ISurvey

            // }
        })

        await Promise.all(asyncFunctions)
        setImportActive(false);
    }


    function SelectAndReadInputExcel() {
        let input = document.createElement('input');
        input.type = 'file';
        input.accept = '.xlsx';
        input.onchange = (e: any) => {
            const file = e.target.files?.[0];
            if (!file) return;

            const reader = new FileReader();
            reader.onload = (evt) => {
                const bstr = evt.target?.result;
                const wb = XLSX.read(bstr, { type: 'binary' });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                console.log(ws)
                const data = XLSX.utils.sheet_to_json<Record<string, any>>(ws);
                if (data === undefined || data === null) {
                    return;
                }

                // loop over each row and give it a unique id
                data.forEach((row, index) => {
                    row['id'] = index;
                })
                console.log(data); // Process your data here
                setInputData(data)
            };
            reader.readAsBinaryString(file);
        };
        input.click();
    }

    const [mapping, setMapping] = React.useState<{[key: string]: string}>({
        "caseNumber": "caseNumber",
        "surveyName": "surveyName",
        "assigned_user_id": "assigned_user_id",
        "coordinator_user_id": "coordinator_user_id",
        // "companyName": "companyName",
        // "projectName": "projectName",
        "sourceSurveyId": "sourceSurveyId",
        "surveyOwner": "surveyOwner",
        "address": "address",
        "project"   : "project",
        "company"   : "company",
        "siteContactName": "siteContactName",
        "siteContactEmail": "siteContactEmail",
        "siteContactPhone": "siteContactPhone",
        "locationName": "locationName",
        "surveyDate_year": "surveyDate_year",
        "surveyDate_month": "surveyDate_month",
        "surveyDate_day": "surveyDate_day",
        "surveyDate_hour": "surveyDate_hour",
        "surveyDate_minute": "surveyDate_minute",
        "customerTicketNumber": "customerTicketNumber"

    });


    const formFieldsMemo = React.useMemo(() => {
        let formFields: CustomFormField[] = []

        if (inputData.length === 0) {
            return formFields;
        }

        let options = Object.keys(inputData[0]).map((key) => {
            return { label: key, value: key };
        });

        suggestedColumns.forEach((column) => {
            let listOfOptionsAvailableOrSelectedByCurrentColumn = options.filter((option) => {
                let mappingValues = Object.values(mapping);
                return (!mappingValues.includes(option.value) || mapping[column.source] === option.value)
            })

            formFields.push({
                key: column.source,
                label: column.label,
                kind: CustomFormFieldTypes.SELECT,
                size: CustomFormFieldSizes.ONE,
                options: listOfOptionsAvailableOrSelectedByCurrentColumn
            });
        })


        return formFields;


    }, [inputData, mapping]);

    const dataGridColumns = React.useMemo(() => {

        // Show all mapped columns

        // show all unmapped columns

        let columns: GridColDef[] = [];

        if (inputData.length === 0) {
            return columns;
        }

        suggestedColumns.forEach((column) => {

            let key = mapping[column.source];

            let specialKeys = ["assigned_user_id", "coordinator_user_id", "project", "company"];
            let mappedSpecialKeys = specialKeys.map((k) => mapping[k]);


            columns.push({
                field: key,
                headerName: column.label,
                width: 300,
                editable: false,
                valueGetter: mappedSpecialKeys.includes(key) ? (params) => {

                    switch (key) {
                        case "assigned_user_id":
                        case "coordinator_user_id":
                        case mapping['assigned_user_id']:
                        case mapping['coordinator_user_id']:
                            let user = userList.find((u) => u._id === params.row[key]);
                            return user?.name || '[No User]';
                        case "project":
                        case mapping['project']:
                            let project = projectsMemo.find((p) => (p as IProject & {_id: string})._id === params.row[key]);
                            return project?.name || '[No Project]';
                        case "company":
                        case mapping['company']:
                            let company = companies.find((c) => (c as ICompany & {_id: string})._id === params.row[key]);
                            return company?.name || '[No Company]';
                    }

                    return params.row[key]
                }: undefined,
            })
        });


        let keys = Object.keys(inputData[0]);
        keys.forEach((key) => {
            let listOfUsedKeys = Object.values(mapping);

            if (!listOfUsedKeys.includes(key) && key !== "id") {
                columns.push({
                    field: key,
                    headerName: key,
                    width: 300,
                    editable: false
                })
            }
        })


        return columns;
    }, [mapping, inputData, userList, projectsMemo, companies]);

    const [selectionModel, setSelectionModel] = React.useState([]);
    const handleSelection = (newSelectionModel: any) => {
        setSelectionModel(newSelectionModel);
    };


    const [importedSurveys, setImportedSurveys] = React.useState<number[]>([]);
    const isRowSelectable = (params: any) => {
        return !importedSurveys.includes(params.row.id); // Example condition
    };



    return (
        <div>
            {/* move the sidebar stuff into a provider system. */}
            <AuthenticatedLayout  pageTitle={"Import Bulk Surveys"}
                customButtons={[
                    {
                        label: "Upload Excel",
                        action: () => {
                            SelectAndReadInputExcel();
                        },
                        icon: <CloudUploadIcon />
                    },
                    {
                        label: "Import Surveys",
                        action: () => {
                            if (selectionModel.length === 0) {
                                return;
                            }

                            if (userToken === null || userToken === undefined) {
                                return;
                            }

                            if (importActive) {
                                alert("Import already in progress");
                                return;
                            }

                            ImportSelectedSurveys().then(() =>{
                                setImportedSurveys([...importedSurveys, ...selectionModel]);
                                setSelectionModel([])
                            });
                        },
                        icon: <CloudUploadIcon />,
                        disabled: selectionModel.length === 0 || importActive
                    }
                ]}
            >

                <Stack style={{height: '100%', width: '100%'}}>

                    <br />
                    <br />


                    {/*<pre>{JSON.stringify(dataGridColumns, undefined, 4)}</pre>*/}

                    <EditObject item={mapping} setItem={setMapping} form={formFieldsMemo} columns={4} />

                    <br />
                    <DataGrid
                        // make sure to set height to 100% so that the grid fills the whole container
                        style={{height: '100%', width: '100%'}}
                        rows={inputData}
                        columns={dataGridColumns}
                        // getRowId={(row: GridValidRowModel) => JSON.stringify(row)}
                        pageSizeOptions={[100]}
                        disableRowSelectionOnClick
                        checkboxSelection={true}
                        onRowSelectionModelChange={handleSelection}
                        rowSelectionModel={selectionModel}
                        isRowSelectable={isRowSelectable}
                    />


                    <pre>{JSON.stringify(selectionModel)}</pre>
                    <pre>{JSON.stringify(importedSurveys)}</pre>

                </Stack>
            </AuthenticatedLayout>
        </div>
    );
}

export default ExcelImportSurveys;

const suggestedColumns = [
    { label: "Case Number", source: "caseNumber" },
    { label: "Survey Name", source: "surveyName" },
    { label: "Assigned User ID", source: "assigned_user_id" },
    { label: "Coordinator User ID", source: "coordinator_user_id" },
    { label: "Source Survey ID", source: "sourceSurveyId" },
    { label: "Survey Owner", source: "surveyOwner" },
    { label: "Address", source: "address" },
    { label: "Customer Ticket Number", source: "customerTicketNumber" },
    { label: "Project", source: "project" },
    { label: "Company", source: "company" },
    { label: "Site Contact Name", source: "siteContactName" },
    { label: "Site Contact Email", source: "siteContactEmail" },
    { label: "Site Contact Phone", source: "siteContactPhone" },
    { label: "Location Name", source: "locationName" },
    { label: "Survey Date Year", source: "surveyDate_year" },
    { label: "Survey Date Month", source: "surveyDate_month" },
    { label: "Survey Date Day", source: "surveyDate_day" },
    { label: "Survey Date Hour", source: "surveyDate_hour" },
    { label: "Survey Date Minute", source: "surveyDate_minute" },

]
