// https://www.linode.com/docs/guides/authenticating-over-websockets-with-jwt/
// https://developer.okta.com/blog/2020/10/28/secure-web-apps-websockets-nodejs

import React from 'react';
import {DataGrid, GridColDef,  GridValidRowModel} from '@mui/x-data-grid';
import AuthenticatedLayout from "../../Library/AuthenticatedLayout";
import {
    ISession,
    ISurvey,
    ISurveyMutex,
    IUser
} from "../../types/interfaces";
import {AuthContext} from "../../Library/AuthContext";
import {
    Button,
    Stack,
    Typography
} from "@mui/material";

import {
    DEFAULT_PAGE_SIZE_OPTIONS,
    UserRole
} from "../../types/enums";
import {UserDataController} from "../Users/UserDataController";
import {useLocalStorage} from "../../Hooks/useLocalStorage";
import {StoredPaginationModel} from "./SurveyManagementView";
import {IPaginated, PaginatedInput} from "../Bank/BankDataController_QuickObject";
import DebugView from "../../Library/DebugView";

class SurveyMutexDataController {

    private readonly baseURL : string

    constructor(private userToken: string) {
        // Nothing to do here yet.
        // const WS_URL = `ws${ process.env.REACT_APP_SERVER_URL ? "s" :"" }://${baseURL}`;
        const domainURL: string = process.env.REACT_APP_SERVER_URL || "127.0.0.1:3000";
        const protocol: string = `http${ process.env.REACT_APP_SERVER_URL ? "s" :"" }`;
        this.baseURL = `${protocol}://${domainURL}`;
    }

    public async get(filter: PaginatedInput, filterText: string): Promise<IPaginated<ISurveyMutex>> {
        let results = await fetch(`${this.baseURL}/api/surveyMutex/?page=${filter.page}&limit=${filter.pageSize}&filter=${encodeURIComponent(filterText)}`, {
            headers: {
                "authorization": `Bearer ${this.userToken}`
            }
        });
        let data = await results.json();
        return await data; // this doesn't seem to type check.
    }

    public async delete(id: string): Promise<void> {
        let results = await fetch(`${this.baseURL}/api/surveyMutex/${id}`, {
            method: "DELETE",
            headers: {
                "authorization": `Bearer ${this.userToken}`,
                "Content-Type": "application/json"
            }
            // body: JSON.stringify(item)
        });

        console.log(results)
        let data = await results.json();
        console.log(data);
        // return await data; // this doesn't seem to type check. change to .item when api updated
    }
}

function SurveyMutexManagementView() {

    const {
        user,
        userToken
    } = React.useContext(AuthContext)!;

    const [mutexList, setMutexList] = React.useState<ISurveyMutex[]>()

    const [usersList, setUserList] = React.useState<IUser[]>([])
    const [totalRows, setTotalRows] = React.useState<number>(0);
    const [searchText, setSearchText] = useLocalStorage("SurveyMutexManagementView.searchText", "") as [string, React.Dispatch<React.SetStateAction<string>>];


    const [paginationModel, setPaginationModel] = useLocalStorage("SurveyMutexManagementView.paginationModel", {
        pageSize: 25,
        page: 0,
    }) as [StoredPaginationModel, React.Dispatch<React.SetStateAction<StoredPaginationModel>>];


    const [debugValue, setDebugValue] = React.useState<any>("")

    const UpdateList = React.useCallback(async () => {
        if (userToken === null || userToken === undefined) {
            return;
        }

        let response = await new SurveyMutexDataController(userToken).get(paginationModel, searchText);
        setTotalRows(response.count)
        setMutexList(response.items);
        // setDebugValue(response)
    },[userToken, paginationModel.page, paginationModel.pageSize, searchText]);

    React.useEffect(() => {
        UpdateList().then();
    }, [userToken, paginationModel.page, paginationModel.pageSize, searchText, UpdateList]);


    const columns = React.useMemo(() => {
        let columns: GridColDef[] = []

        if (user?.role === UserRole.ADMIN) {
            columns.push({ field: '_id', headerName: 'ID', width: 310, editable: false,
                renderCell: (params) => (
                    <>
                        <Stack direction={"row"} sx={{ width: '100%' }}>

                            <Button variant="outlined" color="primary" size={"small"} fullWidth={false} onClick={async () => {
                                let item = params.row as ISurvey & { _id: string };
                                try {
                                    await navigator.clipboard.writeText(item._id);
                                    console.log('Text copied to clipboard');
                                } catch (err) {
                                    console.error('Failed to copy: ', err);
                                }
                            }}>Copy</Button>

                            <Typography sx={{ padding: "3px" }}>&nbsp;{params.value}</Typography>
                        </Stack>
                    </>
                ),
            });
        }
        else if (user?.role !== UserRole.CLIENT) {
            columns.push({ field: '_id', headerName: 'ID', width: 400, editable: false });
        }

        columns.push({ field: 'session._id', headerName: 'Session', width: 400, editable: false, valueGetter: (params) => {
            if (params.row.session === undefined) {
                return "Session Not Found";
            }
            else {
                return params.row.session
                // return (params.row.session as (ISession & {_id : string}))
            }
            // return (params.row.session as (ISession & {_id : string}) | undefined)._id
        } })


        columns.push({ field: 'user.name', headerName: 'User Name', width: 400, editable: false, valueGetter: (params) => { return (params.row.user as IUser & {_id : string}).name } })
        // columns.push({ field: 'session.user.name', headerName: 'User Name', width: 400, editable: false, valueGetter: (params) => { return (params.row.session as ISession & {_id : string}).user.name } })
        columns.push({ field: 'user.email', headerName: 'User Email', width: 400, editable: false, valueGetter: (params) => { return (params.row.user as IUser & {_id : string}).email_address } })
        // columns.push({ field: 'session.user.email', headerName: 'User Email', width: 400, editable: false, valueGetter: (params) => { return (params.row.session as ISession & {_id : string}).user.email_address } })
        columns.push({ field: 'survey.caseNumber', headerName: 'Survey Case Number', width: 400, editable: false, valueGetter: (params) => { return (params.row.survey as ISurvey & {_id : string}).caseNumber } })
        columns.push({ field: 'survey._id', headerName: 'Survey', width: 400, editable: false, valueGetter: (params) => { return (params.row.survey as ISurvey & {_id : string})._id } })

        if (user?.role === UserRole.ADMIN) {
            columns.push({ field: 'delete', headerName: 'Delete', width: 100, editable: false,
                renderCell: (params) => (
                    <>
                        <Stack direction={"row"} sx={{ width: '100%' }}>

                            <Button variant="outlined" color="primary" size={"small"} fullWidth={false} onClick={async () => {
                                let item = params.row as ISurveyMutex & { _id: string };
                                if (userToken === null || userToken === undefined) {
                                    return;
                                }
                                await new SurveyMutexDataController(userToken).delete(item._id);
                                UpdateList().then();
                            }}>Revoke</Button>
                        </Stack>
                    </>
                ),
            });
        }

        return columns;
    }, [user, userToken, UpdateList, usersList])

    const loadData = React.useCallback(async () => {
        if (userToken === null || userToken === undefined) {
            return;
        }
        try {
            let usersList = await new UserDataController(userToken).getAll();
            if (usersList === null || usersList === undefined) {
                alert("Error loading");
                return;
            }
            setUserList(usersList.filter((u) => u.role !== UserRole.CLIENT).sort((a, b) => a.name.localeCompare(b.name)));
        } catch (e) {
            console.warn(`exception: ${(e as any).message}`)
        }
    }, [userToken]);

    React.useEffect(() => {
        loadData().then();
    }, [userToken, loadData]);

    return (
        <div>
            {/* move the sidebar stuff into a provider system. */}
            <AuthenticatedLayout  pageTitle={"Survey Mutex Management"}
                                  searchText={{text: searchText, setText: setSearchText, label: "Search (Email, User Name)"}}
            >
                <DataGrid
                    rows={mutexList || []}
                    columns={columns}
                    getRowId={(row: GridValidRowModel) => row._id}
                    pageSizeOptions={DEFAULT_PAGE_SIZE_OPTIONS}
                    disableRowSelectionOnClick
                    // rowSelectionModel={selectionModel}
                    // onRowSelectionModelChange={handleSelection}
                    // checkboxSelection={user?.role === UserRole.ADMIN}
                    pagination={true}
                    rowCount={totalRows}
                    paginationMode={"server"}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                />

                {/*<DebugView value={debugValue} />*/}

            </AuthenticatedLayout>
        </div>
    );
}

export default SurveyMutexManagementView;
