import {
    CustomFormField,
    ICondition,
    IQuickQuestion, ISurvey,
    SurveyObject,
    SurveyObjectQuestion
} from "../../../types/interfaces";
import React, {Dispatch} from "react";
import {CustomFormFieldSizes, CustomFormFieldTypes} from "../../../types/enums";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    IconButton,
    InputAdornment,Modal,
    Paper,  Stack,
    Table, TableBody, TableCell,
    TableContainer,
    TableHead,
    TableRow, TextField,
    Typography
} from "@mui/material";
import ShuffleIcon from "@mui/icons-material/Shuffle";
import {EditObject} from "../../../Shared/Components/EditObject";
import DeleteIcon from "@mui/icons-material/Delete";
import {generateCode} from "../SurveyEditorView";
import {AuthContext} from "../../../Library/AuthContext";
import {BankDataController_QuickQuestion} from "../../Bank/BankDataController_QuickQuestion";
import {GridCellParams, GridValidRowModel} from "@mui/x-data-grid";
import {DataGridPro} from "@mui/x-data-grid-pro";
import EditIcon from "@mui/icons-material/Edit";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import WarningIcon from '@mui/icons-material/Warning';
import {
    ConditionFlagsPassThru,
} from "./ConditionFlagsPassThru";
import {QuickSelectOptions} from "./QuickSelectOptions";
import {
    MultipleConditionSingleConditionStaticConditionEditorPassThru
} from "./MultipleConditionSingleConditionStaticConditionEditorPassThru";


export const QuestionCustomFormFieldsFactory = (survey: ISurvey, surveyObject: SurveyObject, item: any, setItem: Dispatch<any>, expanded: string | false, handleAccordionChange: any) => {
    let fields: CustomFormField[] = [
        {
            kind: CustomFormFieldTypes.TEXT,
            size: CustomFormFieldSizes.FULL,
            key: "questionText",
            label: "Question Text",
            endAdornment: <InputAdornment position="end"><Typography>{item["questionText"] !== undefined ? item['questionText'].length : 0}</Typography></InputAdornment>
        },
        {
            // kind: CustomFormFieldTypes.TEXT,
            kind: CustomFormFieldTypes.TEXTAREA,
            multilineRows: 3,
            size: CustomFormFieldSizes.FULL,
            key: "questionInstruction",
            label: "Question Instruction",
        },
        {
            kind: CustomFormFieldTypes.PASSTHRU,
            size: CustomFormFieldSizes.FULL,
            key: "required",
            label: "Required",
            passthru: MultipleConditionSingleConditionStaticConditionEditorPassThru,
            passthruProps: {
                conditionKey: "required",
                label: "Required",
                survey: survey,
                item: item,
                setItem: setItem,
                surveyObject: surveyObject,
                expanded: expanded,
                handleAccordionChange: handleAccordionChange
            }
        },

        {
            kind: CustomFormFieldTypes.TEXT,
            size: CustomFormFieldSizes.HALF,
            key: "key",
            label: "Key",
            endAdornment: <InputAdornment position="end"><IconButton aria-label="generate new code" onClick={() => { setItem({...item, key: generateCode()});}} edge="end"><ShuffleIcon /></IconButton></InputAdornment>
        },
        {
            kind: CustomFormFieldTypes.SELECT,
            size: CustomFormFieldSizes.HALF,
            key: "type",
            label: "Type",
            options: [
                {label: "Text", value: "text"},
                {label: "Toggle", value: "toggle"},
                {label: "Select", value: "select"},
                {label: "Photo", value: "photo"},
                {label: "Geo Coordinate", value: "geoCoordinate"},
                {label: 'Document', value: 'document'},
                // {label: 'Signature', value: 'signature'},
                {label: 'Multi Select', value: 'multiSelect'},
                {label: 'Instruction', value: 'instruction'},
                // {label: 'Barcode', value: 'barcode'},
                {label: 'Table', value: 'table'},

            ]
        },
    ];


    if (item.type === 'select' || item.type === 'multiSelect') {
        fields.push({
                kind: CustomFormFieldTypes.TEXT,
                size: CustomFormFieldSizes.FULL,
                key: "options",
                label: `Options (comma separated)`,
            }
        );

        fields.push({
            kind: CustomFormFieldTypes.PASSTHRU,
            size: CustomFormFieldSizes.FULL,
            key: "quickOptions",
            label: "Quick Options",
            passthru: QuickSelectOptions,
            passthruProps: { item: item, setItem: setItem, surveyObject: surveyObject }
        })
    }

    if (item.type === 'table') {
        // fields.push({
        //         kind: CustomFormFieldTypes.TEXT,
        //         size: CustomFormFieldSizes.FULL,
        //         key: "options",
        //         label: `Header Labels (comma separated)`,
        //     }
        // );

        fields.push({
                kind: CustomFormFieldTypes.TEXT,
                size: CustomFormFieldSizes.FULL,
                key: "shownLabels",
                label: `shownLabels Header Labels (comma separated)`,
            }
        );

        fields.push({
                kind: CustomFormFieldTypes.TEXT,
                size: CustomFormFieldSizes.FULL,
                key: "overflowLabels",
                label: `overflowLabels Header Labels (comma separated)`,
            }
        );

    }

    if (item.type === 'select' && item.options !== undefined) {
        fields.push({
                kind: CustomFormFieldTypes.SELECT,
                size: CustomFormFieldSizes.FULL,
                key: "defaultValue",
                label: `Default Value`,
                options: item.options.split(",").map((opt: string) => { return { label: opt.trim(), value: opt.trim() }; })
            }
        );
    }

    // if (item.type === 'select' && item.options !== undefined && surveyObject.questions.length > 1) {
    //     fields.push({
    //         kind: CustomFormFieldTypes.PASSTHRU,
    //         size: CustomFormFieldSizes.FULL,
    //         key: "quickSource",
    //         label: "Quick Source",
    //         passthru: QuickAddAsInstalledSource,
    //         passthruProps: { item: item, setItem: setItem, surveyObject: surveyObject }
    //     })
    // }

    if (item.type === 'toggle') {
        fields.push({
                kind: CustomFormFieldTypes.SELECT,
                size: CustomFormFieldSizes.FULL,
                key: "defaultValue",
                label: `Default Value`,
                options: [
                    {label: "TRUE", value: true},
                    {label: "FALSE", value: false},
                ]
            }
        );
    }

    fields.push({
        kind: CustomFormFieldTypes.PASSTHRU,
        size: CustomFormFieldSizes.FULL,
        key: "conditions",
        label: "Conditions",
        passthru: ConditionalQuestionGrid,
        passthruProps: { item: item, setItem: setItem, surveyObject: surveyObject, expanded: expanded, handleAccordionChange: handleAccordionChange }
    })

    if (survey.conditionFlags !== undefined && Object.keys(survey.conditionFlags).length > 0){
        fields.push({
            kind: CustomFormFieldTypes.PASSTHRU,
            size: CustomFormFieldSizes.FULL,
            key: "conditionFlags",
            label: "Condition Flags",
            passthru: ConditionFlagsPassThru,
            passthruProps: { survey: survey, item: item, setItem: setItem, surveyObject: surveyObject, expanded: expanded, handleAccordionChange: handleAccordionChange }
        })
    }

    fields.push({
        kind: CustomFormFieldTypes.PASSTHRU,
        size: CustomFormFieldSizes.FULL,
        key: "showInStandardReport",
        label: "Show In Standard Report",
        passthru: MultipleConditionSingleConditionStaticConditionEditorPassThru,
        passthruProps: {
            conditionKey: "showInStandardReport",
            label: "Show In Standard Report",
            survey: survey,
            item: item,
            setItem: setItem,
            surveyObject: surveyObject,
            expanded: expanded,
            handleAccordionChange: handleAccordionChange
        }
    })

    fields.push({
        kind: CustomFormFieldTypes.PASSTHRU,
        size: CustomFormFieldSizes.FULL,
        key: "showInExceptionReport",
        label: "Show In Exception Report",
        passthru: MultipleConditionSingleConditionStaticConditionEditorPassThru,
        passthruProps: {
        conditionKey: "showInExceptionReport",
            label: "Show In Exception Report",
            survey: survey,
            item: item,
            setItem: setItem,
            surveyObject: surveyObject,
            expanded: expanded,
            handleAccordionChange: handleAccordionChange
        }
    })

    fields.push({
        kind: CustomFormFieldTypes.PASSTHRU,
        size: CustomFormFieldSizes.FULL,
        key: "showInExceptionCount",
        label: "Show In Exception Count",
        passthru: MultipleConditionSingleConditionStaticConditionEditorPassThru,
        passthruProps: {
            conditionKey: "showInExceptionCount",
            label: "Show In Exception Count",
            survey: survey,
            item: item,
            setItem: setItem,
            surveyObject: surveyObject,
            expanded: expanded,
            handleAccordionChange: handleAccordionChange
        }
    })

    return fields;
};





export const ConditionalQuestionGrid = (props: { surveyObject: SurveyObject, item: any, setItem: Dispatch<any>, field: CustomFormField, expanded: string | false, handleAccordionChange: any }) => {
    const [newRow, setNewRow] = React.useState<any>({});



    const fields = React.useMemo(
        () => {
            let fields: CustomFormField[] = [
                {
                    kind: CustomFormFieldTypes.SELECT,
                    size: CustomFormFieldSizes.ONE,
                    key: "key",
                    label: "Conditional Question Key",
                    options: props.surveyObject.questions.filter((question) => question.key !== props.item.key).map((question: SurveyObjectQuestion) => { return {label: question.questionText, value: question.key} })
                },
                {
                    kind: CustomFormFieldTypes.SELECT,
                    size: CustomFormFieldSizes.ONE,
                    key: "op",
                    label: "Conditional Question Operator",
                    options: [...("=,≠".split(",").map((opt: string) => { return { label: opt, value: opt }; }))],

                }
            ];

            if (newRow.key !== undefined) {
                let selectedQuestions = props.surveyObject.questions.filter((q) => q.key === newRow.key)
                console.log("selectedQuestions", selectedQuestions);
                let selectedQuestion = selectedQuestions[0];
                if (selectedQuestion !== undefined){
                    switch (selectedQuestion.type.type) {
                        case "select":
                            fields.push({
                                kind: CustomFormFieldTypes.SELECT,
                                size: CustomFormFieldSizes.ONE,
                                key: "value",
                                label: "Conditional Question Value",
                                options: selectedQuestion.type.options?.map((opt) => { return { label: opt, value: opt }; })
                            })

                            break;
                        case "text":
                            fields.push({
                                kind: CustomFormFieldTypes.TEXT,
                                size: CustomFormFieldSizes.ONE,
                                key: "value",
                                label: "Conditional Question Value"
                            })
                            break;
                        case "toggle":
                            fields.push({
                                    kind: CustomFormFieldTypes.SELECT,
                                    size: CustomFormFieldSizes.ONE,
                                    key: "value",
                                    label: `Conditional Question Value`,
                                    options: [
                                        {label: "TRUE", value: true},
                                        {label: "FALSE", value: false},
                                    ]
                                }
                            );
                            break;
                        case "photo":
                            // what?!
                            break;

                    }
                }
            }

            return fields;
        },
        [newRow, props.surveyObject.questions, props.item.key]
    );

    // const [quickCondition, setQuickCondition] = React.useState<any>({});
    const [conditionAccordion, setConditionAccordion] = React.useState<string | false>(false);
    const handleConditionAccordionChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => { setConditionAccordion(isExpanded ? panel : false); };

    const isInvalid = React.useMemo(() => {

        let warningCount = 0;

        (props.item[props.field.key as keyof SurveyObjectQuestion] || []).forEach((row: any) => {
            let selectedQuestions = props.surveyObject.questions.filter((q) => q.key === row.key)[0];
            if (selectedQuestions === undefined) {
                warningCount++;
            }
        });

        return warningCount > 0;
    }, [props.item, props.surveyObject.questions, props.field.key]);

    return (<>

        <Accordion expanded={props.expanded === 'conditionQuestions'} onChange={props.handleAccordionChange('conditionQuestions')}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
            >
                {isInvalid ? (<><WarningIcon color={"error"} />&nbsp;</>) : (<></>)}
                <Typography sx={{ width: '33%', flexShrink: 0 }}>
                    Conditional Questions [AND]
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}>Configure the question to be shown only when questions have specific answers.</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Paper variant={"outlined"} sx={{padding: 2}}>

                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">Key</TableCell>
                                    <TableCell align="center">OP</TableCell>
                                    <TableCell align="center">Value</TableCell>
                                    <TableCell align="center">&nbsp;</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(props.item[props.field.key as keyof SurveyObjectQuestion] || []).map((row: any) => {
                                    let selectedQuestions = props.surveyObject.questions.filter((q) => q.key === row.key)[0];
                                    if (selectedQuestions === undefined) {
                                        // TODO: this is a bug, we should probably delete this row.
                                        return (
                                            <TableRow
                                                key={row.key}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell sx={{ color: 'red' }} align="center" component="th" scope="row">
                                                    {row.key} [MISSING QUESTION]
                                                </TableCell>
                                                <TableCell align="center">{row.op}</TableCell>
                                                <TableCell align="center">{row.value}</TableCell>
                                                <TableCell align="center">
                                                    <IconButton aria-label="delete" color={"error"} onClick={() => {
                                                        if (window.confirm(`Are you sure you want to delete ${row.key} ${row.op}?`)){
                                                            let newParentItem = {...props.item};
                                                            // @ts-ignore
                                                            let list = [...newParentItem[props.field.key as keyof SurveyObjectQuestion]];
                                                            list.splice(list.indexOf(row), 1);
                                                            props.setItem({...newParentItem, [props.field.key]: list});
                                                        }

                                                    }}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    }
                                    return (
                                        <TableRow
                                            key={row.key}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell align="center" component="th" scope="row">
                                                {row.key} ({selectedQuestions.questionText})
                                            </TableCell>
                                            <TableCell align="center">{row.op}</TableCell>
                                            <TableCell align="center">{row.value}</TableCell>
                                            <TableCell align="center">
                                                <IconButton aria-label="delete" color={"error"} onClick={() => {
                                                    if (window.confirm(`Are you sure you want to delete ${row.key} ${row.op}?`)){
                                                        let newParentItem = {...props.item};
                                                        // @ts-ignore
                                                        let list = [...newParentItem[props.field.key as keyof SurveyObjectQuestion]];
                                                        list.splice(list.indexOf(row), 1);
                                                        props.setItem({...newParentItem, [props.field.key]: list});
                                                    }

                                                }}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <br />
                    <hr />
                    <br />

                    <QuickConditionAccordionView
                        conditionAccordion={conditionAccordion}
                        // quickConditions={quickConditions}
                        item={props.item}
                        setItem={props.setItem}
                        field={props.field}
                        surveyObject={props.surveyObject}
                        handleConditionAccordionChange={handleConditionAccordionChange}
                    />

                    <Accordion expanded={conditionAccordion === 'complexCondition'} onChange={handleConditionAccordionChange('complexCondition')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                Complex Condition
                            </Typography>
                            <Typography sx={{ color: 'text.secondary' }}>Complex Condition</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Paper variant={"outlined"} sx={{padding: 2}}>
                                <EditObject
                                    item={newRow}
                                    setItem={setNewRow}
                                    form={fields}
                                    columns={fields.length}
                                />
                                <Button fullWidth
                                        disabled={newRow.key === undefined || newRow.op === undefined || newRow.value === undefined || newRow.value === "" || newRow.op === ""}
                                        onClick={() => {
                                            let newParentItem = {...props.item};
                                            // @ts-ignore
                                            if (newParentItem[props.field.key as keyof SurveyObjectQuestion] === undefined) {
                                                // @ts-ignore
                                                newParentItem[props.field.key as keyof SurveyObjectQuestion] = [];
                                            }
                                            let list = [...newParentItem[props.field.key as keyof SurveyObjectQuestion], newRow];
                                            props.setItem({...newParentItem, [props.field.key]: list});
                                            setNewRow({});
                                        }}
                                        variant={"outlined"}
                                        color={"primary"}

                                >
                                    Add Condition
                                </Button>

                            </Paper>
                        </AccordionDetails>
                    </Accordion>
                    <br />
                </Paper>
            </AccordionDetails>
        </Accordion>
    </>);
};

interface QuickConditionAccordionViewProps {
    conditionAccordion: string | false,
    handleConditionAccordionChange: any,
    item: SurveyObjectQuestion,
    field: CustomFormField,
    setItem: Dispatch<any>,
    surveyObject: SurveyObject
}
function QuickConditionAccordionView(props: QuickConditionAccordionViewProps) {

    const [quickCondition, setQuickCondition] = React.useState<any>({});

    const quickConditions = React.useMemo(
        () => {
            let allowedTypes = ["select", "toggle"];
            let questions = props.surveyObject.questions.filter((question) => question.key !== props.item.key && allowedTypes.includes(question.type.type) );

            let conditions: ICondition[] = [];

            questions.forEach((question: SurveyObjectQuestion) => {
                switch (question.type.type) {
                    case "select":
                        question.type.options?.forEach((option: string) => {

                            if (props.item.conditions === undefined || props.item.conditions.filter((condition: ICondition) => condition.key === question.key && condition.op === "=" && condition.value === option).length === 0) {
                                conditions.push({
                                    key: question.key,
                                    op: "=",
                                    value: option
                                });
                            }

                            if (props.item.conditions === undefined || props.item.conditions.filter((condition: ICondition) => condition.key === question.key && condition.op === "≠" && condition.value === option).length === 0) {
                                conditions.push({
                                    key: question.key,
                                    op: "≠",
                                    value: option
                                });
                            }
                        })

                        break;

                    case "toggle":

                        if (props.item.conditions === undefined || props.item.conditions.filter((condition: ICondition) => condition.key === question.key && condition.op === "=" && condition.value === true).length === 0) {
                            conditions.push({
                                key: question.key,
                                op: "=",
                                value: true
                            });
                        }

                        if (props.item.conditions === undefined || props.item.conditions.filter((condition: ICondition) => condition.key === question.key && condition.op === "=" && condition.value === false).length === 0) {
                            conditions.push({
                                key: question.key,
                                op: "=",
                                value: false
                            });
                        }

                }
            });
            return conditions.map((condition) => {
                let selectedQuestions = props.surveyObject.questions.filter((q) => q.key === condition.key)[0];
                return {label: `${selectedQuestions.questionText} (${condition.key}) ${condition.op} ${condition.value}`, value: JSON.stringify(condition) }
            });
        }, [props.item, props.surveyObject.questions]
    );



    return (<>

        <Accordion expanded={props.conditionAccordion === 'quickCondition'} onChange={props.handleConditionAccordionChange('quickCondition')}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
            >
                <Typography sx={{ width: '33%', flexShrink: 0 }}>
                    Quick Condition
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}>Quick Condition</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Paper variant={"outlined"} sx={{padding: 2}}>
                    <EditObject item={quickCondition} setItem={setQuickCondition} form={[
                        {
                            kind: CustomFormFieldTypes.SELECT,
                            size: CustomFormFieldSizes.ONE,
                            key: "quickCondition",
                            label: "QuickCondition",
                            options: quickConditions
                            //     .map((condition) => {
                            //     let selectedQuestions = props.surveyObject.questions.filter((q) => q.key === condition.key)[0];
                            //     return {label: `${selectedQuestions.questionText} (${condition.key}) ${condition.op} ${condition.value}`, value: JSON.stringify(condition) }
                            // })
                            // TODO: check that it isn't already in the list
                        }
                    ]} columns={1} />

                    <Button fullWidth
                            disabled={
                                quickCondition.quickCondition === undefined ||
                                props.item.conditions === undefined ? false : props.item.conditions.filter((condition: ICondition) => {
                                    if (quickCondition.quickCondition === undefined) {
                                        return false;
                                    }
                                    let localQuickCondition = JSON.parse(quickCondition.quickCondition);
                                    return condition.key === localQuickCondition.key && condition.op === localQuickCondition.op && condition.value === localQuickCondition.value
                                }).length > 0
                                // props.item[props.field.key as keyof SurveyObjectQuestion].filter((condition: ICondition) => condition.key === quickCondition.quickCondition.key && condition.op === quickCondition.quickCondition.op && condition.value === quickCondition.quickCondition.value).length > 0
                            }
                            onClick={() => {
                                let newParentItem: SurveyObjectQuestion = {...props.item};
                                if (newParentItem.conditions === undefined) {
                                    newParentItem.conditions = [];
                                }
                                let list: ICondition[] = [...newParentItem.conditions, JSON.parse(quickCondition.quickCondition)];
                                props.setItem({...newParentItem, conditions: list});
                            }}
                            variant={"outlined"}
                            color={"primary"}

                    >
                        Add Condition
                    </Button>

                    {/*<DebugView value={quickCondition} />*/}
                </Paper>
            </AccordionDetails>
        </Accordion>

    </>);
}

function CreateNewItemTemplate() {
    return {
        key: generateCode(),
        required: true,
        showInStandardReport: true,
        showInExceptionReport: true,
    }
}

function isQuestionValid(item: any) {
    if (item.key === undefined || item.key === "") {
        return false;
    }

    if (item.questionText === undefined || item.questionText === "") {
        return false;
    }

    console.log("item", item);
    if (item.type === undefined || item.type === "") {
        return false;
    }
    if (item.type === "select" && (item.options === undefined || item.options.length === 0)) {
        return false;
    }

    if (item.type === "toggle" && !(item.defaultValue === true || item.defaultValue === false)) {
        return false;
    }
    console.log("item", item);
    return true;
}

export const AddNewQuestion = ({ survey, surveyObject, open, onClose, onAddNewQuestion } : { survey: ISurvey, surveyObject: SurveyObject, open: boolean, onClose: () => void, onAddNewQuestion: (question: SurveyObjectQuestion) => boolean }) => {
    const [item, setItem] = React.useState<any>(CreateNewItemTemplate());

    // This is for the accordion
    const [expanded, setExpanded] = React.useState<string | false>(false);
    const handleAccordionChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => { setExpanded(isExpanded ? panel : false); };

    const fields = React.useMemo<CustomFormField[]>(() => QuestionCustomFormFieldsFactory(survey, surveyObject, item, setItem, expanded, handleAccordionChange), [item, surveyObject, expanded, survey]);

    const [isInvalid, setIsInvalid] = React.useState<boolean>(true);

    React.useEffect(() => {
        let valid = isQuestionValid(item);
        setIsInvalid(!valid);
    }, [item]);



    return (<>
        <Modal open={open} onClose={() => {
            // setItem(CreateNewItemTemplate());
            // onClose();
        }}>
            {open ? (<>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '60%',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        overflowY: 'auto',
                        maxHeight: '90vh',
                    }}
                >
                    <Typography id="modal-modal-title" variant="h5" component="h2" sx={{ mb: 2, textAlign: 'center' }}>
                        Create New Question
                    </Typography>
                    <EditObject
                        item={item}
                        setItem={setItem}
                        form={fields}
                        columns={12}
                    />
                    <br />

                    <Stack direction={"row"} spacing={2}>
                        {/*<Button type={"button"} variant={"outlined"} size={"large"} fullWidth={true} onClick={() => {*/}
                        {/*    setItem(FixQuestion(question));*/}
                        {/*}}>RESET</Button>*/}
                        <Button type={"button"} variant={"outlined"} size={"large"} fullWidth={true} onClick={() => {
                            // setItem(CreateNewItemTemplate());
                            setItem(CreateNewItemTemplate());
                            onClose();
                        }}>CANCEL</Button>

                        <Button type={"submit"} variant={"contained"} disabled={isInvalid} size={"large"} fullWidth={true} onClick={() => {

                            let questionToCreate: SurveyObjectQuestion = {
                                key: item.key,
                                questionText: item.questionText,
                                type: {
                                    type: item.type,
                                    options: item.options?.split(",").map((option: string) => option.trim()) ?? undefined,
                                    defaultValue: item.defaultValue ?? undefined,
                                    shownLabels: item.shownLabels?.split(",").map((option: string) => option.trim()) ?? undefined,
                                    overflowLabels: item.overflowLabels?.split(",").map((option: string) => option.trim()) ?? undefined,
                                },
                                conditions: item.conditions ?? [],
                                required : item.type === 'instruction' ? false : (item.required ?? true),
                                questionInstruction: item.questionInstruction ?? undefined,
                                showInStandardReport: item.showInStandardReport ?? true,
                                showInExceptionReport: item.showInExceptionReport ?? true,
                                showInExceptionCount: item.showInExceptionCount ?? false,
                                requiredConditionFlags: item.requiredConditionFlags ?? [],
                            };

                            if (onAddNewQuestion(questionToCreate)) {
                                setItem(CreateNewItemTemplate());
                                onClose();
                            }
                            else {
                                alert("Invalid Question");
                            }
                        }}>CREATE</Button>

                    </Stack>
                </Box>
            </>) : (<></>)}
        </Modal>
    </>);
};

export const EditQuestion = ({ survey, question, surveyObject, open, onClose, saveQuestion } : { survey: ISurvey, question: SurveyObjectQuestion, surveyObject: SurveyObject, open: boolean, onClose: () => void, saveQuestion: (question: SurveyObjectQuestion) => boolean }) => {

    function FixQuestion(question: SurveyObjectQuestion) {
        let opts = question.type.options?.join(",") ?? "";
        let o = {
            ...question,
            type: question.type.type,
            options: opts,
            defaultValue: question.type.defaultValue,
            shownLabels: question.type.shownLabels?.join(",") ?? "",
            overflowLabels: question.type.overflowLabels?.join(",") ?? "",

        };
        // console.log("set Edit Question", o);
        return o;
    }

    const [item, setItem] = React.useState<any>(FixQuestion(question));


    // This is for the accordion
    const [expanded, setExpanded] = React.useState<string | false>(false);
    const handleAccordionChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => { setExpanded(isExpanded ? panel : false); };

    const fields = React.useMemo<CustomFormField[]>(() => QuestionCustomFormFieldsFactory(survey, surveyObject, item, setItem, expanded, handleAccordionChange), [item, surveyObject, expanded, survey]);

    const [isInvalid, setIsInvalid] = React.useState<boolean>(true);

    React.useEffect(() => {
        let valid = isQuestionValid(item);
        setIsInvalid(!valid);
    }, [item]);

    return (<>
        <Modal open={open} onClose={() => {
            // onClose();
        }}>
            {open ? (<>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '60%',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        overflowY: 'auto',
                        maxHeight: '90vh',
                    }}
                >
                    <Typography id="modal-modal-title" variant="h5" component="h2" sx={{ mb: 2, textAlign: 'center' }}>
                        Edit Question
                    </Typography>
                    <EditObject
                        item={item}
                        setItem={setItem}
                        form={fields}
                        columns={12}
                    />
                    <br />

                    {/*<pre>{JSON.stringify(item, undefined, 4)}</pre>*/}

                    <Stack direction={"row"} spacing={2}>
                        {/*<Button type={"button"} variant={"outlined"} size={"large"} fullWidth={true} onClick={() => {*/}
                        {/*    setItem(FixQuestion(question));*/}
                        {/*}}>RESET</Button>*/}
                        <Button type={"button"} variant={"outlined"} size={"large"} fullWidth={true} onClick={() => {
                            // setItem(CreateNewItemTemplate());
                            setItem(FixQuestion(question));
                            onClose();
                        }}>CANCEL</Button>

                        <Button type={"submit"} variant={"contained"} disabled={isInvalid} size={"large"} fullWidth={true} onClick={() => {

                            let questionToCreate: SurveyObjectQuestion = {
                                key: item.key,
                                questionText: item.questionText,
                                type: {
                                    type: item.type,
                                    options: item.options?.split(",").map((option: string) => option.trim()) ?? undefined,
                                    defaultValue: item.defaultValue ?? undefined,
                                    shownLabels: item.shownLabels?.split(",").map((option: string) => option.trim()) ?? undefined,
                                    overflowLabels: item.overflowLabels?.split(",").map((option: string) => option.trim()) ?? undefined,
                                },
                                conditions: item.conditions ?? [],
                                required : item.required ?? true,
                                questionInstruction: item.questionInstruction ?? undefined,
                                showInStandardReport: item.showInStandardReport ?? true,
                                showInExceptionReport: item.showInExceptionReport ?? true,
                                showInExceptionCount: item.showInExceptionCount ?? false,
                                requiredConditionFlags: item.requiredConditionFlags ?? [],
                            };



                            if (saveQuestion(questionToCreate)) {
                                setItem({});
                                onClose();
                            }
                            else {
                                alert("Invalid Question");
                            }
                        }}>SAVE</Button>

                    </Stack>






                </Box>
            </>) : (<></>)}
        </Modal>
    </>);
};

export const AddNewListQuestion = ({  open, onClose, onAddNewQuestion } : { open: boolean, onClose: () => void, onAddNewQuestion: (question: SurveyObjectQuestion) => boolean }) => {
    // this is import question


    return (<>
        <Modal open={open} onClose={() => {
            onClose();
        }}>
            {open ? (<>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '60%',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        overflowY: 'auto',
                        maxHeight: '90vh',
                    }}
                >
                    <Typography id="modal-modal-title" variant="h5" component="h2" sx={{ mb: 2, textAlign: 'center' }}>
                        Create New Question
                    </Typography>

                    <QuestionsListAddView onClose={onClose} onAddNewQuestion={onAddNewQuestion} />
                </Box>
            </>) : (<></>)}
        </Modal>
    </>);
};

function QuestionsListAddView({ onClose, onAddNewQuestion } : { onClose: () => void, onAddNewQuestion: (question: SurveyObjectQuestion) => boolean }) {

    const {
        // user,
        userToken
    } = React.useContext(AuthContext)!;

    React.useEffect(() => {
        async function loadData() {
            if (userToken === null || userToken === undefined) {
                return;
            }
            let all = await new BankDataController_QuickQuestion(userToken).getAll();
            setQuickQuestionsList(all);
        }
        loadData().then();
    }, [userToken]);

    const [quickQuestionsList, setQuickQuestionsList] = React.useState<IQuickQuestion[]>([]);

    const [filter, setFilter] = React.useState<string>("");

    const filteredQuickQuestionsList = React.useMemo(() => {
        return quickQuestionsList.filter((quickQuestion) => {
            return quickQuestion.content.questionText.toLowerCase().includes(filter.toLowerCase());
        })
    }, [quickQuestionsList, filter]);


    const columns = [
        // { field: '_id', headerName: 'ID', width: 250, editable: false },
        { field: 'content.key', headerName: 'Key', width: 150, editable: false, valueGetter: (params: GridCellParams) => {
                let quickQuestion: IQuickQuestion = params.row as IQuickQuestion;
                return quickQuestion.content.key;
            }},
        { field: 'content.questionText', headerName: 'Question Text', width: 150, editable: false, valueGetter: (params: GridCellParams) => {
                let quickQuestion: IQuickQuestion = params.row as IQuickQuestion;
                return quickQuestion.content.questionText;
            }},
        { field: 'content.type', headerName: 'Question Type', width: 150, editable: false, valueGetter: (params: GridCellParams) => {
                let quickQuestion: IQuickQuestion = params.row as IQuickQuestion;
                return quickQuestion.content.type.type;
            }},
        {
            field: 'add_btn',
            headerName: '',
            sortable: false,
            width: 75,
            renderCell: (params: GridCellParams) => {
                return (<IconButton color={"warning"} onClick={() => {
                    let quickQuestion: IQuickQuestion = {...params.row as IQuickQuestion};
                    quickQuestion.content.key = generateCode();
                    onAddNewQuestion(quickQuestion.content);
                    onClose();
                }}>
                    <FontAwesomeIcon icon={faPlus} />
                </IconButton>)
            }
        }
    ]


    return (

        <>
            <Stack direction={"column"} spacing={2}>
                <TextField
                    fullWidth
                    label="Filter"
                    value={filter}
                    onChange={(e) => {
                        setFilter(e.target.value);
                    }}
                />

                <DataGridPro
                    // make sure to set height to 100% so that the grid fills the whole container
                    style={{ height: 'calc( 100% - 5rem )', minHeight: '200px' }}
                    rows={filteredQuickQuestionsList}
                    columns={columns}
                    getRowId={(row: GridValidRowModel) => row._id}
                    pageSizeOptions={[100]}
                    disableRowSelectionOnClick
                />
            </Stack>
        </>



    );
}


export function EditButtonModalContainer(props: { survey: ISurvey, params: GridCellParams, item: SurveyObject, setItem: (item: SurveyObject) => void, index: number }) {
    const [showModal, setShowModal] = React.useState<boolean>(false);
    return (<>
        {showModal && (
            <EditQuestion survey={props.survey} question={props.params.row as SurveyObjectQuestion} surveyObject={props.item} open={showModal} onClose={() =>{ setShowModal(false) }} saveQuestion={(question: SurveyObjectQuestion) => {
                let questions: SurveyObjectQuestion[] = [...props.item.questions];
                questions[props.index] = question;
                let item = {...props.item, questions};
                props.setItem(item);
                return true;
            }} />
        )}
        <IconButton color={"warning"} onClick={() => {
            setShowModal(true)
        }}><EditIcon /></IconButton>
    </>);
}

