import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

//...
import * as Sentry from "@sentry/react";

let dsn = process.env.REACT_APP_SENTRY_IO_URL || "https://20a6246b4802e1ec45498204df3667cf@o4506186607034368.ingest.sentry.io/4506433976991744";
console.warn("REACT_APP_SENTRY_IO_URL", dsn);

// if (process.env.NODE_ENV === "production") {
    Sentry.init({
        // REACT_APP_SENTRY_IO_URL
        dsn: process.env.REACT_APP_SENTRY_IO_URL,
        // dsn: "https://02fe8933b3ae8cdd3efa9ee9a9b435eb@o4506186607034368.ingest.sentry.io/4506337807958016",
        integrations: [
            // new Sentry.BrowserTracing({
            //     // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            //     tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
            // }),
            new Sentry.Replay({
                maskAllText: false,
            }),
        ],
        // Performance Monitoring
        // tracesSampleRate: 1.0, // Capture 100% of the transactions
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
// }

// Sentry.init({
//     dsn: "https://02fe8933b3ae8cdd3efa9ee9a9b435eb@o4506186607034368.ingest.sentry.io/4506337807958016",
//     integrations: [
//         // new Sentry.BrowserTracing({
//         //     // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//         //     tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
//         // }),
//         new Sentry.Replay({
//             maskAllText: false,
//         }),
//     ],
//     // Performance Monitoring
//     // tracesSampleRate: 1.0, // Capture 100% of the transactions
//     // Session Replay
//     replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//     replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

// window.onerror = function (message, source, lineno, colno, error) {
//     // Assuming you have a way to access the setError method from GlobalErrorContext
//     // You might need to find a way to make setError accessible here
//     // alert("window.onerror: " + message);
//
//     // append the error to the body.
//     let div = document.createElement("div");
//     if (error) {
//         div.innerText = error.toString();
//     }
//     // div.innerText = message;
//     document.body.appendChild(div);
//
//
// };

window.addEventListener('error', function(error){
    // prevent React's listener from firing
    // e.stopImmediatePropagation();
    // prevent the browser's console error message
    // e.preventDefault();

    // alert("window.addEventListener: " + e.message);

    // hide element with id 'root'
    let root = document.getElementById('root');
    if (root) {
        root.style.display = 'none';
    }
    // document.getElementById('root').style.display = 'none';

    let div = document.createElement("div");
    div.style.padding = "20px";
    div.style.textAlign = "center";
    div.style.backgroundColor = "red";
    div.style.color = "white";
    div.style.margin = "20px";

    if (error) {

        let p1 = document.createElement("p");
        p1.innerText = error.message;
        div.appendChild(p1);

        let p2 = document.createElement("p");
        p2.innerText = error.filename;
        div.appendChild(p2);

        let p3 = document.createElement("p");
        p3.innerText = error.lineno.toString();
        div.appendChild(p3);

        let p4 = document.createElement("p");
        p4.innerText = error.colno.toString();
        div.appendChild(p4);

        let p5 = document.createElement("p");
        p5.innerText = error.error.toString();
        div.appendChild(p5);

        let pre1 = document.createElement("pre");
        pre1.innerText = JSON.stringify(error);
        div.appendChild(pre1);
    }



    // div.innerText = message;
    document.body.appendChild(div);
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
