import React from "react";
import {AuthContext} from "../../Library/AuthContext";
import {ISurvey, IUser} from "../../types/interfaces";
import {DataGrid, GridCellParams, GridColDef, GridValidRowModel} from "@mui/x-data-grid";
import {Button, Stack, Tooltip, Typography} from "@mui/material";
import {Link as RouterLink} from "react-router-dom";
import {ISurveyFilter, SurveyDataController} from "./SurveyDataController";
import {DEFAULT_PAGE_SIZE_OPTIONS, UserRole} from "../../types/enums";
import AuthenticatedLayout from "../../Library/AuthenticatedLayout";
import {useLocalStorage} from "../../Hooks/useLocalStorage";
import {CloneSurveyButton} from "./Management/CloneSurveyButton";
import {UserDataController} from "../Users/UserDataController";
import {StoredPaginationModel} from "./SurveyManagementView";

function TemplateManagementView() {

    const {
        user,
        userToken
    } = React.useContext(AuthContext)!;

    const [usersList, setUserList] = React.useState<IUser[]>([])

    const loadData = React.useCallback(async () => {
        if (userToken === null || userToken === undefined) {
            return;
        }
        try {
            let usersList = await new UserDataController(userToken).getAll();
            if (usersList === null || usersList === undefined) {
                alert("Error loading");
                return;
            }
            setUserList(usersList.filter((u) => u.role !== UserRole.CLIENT).sort((a, b) => a.name.localeCompare(b.name)));
        } catch (e) {
            console.warn(`exception: ${(e as any).message}`)
        }
    }, [userToken]);

    React.useEffect(() => {
        loadData().then();
    }, [userToken, loadData]);


    const columns = React.useMemo(() => {
        let columns: GridColDef[] = []

        columns.push({
            field: 'open_btn',
            headerName: '',
            sortable: false,
            width: 100,
            renderCell: (params: GridCellParams) => (
                <Tooltip title={"Open Survey"} arrow={true}>
                    <Button variant="outlined" color="primary" fullWidth={true} component={RouterLink}
                            to={`/dashboard/surveys/${params.id}`}>
                        Open
                    </Button>
                </Tooltip>
            )
        });

        columns.push({
            field: 'clone_btn',
            headerName: '',
            sortable: false,
            width: 100,
            renderCell: (params: GridCellParams) => (<>

                    <CloneSurveyButton
                        userToken={userToken}
                        setList={setList}
                        survey={params.row as ISurvey}
                        updateList={() => {
                            async function loadData() {
                                if (userToken === null || userToken === undefined) {
                                    return;
                                }
                                let all = await new SurveyDataController(userToken).getAll();
                                setList(all);
                            }

                            loadData().then();
                        }}
                        usersList={usersList}
                    />
                </>
            ),
        });

        if (user?.role === UserRole.ADMIN || user?.role === UserRole.MANAGER) {
            columns.push(
                {
                    field: 'soft_delete_btn',
                    headerName: '',
                    sortable: false,
                    width: 100,
                    renderCell: (params: GridCellParams) => (
                        <Tooltip title="Archive Survey" arrow>
                            <Button variant="outlined" color="error" fullWidth={true} onClick={() => {

                                async function restoreRow() {
                                    let row = params.row as ISurvey & { _id: string };
                                    row.isArchived = true;

                                    if (userToken === null || userToken === undefined) {
                                        return;
                                    }
                                    await new SurveyDataController(userToken).update(row._id, row);
                                    let all = await new SurveyDataController(userToken).getAll();
                                    setList(all);
                                }

                                if (window.confirm("Are you sure you want to archive this survey?")) {
                                    restoreRow().then();
                                }

                            }}>
                                Archive
                            </Button>
                        </Tooltip>
                    ),
                }
            )
        }

        if (user?.role === UserRole.ADMIN || user?.role === UserRole.MANAGER) {
            columns.push(
                {
                    field: 'export_btn',
                    headerName: '',
                    sortable: false,
                    width: 100,
                    renderCell: (params: GridCellParams) => (
                        <Tooltip title={"Export Survey to JSON File"} arrow={true}>
                            <Button variant="outlined" color="error"  fullWidth={true} onClick={() => {


                                async function download(){
                                    if (userToken === null || userToken === undefined) {
                                        return;
                                    }

                                    let fileContent = await new SurveyDataController(userToken).downloadExport(params.row._id);
                                    // window.open(url, '_blank');
                                    console.log(fileContent)

                                    // Create a Blob from the string data
                                    const blob = new Blob([fileContent], { type: 'application/json' });

                                    // Create a Blob URL
                                    const blobUrl = window.URL.createObjectURL(blob);

                                    // Create a download link
                                    const downloadLink = document.createElement('a');
                                    downloadLink.href = blobUrl;
                                    downloadLink.download = params.row._id + ".json"; // Specify the file name here
                                    // downloadLink.style.display = 'none';

                                    // Trigger the download
                                    // document.body.appendChild(downloadLink);
                                    downloadLink.click();

                                }

                                download().then();


                            }}>
                                Export
                            </Button>
                        </Tooltip>
                    ),
                }
            )
        }


        columns.push({field: 'caseNumber', headerName: 'Case Number', width: 300, editable: false});

        columns.push({field: 'surveyOwner', headerName: 'Survey Owner', width: 300, editable: false});
        columns.push({
            field: 'createdAt', headerName: 'Created At', width: 300, editable: false,
            valueFormatter: params => {
                try {
                    if (params.value === undefined || params.value === null) {
                        return "";
                    }
                    let date = new Date(params.value as string);
                    return date.toLocaleString();
                } catch (e) {
                    // console.log(e);
                    return "";
                }
            }
        });

        if (user?.role === UserRole.ADMIN) {

            columns.push({ field: '_id', headerName: 'ID', width: 310, editable: false,
                renderCell: (params) => (
                    <>
                        <Stack direction={"row"} sx={{ width: '100%' }}>

                            <Button variant="outlined" color="primary" size={"small"} fullWidth={false} onClick={async () => {
                                let item = params.row as ISurvey & { _id: string };
                                try {
                                    await navigator.clipboard.writeText(item._id);
                                    console.log('Text copied to clipboard');
                                } catch (err) {
                                    console.error('Failed to copy: ', err);
                                }
                            }}>Copy</Button>

                            <Typography sx={{ padding: "3px" }}>&nbsp;{params.value}</Typography>
                        </Stack>
                    </>
                ),
            });
        }
        else {
            columns.push({ field: '_id', headerName: 'ID', width: 400, editable: false });
        }


        return columns;

    }, [user, userToken, usersList])

    const [paginationModel, setPaginationModel] = useLocalStorage("TemplateManagementView.paginationModel", {
        pageSize: 25,
        page: 0,
    }) as [StoredPaginationModel, React.Dispatch<React.SetStateAction<StoredPaginationModel>>];
    const [totalRows, setTotalRows] = React.useState<number>(0);
    const [list, setList] = React.useState<ISurvey[]>([]);
    const [searchText, setSearchText] = useLocalStorage("TemplateManagementView.searchText", "") as [string, React.Dispatch<React.SetStateAction<string>>];


    const UpdateList = React.useCallback(async () => {
        if (userToken === null || userToken === undefined) {
            return;
        }
        console.log(paginationModel)
        let filter:  ISurveyFilter = {
            page: paginationModel.page,
            pageSize: paginationModel.pageSize,
            searchText: searchText,
            isTemplate: true,
            filterStart_year: null,
            filterStart_month: null,
            filterStart_day: null,
            filterEnd_year: null,
            filterEnd_month: null,
            filterEnd_day: null,

        }
        let response = await new SurveyDataController(userToken).getPaginated(filter);
        console.log(response)
        setList(response.items);
        setTotalRows(response.count);
    },[userToken, searchText, paginationModel]);

    React.useEffect(() => {
        UpdateList().then();
    }, [userToken, paginationModel.page, paginationModel.pageSize, searchText, UpdateList]);

    const filteredList = React.useMemo(() => {
        if (list === undefined || list === null) {
            return [];
        }

        if (searchText === "") {
            return list
        }


        return list.filter((survey) => {
            if (survey.caseNumber.toLowerCase().includes(searchText.toLowerCase())) {
                return true;
            }

            if (survey.assigned_user_id !== undefined && survey.assigned_user_id !== null) {
                if ((survey.assigned_user_id as IUser).name.toLowerCase().includes(searchText.toLowerCase())) {
                    return true;
                }
            }

            return false;
        });
    }, [list, searchText]);


    return (
        <div>
            {/* move the sidebar stuff into a provider system. */}
            <AuthenticatedLayout pageTitle={"Surveys Templates"}
                                 searchText={{text: searchText, setText: setSearchText, label: "Search (Case Number)"}}
                                 customButtons={[]}
            >

                <DataGrid
                    // make sure to set height to 100% so that the grid fills the whole container
                    style={{height: '100%'}}
                    rows={filteredList}
                    columns={columns}
                    getRowId={(row: GridValidRowModel) => row._id}
                    pageSizeOptions={DEFAULT_PAGE_SIZE_OPTIONS}
                    disableRowSelectionOnClick

                    pagination={true}
                    rowCount={totalRows}
                    paginationMode={"server"}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                />


            </AuthenticatedLayout>
        </div>
    );
}

export default TemplateManagementView;
